import { useContext, useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import {
  BannerComponent,
  UserBannerTopComponent,
} from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { useNavigate } from "react-router-dom";
import "./StudyPlanner.css";
import { StudyPlannerUserCardComponent } from "../../../components/common/Cards";
import { Button, Form, Modal } from "react-bootstrap";
import { Icon } from "@iconify/react/dist/iconify.js";
import ProgressBar from "@ramonak/react-progress-bar";
import { endpoints } from "../../../services/endPoints";
import { getRequest, putRequest } from "../../../services/apiService";
import { UserContext } from "../../../Context/Context";
const StudyPlanner = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const userData = useContext(UserContext);
  const [slectedSlide, setSelectedSlide] = useState(null);
  const [selectedSlideId, setSelectedSlideId] = useState(null);
  const [selectedSlideData, setSelectedSlideData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [purchasedCourses, setPurchasedCourses] = useState([]);

  const exams = [
    {
      name: "JEE",
      image: images.goalsPageCourseImage1,
    },
    {
      name: "NEET",
      image: images.goalsPageCourseImage2,
    },
    {
      name: "JEE",
      image: images.goalsPageCourseImage3,
    },
    {
      name: "NEET",
      image: images.goalsPageCourseImage2,
    },
    {
      name: "NEET",
      image: images.goalsPageCourseImage2,
    },
  ];

  const getSelectedSlideData = () => {
    if (!userData?.user?.goalCategory) return;
    const params = {};
    if (search) params.search = search;
    getRequest({
      endpoint: `${endpoints.getGoalById(userData?.user?.goalCategory?._id)}`,
      setIsLoading,
      params,
    }).then((res) => {
      setSelectedSlideData(res.data);
    });
  };

  useEffect(() => {
    if (userData?.user?.goalCategory) getSelectedSlideData();
  }, [search]);
  const handleClick = (id) => {
    navigate(`/user/study-planner-ai/${id}`);
    return;
    putRequest({
      endpoint: endpoints.updateUserProfile,
      data: {},
    }).then((res) => {});
  };
  const getPurchasedStudyPlanner = () => {
    getRequest({
      endpoint: endpoints.getMyCourses,

      params: { type: "StudyPlanner", limit: 1000 },
      setIsLoading2,
    }).then((res) => {
      setPurchasedCourses(res?.data);
    });
  };
  useEffect(() => {
    getPurchasedStudyPlanner();
  }, []);
  const [bannerData, setBannerData] = useState({});

  const getBanner = () => {
    const params = {
      position: "TOP",
      type: "StudyPlanner",
    };
    getRequest({
      endpoint: endpoints.getHomePageBanners,
      setIsLoading,
      params,
    }).then((res) => {
      setBannerData(res?.data);
    });
  };
  useEffect(() => {
    getBanner();
  }, []);
  const [modalShow, setModalShow] = useState(false);
  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body style={{ padding: "0px" }}>
          <div>
            <img
              style={{ width: "100%", height: "100%", cursor: "pointer",borderRadius:"10px" }}
              onClick={() => setModalShow(false)}
              src={images.userStudyModal}
              alt=""
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  useEffect(() => {
    // setModalShow(true);
  }, []);

  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <div className="user_container ">
        <div>
          <div>
            <UserBannerTopComponent
              divCSS="UserMainTopBannerDiv"
              imagecss={"UserMainTopBannerDivImage"}
              image={images.userDashboardTopBanner}
            />
          </div>
          <div className="user_container_width">
            <UserMenuBar
              DashboardName={"Dashboard"}
              link={"/user/my-sucess-roadmap"}
            />
          </div>
          <div className="user_container_width">
            <div>
              <div>
                {bannerData && (
                  <BannerComponent
                    image={
                      bannerData?.image?.[bannerData?.image?.length - 1] ||
                      images.userHandWrittenNotesImage
                    }
                    imagecss={"userHandWrittenNotesBannerImage"}
                    alt="alttext"
                  />
                )}
              </div>
              <div className="mt-4 mb-2">
                <p className="dashboardPage_continueWhereYouLeft_0">
                  Your Study Planner
                </p>
                {purchasedCourses?.length > 0 &&
                  purchasedCourses?.map((item, index) => (
                    <div className="dashboardPage_continueWhereYouLeft_1 user_container_width mb-2">
                      <div className="dashboardPage_continueWhereYouLeft_1_1">
                        <img src={images.userDashboardTopBanner} alt="image" />
                      </div>
                      <div className="dashboardPage_continueWhereYouLeft_1_2">
                        <span>
                          {item?.studyPlanner?.title ||
                            "Complete Week Preparition"}
                        </span>
                        <span>
                          <ProgressBar
                            completed={item?.progress || 0}
                            labelColor="gray"
                            bgColor="linear-gradient(90deg, #A37BF5, #F3A952) "
                            isLabelVisible={false}
                          />
                        </span>
                      </div>
                      <div className={"dashboardPage_continueWhereYouLeft_1_3"}>
                        <Button
                          onClick={() =>
                            navigate(
                              `/user/study-planner-aip/${item?.order?.items?.[0]?.userStudyPlanner}`
                            )
                          }
                        >
                          Start
                        </Button>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="userStudyPlannerContainer">
                <p className="userStudyPlannerHeading1">
                  <span>Create Your Personalized</span>{" "}
                  <span>Study Schedule</span>
                </p>
                <p className="userStudyPlannerHeading2">
                  <span className="userStudyPlannerInputContainer1">
                    <Form.Control
                      className="userStudyPlannerInput"
                      type="text"
                      placeholder="Search Exam By Name"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </span>
                  <span className="userStudyPlannerInputContainer2">
                    <Icon
                      className="userStudyPlannerIcon"
                      icon="iconamoon:search"
                    />
                  </span>
                </p>
                <div className="userStudyPlannerCardsContainer">
                  <p className="userStudyPlannerCardsHeading">Popular Exams</p>
                  <div className="gridLayout4">
                    {selectedSlideData &&
                      selectedSlideData?.map((item, index) => {
                        return (
                          <StudyPlannerUserCardComponent
                            key={index}
                            image={item.image}
                            name={item.name}
                            onClick={() => {
                              handleClick(item?._id);
                            }}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HOC(StudyPlanner);
