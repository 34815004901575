import { useContext, useEffect, useState } from "react";
import "./css/Sidebar.css";
import images from "../../utils/images";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate } from "../../utils/imports";
import { UserContext } from "../../Context/Context";
import { useLocation } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";

const Sidebar = () => {
  const navigate = useNavigate();
  const userData = useContext(UserContext);
  const { pathname } = useLocation();

  const sideBarItems = [
    { name: "Home", path: "/user/home", iconName: "ic:baseline-home" },
    {
      name: "Study Planner AI ✨",
      path:"/user/studPlannerBanner",
      // path: "/user/study-planner-ai",
      iconName: "mynaui:book-check",
    },
    {
      name: "About Exam",
      path: "/user/about-exam",
      iconName: "ic:outline-event-note",
    },
    { name: "Courses", path: "/user/course", iconName: "mdi:school-outline" },
    {
      name: "Tests with Video Solution",
      path: "/user/test-video-solution",
      iconName: "ic:baseline-ondemand-video",
    },
    {
      name: "Handwritten Notes",
      path: "/user/handwritten-notes",
      iconName: "ic:outline-event-note",
    },
    {
      name: "PYQs with Videos",
      path: "/user/pyq-with-videos",
      iconName: "mynaui:book-check",
    },
    {
      name: "Community",
      path: "/user/community",
      iconName: "ic:baseline-group",
    },
    {
      name: "Capsule Course",
      path: "/user/capsule-course",
      iconName: "mdi:format-list-numbered",
    },
    {
      name: "Skills",
      path: "/user/skills",
      iconName: "mdi:lightning-bolt-outline",
    },
    {
      name: "Current Affairs",
      path: "/user/current-affairs",
      iconName: "mdi:newspaper-variant-outline",
      isShow: userData?.user?.goalCategory?.isGovernmentExam ?? false,
    },
    {
      name: "Setting",
      path: "/user/settings",
      iconName: "ic:outline-settings",
    },
    {
      name: "Logout",
      path: "/user/logout",
      iconName: "ic:outline-logout",
    },
  ];
  const [modalShow, setModalShow] = useState(false);

  function MyVerticallyCenteredModal(props) {
    const handleLogout = () => {
      localStorage.removeItem("StudyPlannerDaysAvailable");
      localStorage.removeItem("StudyPlannerHoursAvailable");
      localStorage.removeItem("StudyPlannerTotalDays");
      localStorage.removeItem("StudyPlannerendDate");
      localStorage.removeItem("StudyPlannerstartDate");
      localStorage.removeItem("authToken");
      localStorage.removeItem("userIdLogin");

      navigate("/");
    };
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div class="modal-body-lock">
            <h3>Are You sure you want to logout?</h3>
            <div>
              <button onClick={() => setModalShow(false)}>Cancel</button>
              <button onClick={() => handleLogout()}>Logout</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <div className="sidebar__container">
        <div className="sidebar__container1">
          <div className="sidebar__container1__1">
            <span>
              <img
                onClick={() => navigate(-1)}
                src={images.navBarLogo}
                alt="logo"
                className="sidebar__logo"
              />
            </span>
            <span>Digital Benchers</span>
          </div>

          <div className="sidebar__container1__2">
            {sideBarItems
              ?.filter((item) => item.isShow !== false)
              ?.map((item, index) => (
                <div
                  key={index}
                  onClick={() =>
                    item.path === "/user/logout"
                      ? setModalShow(true)
                      : navigate(item.path)
                  }
                  className={`sidebar__container1__2__1 ${
                    item.path === pathname ? "active" : ""
                  }`}
                >
                  {/* Icon Section */}
                  <span className="sidebar__container1__2__1__1">
                    <Icon icon={item.iconName} className="sidebar__icon" />
                  </span>
                  {/* Label Section */}
                  <span className="sidebar__container1__2__1__2">
                    {item.name}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;