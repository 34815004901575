import { useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import { UserBannerTopComponent } from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { useNavigate } from "react-router-dom";
import "./AboutExam.css";
import { getRequest } from "../../../services/apiService";
import { endpoints } from "../../../services/endPoints";
import { formatLinks } from "../../../utils/constants";
import { Spinner } from "react-bootstrap";
const AboutExam = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [goalId, setGoalId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getUserData = () => {
    getRequest({
      endpoint: endpoints.getUserProfile,
      setIsLoading,
    }).then((res) => {
      
      setGoalId(res?.data?.user?.goal?._id);
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (goalId) {
      getRequest({
        endpoint: endpoints.getAboutExamGoalById(goalId),
        setIsLoading,
      }).then((res) => {
        if (res.data && res.data.length > 0) {
          setData(res.data[res.data.length - 1]);
          
        } else {
          
        }
      });
    }
  }, [goalId]);
  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar />
        </div>
        <div className="user_container_width">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
              className="loader"
            >
              <Spinner animation="border" />
            </div>
          ) : data ? (
            <div className="user_aboutPage_container1 user_container_width">
              <div>
                {data?.image && (
                  <UserBannerTopComponent
                    divCSS="userAboutPageTopBannerDiv"
                    imagecss={"userAboutPageTopBannerDivImage"}
                    image={
                      data?.image ? data?.image : images.userAboutExamImage
                    }
                  />
                )}
              </div>
              <p className="user_aboutPage_heading">{data?.name}</p>
              <p className="user_aboutPage_text">
                <span className="user_aboutPage_text_span">
                  <span className="user_aboutPage_text_span1">
                    About the Exam
                  </span>
                  <span className="user_aboutPage_text_span2">
                    {data?.description?.split("\n")?.map((item) => (
                      <p>{item}</p>
                    ))}
                  </span>
                </span>
                <span className="user_aboutPage_text_span">
                  <span className="user_aboutPage_text_span1">
                    Eligibility Criteria{" "}
                  </span>
                  <span className="user_aboutPage_text_span2">
                    {data?.eligibilityCriteria?.split("\n")?.map((item) => (
                      <p>{item}</p>
                    ))}
                  </span>
                </span>
                <span className="user_aboutPage_text_span">
                  <span className="user_aboutPage_text_span1">
                    Important Dates:
                  </span>
                  <span className="user_aboutPage_text_span2">
                    {data?.importantDates?.split("\n")?.map((item) => (
                      <p>{item}</p>
                    ))}
                  </span>
                </span>
                <span className="user_aboutPage_text_span">
                  <span className="user_aboutPage_text_span1">Syllabus</span>
                  <span className="user_aboutPage_text_span2">
                    {data?.syllabus?.split("\n")?.map((item) => (
                      <p>{item}</p>
                    ))}
                  </span>
                </span>
                <span className="user_aboutPage_text_span">
                  <span className="user_aboutPage_text_span1">
                    Exam Pattern
                  </span>
                  <span className="user_aboutPage_text_span2">
                    {data?.examPattern?.split("\n")?.map((item) => (
                      <p>{item}</p>
                    ))}
                  </span>
                </span>
                <span className="user_aboutPage_text_span">
                  <span className="user_aboutPage_text_span1">
                    Any Important thing to show (Data)
                  </span>
                  <span className="user_aboutPage_text_span2">
                    {data?.additionalInfo?.split("\n")?.map((item) => (
                      <p>{item}</p>
                    ))}
                  </span>
                </span>
                <span className="user_aboutPage_text_span user_aboutPage_text_span2end">
                  <span className="user_aboutPage_text_span1">
                    Important Links (Main website to apply)
                  </span>
                  <span className="user_aboutPage_text_span2 ">
                    {data?.importantLinks
                      ? formatLinks(data.importantLinks)
                      : "No links available"}
                  </span>
                </span>
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default HOC(AboutExam);
