import { Icon } from "@iconify/react/dist/iconify.js";
import images from "../../utils/images";
import styles from "./common_css/common.module.css";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRequest } from "../../services/apiService";
import { endpoints } from "../../services/endPoints";

export const UserMenuBar = ({ name, DashboardName ,link}) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [goalId, setGoalId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  
  const getUserData = () => {
    getRequest({
      endpoint: endpoints.getUserNotifications,
      setIsLoading,
    }).then((res) => {
      
    });
  };
  
  useEffect(() => {
    return;
    getUserData();
  }, []);
  const menuIcons = [
    {
      image1: images.menubarIcon1,
      path: "/user-search",
    },
    {
      image1: images.menubarIcon2,
      popover: (
        <Popover id="popover-basic">
          <Popover.Body>
            <div className={styles.MySuccessRoadMapDiv21Popover}>
              <span>Notification 1</span>
              <span>Notification 2</span> 
              <span>Notification 3</span>
            </div>
          </Popover.Body>
        </Popover>
      ),
    },
    {
      image1: images.menubarIcon3,
      popover: (
        <Popover id="popover-basic">
          <Popover.Body>
            <div className={styles.MySuccessRoadMapDiv2Popover}>
              <span>English</span>
              <span>Hindi</span>
              <span>Gujarati</span>
              <span>Marathi</span>
              <span>Kannada</span>
              
  
            </div>
          </Popover.Body>
        </Popover>
      ),
    },
    {
      image1: images.menubarIcon4,
      path: "/user-wishlist",
    },
    {
      image1: images.menubarIcon5,
      path: "/user/cart",
    },
  ];
  return (
    <div  className={styles.userMenuBar}>
      <div>
        {name && <p className={styles.menubarName}>{name}</p>}
        {DashboardName && (
          <p onClick={() =>link && navigate(link)} className={styles.menubarDashboardName}>
            <span className={styles.menubarDashboardNameIcon}>
              <Icon style={{ color: "white" }} icon="bi:bar-chart-fill" />
            </span>
            <span className={styles.menubarDashboardNameText}>
              {DashboardName}
            </span>
          </p>
        )}
      </div>
      <div className={styles.menubarIcons}>
        {menuIcons.map((item, index) => {
          return  <div key={index} className={styles.menubarIconWrapper}>
          {item.popover ? (
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={item.popover}
              rootClose
            >
              <img
                src={item.image1}
                alt="icon"
                className={styles.menubarIcon}
                style={{ cursor: "pointer" }}
              />
            </OverlayTrigger>
          ) : (
            <img
              src={item.image1}
              alt="icon"
              className={styles.menubarIcon}
              onClick={() => item.path && navigate(item.path)}
              style={{ cursor: item.path ? "pointer" : "default" }}
            />
          )}
        </div>
          
        })}
      </div>
    </div>
  );
};
