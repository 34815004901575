import React from "react";
import images from "../../../utils/images";
import "./UserNavbar.css"
import { useNavigate } from "react-router-dom";
const UserWishlistBar = () => {
  const navigate = useNavigate();
  return (
    <div className="userNavbarContainer">
      <div className="userNavbar">
        <div className="userNavbarLogo">
          <span>
            <img onClick={() => navigate("/user/home")} src={images.userNavbarLogo} alt="imageLogo" />
          </span>
          <span>Wishlist</span>
        </div>
       
      </div>
    </div>
  );
};

export default UserWishlistBar;
