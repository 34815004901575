import { useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import {
  BannerComponent,
  UserBannerTopComponent
} from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { useNavigate, useParams } from "react-router-dom";
import "./StudyPlanner.css";
import { Button, Form } from "react-bootstrap";
import { showNotification } from "../../../components/common/APIComponents";
import { getRequest } from "../../../services/apiService";
import { endpoints } from "../../../services/endPoints";

const StudyPlannerPage4 = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [daysAvailable, setDaysAvailable] = useState([]);
  const [hoursAvailable, setHoursAvailable] = useState("");

  const days = [
    { name: "Mon", value: "monday" },
    { name: "Tue", value: "tuesday" },
    { name: "Wed", value: "wednesday" },
    { name: "Thu", value: "thursday" },
    { name: "Fri", value: "friday" },
    { name: "Sat", value: "saturday" },
    { name: "Sun", value: "sunday" },
  ];

  const hours = [
    { name: "3", value: "threeHour" },
    { name: "4.5", value: "fourAndHalfHour" },
    { name: "6", value: "sixHour" },
    { name: "7.5", value: "sevenAndHalfHour" },
    { name: "9", value: "nineHour" },
    { name: "10.5", value: "tenAndHalfHour" },
    { name: "12", value: "twelveHour" },
    { name: "13.5", value: "thirteenAndHalfHour" },
    { name: "15", value: "fifteenHour" },
    { name: "16.5", value: "sixteenAndHalfHour" },
  ];

  // Toggle daysAvailable state for checkboxes
  const handleDaysChange = (e) => {
    const { value, checked } = e.target;
    setDaysAvailable((prev) =>
      checked ? [...prev, value] : prev.filter((day) => day !== value)
    );
  };

  const handleHoursChange = (e) => {
    setHoursAvailable(e.target.value);
  };
  const { id, idName } = useParams();

  const handleSubmit = () => {
    if (!daysAvailable.length || !hoursAvailable) {
      return showNotification({
        type: "error",
        message: "Please select both days and hours.",
      })
    }

    
    
    localStorage.setItem("StudyPlannerDaysAvailable", daysAvailable);
    localStorage.setItem("StudyPlannerHoursAvailable", hoursAvailable);
    // localStorage

    navigate(
      `/user/study-planner-ai/${id}/${idName}/1/studyPlanner`
    );
  };
  const [bannerData, setBannerData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const getBanner = () => {
    const params = {
      position: "TOP",
      type: "StudyPlanner",
    };
    getRequest({
      endpoint: endpoints.getHomePageBanners,
      setIsLoading,
      params,
    }).then((res) => {
      setBannerData(res?.data);
    });
  };
  useEffect(() => {
    getBanner();
  }, []);
  return (
    <div className="user_container">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss="UserMainTopBannerDivImage"
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar DashboardName="Dashboard" link="/user/dashboard" />
        </div>
        <div className="user_container_width">
          <div>
         {bannerData&&   <BannerComponent
              image={bannerData?.image?.[bannerData?.image?.length - 1] ||images.userHandWrittenNotesImage}
              imagecss="userHandWrittenNotesBannerImage"
              alt="alttext"
            />}
            <div className="userStudyPlannerContainer">
              <div className="userStudyPlannerCardsContainer">
                <p className="userStudyPlanner2CardsHeading">
                  Please specify the days and hours you are available for
                  studying
                </p>

                <div className="userStudyPlanner4Div">
                  <div className="userStudyPlanner4Div1">
                    <p className="userStudyPlanner4Div1P">
                      Days Available for Studying
                    </p>
                    {days.map((day, index) => (
                      <div className="userStudyPlanner4Div1Box" key={index}>
                        <p className="userStudyPlanner4Div1BoxP">
                          <span>
                            <Form.Check
                              value={day.value}
                              name="days"
                              type="checkbox"
                              onChange={handleDaysChange}
                            />
                          </span>
                          <span>{day.name}</span>
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className="userStudyPlanner4Div2">
                    <p className="userStudyPlanner4Div1P">Hours Per Day</p>
                    <div className="userStudyPlanner4Div2BoxContainer">
                      {hours.map((hour, index) => (
                        <div className="userStudyPlanner4Div2Box" key={index}>
                          <p className="userStudyPlanner4Div1BoxP">
                            <span>
                              <Form.Check
                                type="radio"
                                name="hours"
                                value={hour.value}
                                onChange={handleHoursChange}
                              />
                            </span>
                            <span>{hour.name} Hour</span>
                          </p>
                        </div>
                      ))}
                    </div>
                    <div className="d-flex justify-content-end">
                      <Button onClick={handleSubmit}>Save & Next</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(StudyPlannerPage4);
