import React from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import { UserBannerTopComponent } from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { useNavigate } from "react-router-dom";
import "./TestWithVideo.css";
import {
  DashboardUserCourseCard,
  TestWithVideoCard,
} from "../../../components/common/Cards";
import { Button } from "react-bootstrap";
import { Icon } from "@iconify/react/dist/iconify.js";
const PurchasedTestSeries = () => {
  const navigate = useNavigate();
  const testwithVideoUserCard = [
    {
      name: "RRB NTPC (CBT 1 + CBT 2) 2024 Mock Test Series",
      iconImage: images.userTestWithVideoImage,
      description: "🌐 English, Hindi + 6 More",
      descriptionArray: ["1100 Total Tests", "10 Free tests"],
      courseText: "Start Foundation Course",
      pointsArray: [
        "96 Current General knowledge CT",
        "269 Chapter test",
        "+756 more test",
      ],
      users: 80,
    },
    {
      name: "RRB NTPC (CBT 1 + CBT 2) 2024 Mock Test Series",
      iconImage: images.userTestWithVideoImage,
      description: "🌐 English, Hindi + 6 More",
      descriptionArray: ["1100 Total Tests", "10 Free tests"],
      courseText: "Start Foundation Course",
      pointsArray: [
        "96 Current General knowledge CT",
        "269 Chapter test",
        "+756 more test",
      ],
      users: 80,
    },
  ];

  const rrbData = [
    {
      title: "All RRB (Railway) Exams General Knowledge - Mega Live Test",
      question: "10",
      time: "30 min",
      marks: "100",
      isLocked: false,
      others: "English, Hindi + 6 More",
    },
    {
      title: "All RRB (Railway) Exams General Knowledge - Mega Live Test",
      question: "10",
      time: "30 min",
      marks: "100",
      isLocked: false,
      others: "English, Hindi + 6 More",
    },
  ];

  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar link={"/user/dashboard"} />
        </div>
        <div className="user_container_width">
          <div className="TestWithVideoPage2Container">
            <div className="TestWithVideoPage2Containe1">
              <p className="TestWithVideoPage2Containe1P1">
                <span>
                  <img src={images.userTestWithVideoImage} alt="imageItem" />
                </span>
                <span>RRB NTPC (CBT 1 + CBT 2) 2024 Mock Test Series</span>
              </p>
              <hr />
              <p className="TestWithVideoPage2Containe1P2">
                <span>1000 Total Tests</span>
                <span>10 Free tests</span>
                <span>800 users</span>
                <span>English, Hindi + 6 More</span>
                <span></span>
              </p>
              <hr />
              <p>
                <ui className="TestWithVideoPage2Containe1P3">
                  <li>3 सफलता एक्सप्रेस</li>
                  <li>40 Sectional Test (CBT 1)</li>
                  <li>40 Sectional Test (CBT 2)</li>
                  <li>20 Sectional Test (CBT 1 + CBT 2)</li>
                  <li>10 Chapter Test</li>
                  <li>1 Current General knowledge CT</li>
                </ui>{" "}
              </p>
              <hr />
              <p className="TestWithVideoPage2Containe1P4">
                <span style={{fontWeight:"bold",fontSize:"1.2rem"}}>Purchased on 11 Nov 2024</span>
              </p>
            </div>
            <div className="TestWithVideoPage2Containe2">
              <p className="TestWithVideoPage2Containe2P1">
                RRB NTPC (CBT 1 + CBT 2) 2024 Mock Test Series (1124){" "}
              </p>
              <p className="TestWithVideoPage2Containe2P">
                <span>Chapter Test 11th(56)</span>
                <span>Chapter Test 12th(16)</span>
                <span>Minor Test(7)</span>
                <span>Semi Major Test(6)</span>
                <span>Full Length Test(10)</span>
              </p>
            </div>
            <div className="TestWithVideoPage2Containe3cont">
              {rrbData?.map((item, index) => (
                <div key={index} className="TestWithVideoPage2Containe3">
                  <p className="TestWithVideoPage2Containe3P">{item?.title}</p>
                  <p className="TestWithVideoPage2Containe3P2">
                    <span className="TestWithVideoPage2Containe3P2Span">
                      <span>{item?.question} Questions</span>
                      <span>{item?.marks} Marks</span>
                      <span>{item?.time} Time</span>
                    </span>
                    <span
                      onClick={() => navigate("/user/test-video-solution/1/1")}
                      className={
                        item?.isLocked
                          ? "testWithVideoPage2Containe3P2Spanactive"
                          : "testWithVideoPage2Containe3P2Spaninactive"
                      }
                    >
                      {item?.isLocked ? "Start Now" : "Start Now"}
                    </span>
                  </p>
                  <p className="TestWithVideoPage2Containe3P3rkt">
                    {item?.others}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(PurchasedTestSeries);
