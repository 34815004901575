import React, { useEffect, useState } from "react";
import "./Home.css";
import { BannerComponent } from "../../components/common/Banners";
import images from "../../utils/images";
import {
  CardComponentHomePage,
  CardComponentHomePage1,
  CardComponentHomePage2AI,
  CardComponentHomePage2Capsule,
  CardComponentHomePage2Community,
  CardComponentHomePage2Course,
  TeachersCardAboutUs,
} from "../../components/common/Cards";
import { Button, Form } from "react-bootstrap";
import Navbar from "../../components/layout/Navbar";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/layout/Footer";
import { endpoints } from "../../services/endPoints";
import { getRequest } from "../../services/apiService";
const TermsAndCondition = () => {
  const cards = [
    {
      name: "Study Planner",
      image: images.homePageLearningIcon1,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      exploreText: "Explore Study Planner",
    },
    {
      name: "Course",
      image: images.homePageLearningIcon2,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      exploreText: "Explore Course",
    },
    {
      name: "Test series",
      image: images.homePageLearningIcon3,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      exploreText: "Explore Test series",
    },
    {
      name: "Hand written notes",
      image: images.homePageLearningIcon4,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      exploreText: "Explore Hand written notes",
    },
    {
      name: "PYQ’s",
      image: images.homePageLearningIcon5,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      exploreText: "Explore PYQ’s",
    },
    {
      name: "Community",
      image: images.homePageLearningIcon6,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      exploreText: "Explore Community",
    },
    {
      name: "Capsule course",
      image: images.homePageLearningIcon7,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      exploreText: "Explore Capsule course",
    },
    {
      name: "Skills",
      image: images.homePageLearningIcon8,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      exploreText: "Explore Skills",
    },
  ];
  const cards1 = [
    { name: "Study Planner", id: "1", yOffset: 212 },
    {
      name: "Courses",
      id: "2",
      yOffset: -180,
    },
    {
      name: "Tests with video solution",
      id: "3",
      yOffset: -230,
    },
    {
      name: "Handwritten Notes by Toppers",
      id: "4",
      yOffset: -280,
    },
    {
      name: "PYQs with videos",
      id: "5",
      yOffset: -282,
    },
    {
      name: "Capsule Course",
      id: "6",
      yOffset: 460,
    },
  ];

  const cards2 = [
    {
      name: "Set Your exam goals",
      iconImage: images.homePageAIIcons1,
    },
    {
      name: "Input Your Availability",
      iconImage: images.homePageAIIcons2,
    },
    {
      name: "Set Your exam goals",
      iconImage: images.homePageAIIcons3,
    },
  ];

  const cards3 = [
    {
      name: "Foundation Course",
      iconImage: images.homePageCourseIcon1,
      description:
        " (suitable for learners having at least 6-8 months of prep time in Govt Exams segment and 1 year of prep time in PUG and K12 exams)",
      courseText: "Start Foundation Course",
    },
    {
      name: "Crash Course",
      iconImage: images.homePageCourseIcon2,
      description:
        "(suitable for learners having 3-4 months of prep time in Govt. Exams segment and 6-8 month of prep time in PUG & K12 exams)",
      courseText: "Start Crash Course",
    },
    {
      name: "Express Course",
      iconImage: images.homePageCourseIcon3,
      description:
        "(suitable for learners who want to brush up their concepts in 1-2 months of prep time in any segment. This course is ideal for those learners who already have a deep understanding of the concepts (thorough understanding)",
      courseText: "Start Express Course",
    },
  ];

  const cards4 = [
    {
      name: "Discussion Forums",
      imageIcon: images.homePageComunityImage1,
      description:
        "Engage in topic-based discussions, post questions, and participate in debates on key subjects.",
    },
    {
      name: "Study Groups",
      imageIcon: images.homePageComunityImage2,
      description:
        "Join study groups based on your exam or subject area. Work together, share strategies, and track each other’s progress.",
    },
    {
      name: "Doubts & Solutions",
      imageIcon: images.homePageComunityImage3,
      description:
        "Post your doubts, and receive solutions from other learners and mentors. Respond to queries and help others along the way.",
    },
    {
      name: "Challenges & Leaderboards",
      imageIcon: images.homePageComunityImage4,
      description:
        "Participate in weekly or monthly challenges. Earn points by solving quizzes, contributing to discussions, and climb the leaderboard.",
    },
  ];

  const cards5 = [
    { name: "💊 Bite-Sized Learning", imageIcon: images.homePageHealthIcon1 },
    { name: "💊 Expert Instructors", imageIcon: images.homePageHealthIcon2 },
    { name: "💊 Last-Minute Prep", imageIcon: images.homePageHealthIcon3 },
    {
      name: "💊 Video Lectures & Practice Questions",
      imageIcon: images.homePageHealthIcon4,
    },
  ];
  const teachersContainer = [
    {
      name: "Dr. Ravi Kumar",
      imageIcon: images.userAboutPageTeacherImage,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    },
    {
      name: "Dr. Ravi Kumar",
      imageIcon: images.userAboutPageTeacherImage,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    },
    {
      name: "Dr. Ravi Kumar",
      imageIcon: images.userAboutPageTeacherImage,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    },
    {
      name: "Dr. Ravi Kumar",
      imageIcon: images.userAboutPageTeacherImage,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    },
  ];
  const navigate = useNavigate();
  const [data, setData] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [privacy, setPrivacy] = useState("");

  const getUserData = () => {
    getRequest({
      endpoint: endpoints.getFooterSectionData,
      setIsLoading,
      params: { type: "TERMS" },
    }).then((res) => {
      setPrivacy(res.data?.terms);

      setData(res.data);
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div className="HomePage_Container">
      <div className="navbar_container_main">
        <Navbar isCSS={true} />
        <Navbar isCSS={false} />
      </div>
      <div className="HomePage_Body">
        <div className="homepage_sub_container">
          <div className="homepage_menubar_container">
            <div className="gridContainer1 fixMaxWidth">
              {cards1 &&
                cards1?.map((card, index) => (
                  <CardComponentHomePage1
                    key={index}
                    name={card.name}
                    id={card.id}
                    yOffsetP={card.yOffset}
                  />
                ))}
            </div>
          </div>
          <div
            style={{ marginTop: "2rem" }}
            className="aboutPageContainerMain fixMaxWidth"
          >
            <p>
              {}
              {data && data?.title} <span>Terms & Conditions</span>
            </p>
            <div>
              {privacy &&
                privacy?.split("\n").map((item, index) => (
                  <h3 style={{ textAlign: "justify" }} key={index}>
                    {item}
                  </h3>
                ))}
            </div>
          </div>

          <div className="homepage_sub_sub_container41">
            <div className="homepage_sub_sub_container41_p1">
              <div className="homepage_sub_sub_container41_p1_p1_p2 gridLayout2 fixMaxWidth">
                <div className="homepage_sub_sub_container41_p1_p2">
                  <p className="homepage_sub_sub_container41_p1_p2_p1">
                    <span className="homepage_sub_sub_container41_p1_p2_p1_span">
                      Download App Now !
                      <p className="homepage_sub_sub_container41_p1_p2_p1_span11rk">
                        {" "}
                        Use code WELCOMEMMT and get FLAT 12% OFF* on your first
                        domestic flight booking
                      </p>{" "}
                    </span>
                    <p className="aboutBtn_link">
                      <span>
                        <Form.Control
                          type="text"
                          placeholder="Enter Mobile Number"
                          className="form-control-about-page"
                        />
                      </span>
                      <span>
                        <Button
                          onClick={() => navigate("/signup")}
                          className="btn-about-page"
                          variant="primary"
                        >
                          Get app link
                        </Button>
                      </span>
                    </p>
                    <p className="aboutBtns">
                      <span>
                        <img src={images.userAboutPageDownloadBtn1} alt="" />
                      </span>
                      <span>
                        <img src={images.userAboutPageDownloadBtn2} alt="" />
                      </span>
                    </p>
                  </p>
                </div>
                <div className="homepage_sub_sub_container41_p1_p1">
                  <img
                    className="homepage_sub_sub_container41_p1_p1_img1"
                    src={images.hompageDownloadAppImage}
                    alt="BannerImage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default TermsAndCondition;
