import { useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import {
  BannerComponent,
  UserBannerTopComponent,
} from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { useNavigate } from "react-router-dom";
import "./PYQwithVideos.css";
import { PYQsNotesUserCardComponent } from "../../../components/common/Cards";
import { endpoints } from "../../../services/endPoints";
import { getRequest } from "../../../services/apiService";
const PYQwithVideos = () => {
  const navigate = useNavigate(); 
  const PYQsNotesUserCard = [
    {
      mainTitle: "NEET 2024 Question Paper (Total Tests: 1)",
      isFree: true,
      title: "NEET 2024 Official Paper (Held On: 05 May, 2024)",
      noOFQuestions: 10,
      marks: 100,
      time: "30 Min",
      subject: "🌐 English, Hindi + 6 More",
    },
    {
      mainTitle: "NEET 2024 Question Paper (Total Tests: 1)",
      isFree: true,
      title: "NEET 2024 Official Paper (Held On: 05 May, 2024)",
      noOFQuestions: 10,
      marks: 100,
      time: "30 Min",
      subject: "🌐 English, Hindi + 6 More",
    },
  ];
  const [data, setData] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [page, setPage] = useState(1);
  const [bannerData, setBannerData] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const getUserData = () => {
    getRequest({
      endpoint: endpoints.getPYQVideos,
      setIsLoading,
      params: {
        page: page,
        limit: 10,
      },
    }).then((res) => {
      
      setData(res?.data);
      setBannerData(res?.data?.[0]?.previousYearQuestionMainTitle);
      setHasNextPage(res?.pagination?.hasNextPage);
    });
  };

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar
            DashboardName={"Practice Analysis"}
            link={"/user/practice-analysis"}
          />
        </div>
        <div className="user_container_width">
          <div className="userPYQwithNotes_container">
            <div className="userPYQwithNotes_container1">
              {bannerData && (
                <BannerComponent
                  image={bannerData?.tileImage || ""}
                  imagecss={"userPYQsWithNotesImage1"}
                  alt="alttext"
                />
              )}
            </div>
            <div className="userPYQwithNotes_container2">
              {bannerData && (
                <>
                  <p className="userPYQwithNotes_container2_1">
                    {bannerData?.desc1 || ""}{" "}
                  </p>
                  <p className="userPYQwithNotes_container2_2">
                    <span>{bannerData?.heading || ""} </span>
                    <span>{bannerData?.desc2 || ""}</span>
                  </p>
                </>
              )}
              <div className="userPYQwithNotes_container2_3">
                <div className="userPYQwithNotes_container2_3_1">
                  {data &&
                    data?.map((i, index) => {
                      return (
                        <PYQsNotesUserCardComponent
                          key={index}
                          mainTitle={i.bundleName}
                          isFree={i.testCost === "Free" ? true : false}
                          title={i.bundleDescription}
                          noOFQuestions={i.noOFQuestions}
                          marks={i.marks}
                          time={i.bundleDuration}
                          subject={i.subject}
                          locale={i.locale}
                          id={i._id}
                          item={i}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(PYQwithVideos);
