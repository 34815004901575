import React from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import { UserBannerTopComponent } from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { useNavigate } from "react-router-dom";
import "./Course.css";
import {
  DashboardUserCourseCard,
  DashboardUserCoursesTypeCard,
  DashboardUserCoursesTypeCardCoursePage3,
} from "../../../components/common/Cards";
import { modifyLink } from "../../../utils/constants";
import AddToCart from "../../../components/common/AddToCart";
const PurchasedCourse = () => {
  const navigate = useNavigate();
  const courseUserCard3 = [
    {
      name: "Mathmatics",
      iconImage: images.homePageCourseIcon1,
      subTopics: 14,
      path: "/user/course/1/subjects/1",
    },
    {
      name: "English",
      iconImage: images.homePageCourseIcon2,
      subTopics: 14,
      path: "/user/courses/crash-course/1",
    },
    {
      name: "Science",
      iconImage: images.homePageCourseIcon3,
      subTopics: 14,
      path: "/user/courses/express-course/1",
    },
    {
      name: "Social Science",
      iconImage: images.homePageCourseIcon2,
      subTopics: 14,
      path: "/user/courses/express-course/1",
    },
    {
      name: "Computer Science",
      iconImage: images.homePageCourseIcon1,
      subTopics: 14,
      path: "/user/courses/express-course/1",
    },
  ];

  const addToCartItems = {
    name: "Foundation Course For JEE",
    image: images.homePageCourseIcon2,
    courseHeighlights: [
      "Includes 95 Courses",
      "1000+ notes & videos",
      "Unlimited practice test",
      "Personalize analysis",
    ],
    Price: "Purchased On 11 Nov 2024",
    PromoCodes: [
      {
        code: "JEE50",
        discount: "50%",
      },
      {
        code: "JEE20",
        discount: "20%",
      },
      {
        code: "JEE10",
        discount: "10%",
      },
    ],
  };
  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar
            name="Fundation Course For JEE"
            link={"/user/dashboard"}
          />
        </div>
        <div className="user_container_width">
          <div className="userCourseContainerPage3">
            <div className="userCourseContainerPage3Div1">
              {courseUserCard3.map((i, index) => (
                <DashboardUserCoursesTypeCardCoursePage3
                  name={i.name}
                  image={i.iconImage}
                  subTopics={i.subTopics}
                  path={i.path}
                />
              ))}
            </div>
            <div className="userCourseContainerPage3Div2">
              <AddToCart
                name={addToCartItems.name}
                image={addToCartItems.image}
                courseHighlights={addToCartItems.courseHeighlights}
                price={addToCartItems.Price}
                purchased={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(PurchasedCourse);
