import React, { useState } from "react";
import HOC from "../../../components/layout/HOC";
import styles from "./CSS/MySuccessRoadMap.module.css";

import images from "../../../utils/images";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate } from "react-router-dom";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

import { UserBannerTopComponent } from "../../../components/common/Banners";
import { Button, Form, OverlayTrigger, Popover } from "react-bootstrap";
import { SwiperComponent2Settings } from "../../../components/common/Cards";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { Bar } from "react-chartjs-2";
const HandwrittenNotesByTopperAnalysis = () => {
  const navigate = useNavigate();
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <div className={styles.MySuccessRoadMapDiv2Popover}>
          <span>Filter</span>
          <span>Overall Progress</span>
          <span>Weekly Progress</span>
        </div>
      </Popover.Body>
    </Popover>
  );
  const data = {
    labels: ["Subject 1", "Subject 2", "Subject 3", "Subject 4", "Subject 5"],
    datasets: [
      {
        type: "bar",
        label: "",
        data: [3, 6, 4, 7, 9],
        backgroundColor: [
          "#76e8ac",
          "#b163ec",
          "#fb8533",
          "#f4c947",
          "#3b98f5",
        ],
        borderRadius: 5,
        borderWidth: 1,
      },
      {
        type: "line",
        label: "",
        data: [3, 6, 4, 7, 9],
        borderColor: "#f00",
        borderWidth: 3,
        fill: false,
        tension: 0.4,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Subjects",
          color: "#6865f3",
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Reading Time in min",
          color: "#6865f3",
        },
      },
    },
  };
  const comp1 = [
    {
      icon: "bx:calendar",
      text: "Topic Completed",
      number: "30",
    },
    {
      icon: "mdi:tick-circle-outline",
      text: "Topics to Complete",
      number: "40%",
    },
    {
      icon: "mdi:alarm-clock-tick",
      text: "Overall time spent",
      number: "80H 1M",
    },
  ];
  const comp2 = [
    {
      videos: 80,
      practice: 30,
      notes: 20,
      test: 10,
      timeLeft: 10,
      isLocked: false,
      week: 1,
    },
    {
      videos: 80,
      practice: 10,
      notes: 0,
      test: 10,
      timeLeft: 10,
      isLocked: true,
      week: 2,
    },
    {
      videos: 80,
      practice: 30,
      notes: 20,
      test: 10,
      timeLeft: 10,
      isLocked: true,
      week: 3,
    },
  ];
  const chartData = [
    { name: "Correct", value: 40, color: "#66cc66" },
    { name: "Incorrect", value: 40, color: "#ff6600" },
    { name: "Unattempt", value: 20, color: "#cccccc" },
  ];
  const [selectedWeek, setSelectedWeek] = useState(1);
  return (
    <div className="MySuccessRoadMapContainer">
      <div>
        <UserBannerTopComponent
          divCSS="UserMainTopBannerDiv"
          imagecss={"UserMainTopBannerDivImage"}
          image={images.userDashboardTopBanner}
        />
      </div>
      <div className={styles.MySuccessRoadMapDiv1}>
        <span className={styles.MySuccessRoadMapDiv1Span1}>
          Handwritten Notes By Topper Analysis{" "}
        </span>
      </div>
      <div className={styles.MySuccessRoadMapDiv2Main}>
        <div className={styles.MySuccessRoadMapDiv2}>
          {comp1.map((item, index) => {
            return (
              <div className={styles.MySuccessRoadMapDiv2Div1} key={index}>
                <span className={styles.MySuccessRoadMapDiv2Div1Span1}>
                  <Icon
                    icon={item.icon}
                    style={{ width: "25px", height: "25px" }}
                  />
                </span>
                <span className={styles.MySuccessRoadMapDiv2Div1Span4}>
                  <span className={styles.MySuccessRoadMapDiv2Div1Span3}>
                    {item.number}
                  </span>
                  <span className={styles.MySuccessRoadMapDiv2Div1Span2}>
                    {item.text}
                  </span>
                </span>
              </div>
            );
          })}
        </div>

        <div className={styles.MySuccessRoadMapDiv5}>
          <p className={styles.MySuccessRoadMapDiv5P1}>Weekly Progress</p>
          <p className={styles.MySuccessRoadMapDiv5P2}>
            <span className={styles.MySuccessRoadMapDiv5P2Span1}>
              <span className={styles.MySuccessRoadMapDiv5P2Span2}>
                <Icon
                  className={styles.MySuccessRoadMapDiv5P2Span2Icon}
                  icon="mdi:tick-circle-outline"
                />
              </span>
              <span>20 Watched Out of 30</span>
            </span>
            <span className={styles.MySuccessRoadMapDiv5P2Span3}>
              {" "}
              <span>
                <CircularProgressbar
                  value={20}
                  text={`${20}%`}
                  styles={buildStyles({
                    pathColor: "#FFC851",
                    trailColor: "#D9D9D9",
                    textColor: "blue",
                  })}
                />
                <span
                  style={{
                    display: "block",
                    marginTop: "10px",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {20}
                </span>
              </span>
            </span>
            <span className={styles.MySuccessRoadMapDiv5P2Span4}>
              <span className={styles.MySuccessRoadMapDiv5P2Span4Span1}>
                <span>
                  <img src={images.userAllDashboardImage} alt="DasImage" />
                </span>
                <span>
                  <Icon
                    className={styles.MySuccessRoadMapDiv5P2Span2Icon2}
                    icon="mdi:tick-circle-outline"
                  />
                </span>
                <span className={styles.MySuccessRoadMapDiv5P2Span4Span2}>
                  Subject
                </span>
                <span className={styles.MySuccessRoadMapDiv5P2Span4Span3}>
                  topic
                </span>
                <span className={styles.MySuccessRoadMapDiv5P2Span4Span4}>
                  Duration
                </span>
              </span>
            </span>
          </p>
        </div>
        <div className={styles.MySuccessRoadMapDiv5}>
          <p className={styles.MySuccessRoadMapDiv5P1}>Overall Progress</p>
          <p className={styles.MySuccessRoadMapDiv5P2}>
            <span className={styles.MySuccessRoadMapDiv5P2Span1}>
              <span className={styles.MySuccessRoadMapDiv5P2Span2}>
                <Icon
                  className={styles.MySuccessRoadMapDiv5P2Span2Icon}
                  icon="mdi:tick-circle-outline"
                />
              </span>
              <span>20 Watched Out of 30</span>
            </span>
            <span className={styles.MySuccessRoadMapDiv5P2Span3}>
              {" "}
              <span>
                <CircularProgressbar
                  value={20}
                  text={`${20}%`}
                  styles={buildStyles({
                    pathColor: "#FFC851",
                    trailColor: "#D9D9D9",
                    textColor: "blue",
                  })}
                />
                <span
                  style={{
                    display: "block",
                    marginTop: "10px",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {20}
                </span>
              </span>
            </span>
            <span className={styles.MySuccessRoadMapDiv5P2Span4}>
              <span className={styles.MySuccessRoadMapDiv5P2Span4Span1}>
                <span>
                  <img src={images.userAllDashboardImage} alt="DasImage" />
                </span>
                <span>
                  <Icon
                    className={styles.MySuccessRoadMapDiv5P2Span2Icon2}
                    icon="mdi:tick-circle-outline"
                  />
                </span>
                <span className={styles.MySuccessRoadMapDiv5P2Span4Span2}>
                  Subject
                </span>
                <span className={styles.MySuccessRoadMapDiv5P2Span4Span3}>
                  topic
                </span>
                <span className={styles.MySuccessRoadMapDiv5P2Span4Span4}>
                  Duration
                </span>
              </span>
            </span>
          </p>
        </div>
        <div className={styles.TestAnalysisDiv2Chart}>
          <div>
            <p className={styles.MySuccessRoadMapDiv7Div1P1}>
              <span style={{color: "#7DE079"}}> 23%</span>
              <span>Topic Completation</span>
            </p>
            <p className={styles.MySuccessRoadMapDiv7Div1P1}>
              <span style={{color: "#FF0000"}}>1H 30M</span>
              <span>Avg time spend per subjects</span>
            </p>
          </div>
          <div className={styles.TestAnalysisDiv2ChartDiv1}>
            <p>Reading Analysis Over Time</p>
            <p>
              <div
                style={{ maxWidth: "100%", margin: "auto", padding: "20px" }}
              >
                <Bar data={data} options={options} />
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(HandwrittenNotesByTopperAnalysis);
