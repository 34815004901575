import { useContext, useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import {
  BannerComponent,
  UserBannerTopComponent,
} from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { useNavigate, useParams } from "react-router-dom";
import "./StudyPlanner.css";
import {
  DashboardCardSingleItem,
  // Component,
  StudyPlannerUserCardComponentPage5,
} from "../../../components/common/Cards";
import { UserContext } from "../../../Context/Context";
import { formatDateStudyPlanner } from "../../../utils/dateFormatter";
import { endpoints } from "../../../services/endPoints";
import { postRequest } from "../../../services/apiService";
import { Spinner } from "react-bootstrap";
const StudyPlannerPage5 = () => {
  const navigate = useNavigate();

  const { id, idName } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const userData = useContext(UserContext);
  const [data, setData] = useState([]);

  const StudyPlannerCards = [
    {
      todo: [
        { name: "10 Hours on video & Practice", type: "Video" },
        {
          name: "10 Hours on PYQs & Mock Tests",
          type: "Book",
        },
        { name: "10 Hours on video & Practice", type: "Video" },
        {
          name: "10 Hours on PYQs & Mock Tests",
          type: "Book",
        },
        { name: "10 Hours on video & Practice", type: "Video" },
      ],
      week: 1,
      isLocked: false,
    },

    {
      todo: [
        {
          name: "10 Hours on PYQs & Mock Tests",
          type: "Book",
        },
        { name: "10 Hours on video & Practice", type: "Video" },
        {
          name: "10 Hours on PYQs & Mock Tests",
          type: "Book",
        },
      ],
      week: 2,
      isLocked: true,
    },

    {
      todo: [
        { name: "10 Hours on video & Practice", type: "Video" },
        {
          name: "10 Hours on PYQs & Mock Tests",
          type: "Book",
        },
        { name: "10 Hours on video & Practice", type: "Video" },
      ],
      week: 3,
      isLocked: false,
    },

    {
      todo: [
        { name: "10 Hours on video & Practice", type: "Video" },
        {
          name: "10 Hours on PYQs & Mock Tests",
          type: "Book",
        },
        { name: "10 Hours on video & Practice", type: "Video" },
      ],
      week: 4,
      isLocked: false,
    },

    {
      todo: [
        { name: "10 Hours on video & Practice", type: "Video" },
        {
          name: "10 Hours on PYQs & Mock Tests",
          type: "Book",
        },
        { name: "10 Hours on video & Practice", type: "Video" },
      ],
      week: 5,
      isLocked: false,
    },

    {
      todo: [
        { name: "10 Hours on video & Practice", type: "Video" },
        {
          name: "10 Hours on PYQs & Mock Tests",
          type: "Book",
        },
        { name: "10 Hours on video & Practice", type: "Video" },
      ],
      week: 6,
      isLocked: false,
    },
    {
      todo: [
        { name: "10 Hours on video & Practice", type: "Video" },
        {
          name: "10 Hours on PYQs & Mock Tests",
          type: "Book",
        },
        { name: "10 Hours on video & Practice", type: "Video" },
      ],
      week: 7,
      isLocked: false,
    },
    {
      todo: [
        { name: "10 Hours on video & Practice", type: "Video" },
        {
          name: "10 Hours on PYQs & Mock Tests",
          type: "Book",
        },
        { name: "10 Hours on video & Practice", type: "Video" },
      ],
      week: 8,
      isLocked: false,
    },
  ];

  const daysAvailable = localStorage.getItem("StudyPlannerDaysAvailable");
  const hoursAvailable = localStorage.getItem("StudyPlannerHoursAvailable");
  const examStartDate = localStorage.getItem("StudyPlannerstartDate");
  const examEndDate = localStorage.getItem("StudyPlannerendDate");
  const daysRemaning = localStorage.getItem("StudyPlannerTotalDays");
  const goalCategory = userData?.user?.goalCategory;
  const goal = id;
  const studyPlan = idName;
  const [studyPlannerId, setStudyPlannerId] = useState("");
  const [isPurchased, setIsPurchased] = useState(false);
  const [userStudyPlannerId, setUserStudyPlannerId] = useState("");

  const getData = () => {
    const payload = {
      weeks: {
        monday: daysAvailable.includes("monday"),
        tuesday: daysAvailable.includes("tuesday"),
        wednesday: daysAvailable.includes("wednesday"),
        thursday: daysAvailable.includes("thursday"),
        friday: daysAvailable.includes("friday"),
        saturday: daysAvailable.includes("saturday"),
        sunday: daysAvailable.includes("sunday"),
      },
      hours: {
        threeHour: hoursAvailable.includes("threeHour"),
        fourAndHalfHour: hoursAvailable.includes("fourAndHalfHour"),
        sixHour: hoursAvailable.includes("sixHour"),
        sevenAndHalfHour: hoursAvailable.includes("sevenAndHalfHour"),
        nineHour: hoursAvailable.includes("nineHour"),
        tenAndHalfHour: hoursAvailable.includes("tenAndHalfHour"),
        twelveHour: hoursAvailable.includes("twelveHour"),
        thirteenAndHalfHour: hoursAvailable.includes("thirteenAndHalfHour"),
        fifteenHour: hoursAvailable.includes("fifteenHour"),
        sixteenAndHalfHour: hoursAvailable.includes("sixteenAndHalfHour"),
      },
    };

    if (examStartDate)
      payload.examStartDate = formatDateStudyPlanner(examStartDate);
    if (examEndDate) payload.examEndDate = formatDateStudyPlanner(examEndDate);
    // if (daysRemaning) payload.daysRemaning = daysRemaning;
    if (goal) payload.goal = goal;
    if (goalCategory) payload.goalCategory = goalCategory;
    if (studyPlan) payload.studyPlan = studyPlan;

    postRequest({
      endpoint: endpoints.getcreateStudyPlanner,
      data: payload,
      setIsLoading,
    }).then((res) => {
      console.log(res);
      setData(
        res?.data?.plannersWithPurchaseInfo?.[0]?.studyPlannerDetails
          ?.weeklyPlan
      );
      setStudyPlannerId(
        res?.data?.plannersWithPurchaseInfo?.[0]?.studyPlannerId
      );
      console.log(res?.data?.userStudyPlanner);
      setUserStudyPlannerId(res?.data?.userStudyPlanner);
      setIsPurchased(res?.data?.plannersWithPurchaseInfo?.[0]?.isPurchased);
      // navigate("/user/home");
    });
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar DashboardName={"Dashboard"} link={"/user/dashboard"} />
        </div>
        <div className="user_container_width">
          <div>
            <div>
              <BannerComponent
                image={images.userHandWrittenNotesImage}
                imagecss={"userHandWrittenNotesBannerImage"}
                alt="alttext"
              />
            </div>
            <div className="userStudyPlannerPage5Container1">
              {isLoading && (
                <div className="loader mt-4">
                  <Spinner animation="border" />
                </div>
              )}
              {data?.length > 0 && (
                <>
                  <p>🥳 Congratulations! </p>
                  <p>You have successfully created your study planner.</p>
                  <p>
                    Based on your inputs, here is your customized study
                    schedule:
                  </p>
                </>
              )}
            </div>
            <div className="userStudyPlannerPage5Container2 ">
              <div className="mb-4 gridLayout3">
                {data &&
                  data?.map((i, index) => {
                    return (
                      <StudyPlannerUserCardComponentPage5
                        key={index}
                        todo={i.tasks}
                        week={i.week}
                        weekResources={i.weekResources}
                        isLocked={!isPurchased || true}
                        studyPlannerId={studyPlannerId}
                        userId={userStudyPlannerId}
                      />
                    );
                  })}
                <DashboardCardSingleItem
                  image={images.userCourseViewDashboardImage}
                  link={"/user/my-sucess-roadmap"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(StudyPlannerPage5);
