export const modifyLink = (link) => {
  return link.toLowerCase().replace(/\s+/g, "-");
};

export const formatLinks = (text) => {
  
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  
  return text.split("\n").map((line, index) => {
    return (
      <p key={index}>
        {line.split(urlRegex).map((part, i) =>
          urlRegex.test(part) ? (
            <a href={part} target="_blank" rel="noopener noreferrer" key={i}>
              {part}
            </a>
          ) : (
            part
          )
        )}
      </p>
    );
  });
};


export const  convertToHoursAndMinutesFun=(decimal)=> {
  if (!decimal) return "0h 0m"; 
  const totalMinutes = Math.floor(decimal * 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours}h ${minutes}m`; 
}