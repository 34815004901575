import { useEffect, useState } from "react";
import "./TestWithVideo.css";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { startMainTest, startTest, startTestTWV } from "../../../../utils/otherApi";
import { showNotification } from "../../../../components/common/APIComponents";
const UserMainTest3 = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [instructionFileData, setInstructionFileData] = useState({});
  const { testId, testSeriesFileId } = useParams();
  const [goalId, setGoalId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const {
    todo,
    week,
    itemName,
    itemId,
    isPurchased,
    isInnerTest,
    isInnerTestName,
    testSeriesIdMain,
  } = location.state;
  console.log(location.state);
  const getUserData = () => {
    let itemIdCurrent;
    if (!isPurchased) {
      showNotification({
        type: "error",
        message: "You are not authorized to access this page",
      });
      return navigate("/user/home");
    }
    const parmas = {
      testId: todo?._id,
      testSeriesFileId: testSeriesIdMain,
    };

    if (itemName === "Course") {
      const payloadData = {
        courseId: itemId,
      };
      if (isInnerTestName === "practice") {
        payloadData.practiceQuestionId = testSeriesIdMain;
      } else {
        payloadData.testSeriesId = testSeriesIdMain;
      }
      startTest({
        payload: payloadData,
      });
    } else if (itemName === "StudyPlanner") {
      const payloadData = {
        studyPlannerId: itemId,
      };
      if (isInnerTestName === "practice") {
        payloadData.practiceQuestionId = testSeriesIdMain;
      } else {
        payloadData.testSeriesId = testSeriesIdMain;
      }
      startTest({
        payload: payloadData,
      });
    } else if (itemName === "PYQ") {
      const payloadData = {
       
      };
      startMainTest({
        payload: {
          // testSeriesId :todo?.testSeriesFiles?.[0]?._id,
          previousYearQuestionId: itemId
        },
      });
      startTest({
        payload: {
          testSeriesId :todo?.testSeriesFiles?.[0]?._id,
          previousYearQuestionId: itemId
        },
      });
      console.log("BOSS");
      // payloadData.previousYearQuestionId = itemId;
      // payloadData.testSeriesId = testSeriesIdMain;

      // startTest({
      //   payload: {
      //     previousYearQuestionId: itemId,
      //     testSeriesId: testSeriesIdMain,
      //   },
      // });
    } else if (itemName === "CapsuleCourse") {
      const payloadData = {
        capsuleCourseId: itemId,
      };
      if (isInnerTestName === "practice") {
        payloadData.practiceQuestionId = testSeriesIdMain;
      } else {
        payloadData.testSeriesId = testSeriesIdMain;
      }
      startTest({
        payload: payloadData,
      });
    }

    // startTestTWV({
    //   payload: {
    //     testSeriesId: id,
    //   },
    // });
    setData(
      isInnerTestName === "practice"
        ? todo?.practiceFiles?.[0]?.instructionFile
        : todo?.testSeriesFiles?.[0]?.instructionFile
    );
    // getRequest({
    //   endpoint: endpoints.getTestInstructions,
    //   setIsLoading,
    //   params: parmas,
    // }).then((res) => {

    //   setData(res?.data?.instructionFile);
    // });
  };

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <div className="TestWithVideoPage3Container">
      <div className="TestWithVideoPage3">
        <div className="TestWithVideoPage3Content">
          <p>Instructions</p>
          <h5>
            {data?.instructionsPage1 &&
              data?.instructionsPage1?.split("\n").map((item, index) => {
                return <p key={index}>{item}</p>;
              })}
          </h5>
          {/* <ul>
            <li>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus
              eum officia dolores rerum, animi laborum dolorem hic? Magnam, rem
              veritatis cum eligendi, optio quod numquam dolorem eum quae harum
              sapiente.
            </li>
            <li>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus
              eum officia dolores rerum, animi laborum dolorem hic? Magnam, rem
              veritatis cum eligendi, optio quod numquam dolorem eum quae harum
              sapiente.
            </li>
            <li>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus
              eum officia dolores rerum, animi laborum dolorem hic? Magnam, rem
              veritatis cum eligendi, optio quod numquam dolorem eum quae harum
              sapiente.
            </li>
          </ul> */}
          <div className="TestWithVideoPage3Buttons">
            <Button onClick={() => navigate(-1)}>Go Back</Button>
            <Button
              onClick={() =>
                navigate(`/user/mainTest/${id}/3`, {
                  state: {
                    todo,
                    week,
                    itemName,
                    itemId,
                    isPurchased,
                    isInnerTest,
                    isInnerTestName,
                    testSeriesIdMain,
                  },
                })
              }
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMainTest3;
