import React from "react";
import images from "../../../utils/images";
import "./UserNavbar.css";
import { useNavigate } from "react-router-dom";
const UserTestNavBar = () => {
  const navigate = useNavigate();


  return (
    <div className="userNavbarContainer">
      <div className="userNavbar">
        <div className="userNavbarLogo">
          <span>
            <img onClick={() => navigate("/user/home")} src={images.userNavbarLogo} alt="imageLogo" />
          </span>
          <span>Test Progress</span>
        </div>
        <div  onClick={() => navigate("/user/home")}  className="userNavbarHome">
          <span>
            <img 
              src={images.userNavbarHome}
              className="userNavbarHome"
              alt=""
            />
          </span>
          <span>Home</span>
        </div>
      </div>
    </div>
  );
};

export default UserTestNavBar;
