import "./css/Footer.css";
import images from "../../utils/images";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { endpoints } from "../../services/endPoints";
import { getRequest } from "../../services/apiService";
const Footer = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  
  const [isLoading, setIsLoading] = useState(false);

  const getUserData = () => {
    getRequest({
      endpoint: endpoints.getContactUs,
      setIsLoading,
    }).then((res) => {
      
      setData(res?.data?.[res?.data?.length - 1]);
      
    });
    

  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleInitialPageClick = ({ page }) => {
    localStorage.setItem("startingPage", page);
    navigate(`/signup`);
  };
  return (
    <div className="footerSection">
      <div className="footerSectionContainer">
        <div className="footerContainer">
          <div className="footerContent1">
            <p className="footerLogo">
              <span>
                <img src={images.footerLogo} alt="footerLogo" />
              </span>
              <span>
                Digital Benchers is democratising education, making it accessible
                to all. Join the revolution, learn on India's largest learning
                platform.
              </span>
            </p>
            <p className="footerLine">
              <hr />
            </p>
            <p className="footerContact">
              <span>Reach out to us</span>
              <span>
                Get your questions answered about learning with Digital Benchers.
              </span>
              <span>
                <span>
                  <Icon icon="bi:telephone-fill" />
                </span>
                <span>{data?.mobileNumber||""}</span>
              </span>
              <span>
                <span>
                  {" "}
                  <Icon icon="bi:envelope-fill" />{" "}
                </span>
                <span>{data?.email||""}</span>
              </span>
              <p className="footerSocial">
                <span>
                  <Icon icon="bi:instagram" className="icon-instagram" />
                </span>
                <span>
                  <Icon icon="bi:linkedin" className="icon-linkedin" />
                </span>
                <span>
                  <Icon icon="bi:whatsapp" className="icon-whatsapp" />
                </span>
                <span>
                  <Icon icon="bi:telegram" className="icon-telegram" />
                </span>
              </p>
            </p>
          </div>
          <div className="footerContent2">
            <div>
              <div className="footerContent2Div1">
                <p>Company</p>
                <p>
                  <span onClick={() => navigate("/about-us")}>About us</span>
                  <span onClick={() => navigate("/about-us")}>Careers</span>
                  <span onClick={() => navigate("/privacy-policy")}>
                    Privacy Policy
                  </span>
                  <span onClick={() => navigate("/terms-and-conditions")}>
                    {" "}
                    Terms & Conditions
                  </span>
                </p>
              </div>
              <div className="footerContent2Div1">
                <p>Help & support</p>
                <p>
                  <span onClick={() => navigate("/user-guidelines")}>
                    User Guidelines
                  </span>
                  <span onClick={() => navigate("/about-us")}>Site Map</span>
                  <span onClick={() => navigate("/refund-policy")}>Refund Policy</span>
                  <span onClick={() => navigate("/take-down-policy")}>
                    Takedown Policy
                  </span>
                  <span onClick={() => navigate("/grievance-policy")}>
                    Grievance Redressal
                  </span>
                </p>
              </div>
            </div>
            <div className="footerContent2Div2">
              <div className="footerContent2Div1">
                <p>Popular goals</p>
                <p>
                  <span onClick={() => navigate("/signup")}>IIT JEE</span>
                  <span onClick={() => navigate("/signup")}>UPSC</span>
                  <span onClick={() => navigate("/signup")}>SSC</span>
                  <span onClick={() => navigate("/signup")}> SDIR UGC NET</span>
                  <span onClick={() => navigate("/signup")}>NEET UG</span>
                </p>
              </div>
              <div className="footerContent2Div1">
                <p>Digital Benchers</p>
                <p>
                  <span onClick={() => handleInitialPageClick({ page: "user/study-planner-ai" })}>study planner</span>
                  <span onClick={() => handleInitialPageClick({ page: "user/course" })}>Courses</span>
                  <span onClick={() => handleInitialPageClick({ page: "user/test-video-solution" })}>Test series</span>
                  <span onClick={() => handleInitialPageClick({ page: "user/handwritten-notes" })}>
                    Hand written notes by toppers
                  </span>
                  <span onClick={() => handleInitialPageClick({ page: "user/community" })}>Community</span>
                  <span onClick={() => handleInitialPageClick({ page: "user/capsule-course" })}>Capsule course</span>
                  <span onClick={() => handleInitialPageClick({ page: "user/skills" })}>skills</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
