import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import images from "../../../utils/images";
import { useNavigate } from "react-router-dom";
import { deleteRequest, getRequest } from "../../../services/apiService";
import { endpoints } from "../../../services/endPoints";
import "../Skills/Skills.css";
import UserCart from "./UserCart";
import { Icon } from "@iconify/react/dist/iconify.js";
import { SkillsUserCardComponentCart } from "../../../components/common/Cards";

const UserCartDiv = () => {
  const Items = [
    "OverView",
    "Teacher Notes",
    "Q & A",
    "Hand written notes by topper",
  ];
  const testwithVideoUserCard = [
    {
      name: "RRB NTPC (CBT 1 + CBT 2) 2024 Mock Test Series",
      iconImage: images.userTestWithVideoImage,
      description: "🌐 English, Hindi + 6 More",
      descriptionArray: ["1100 Total Tests", "10 Free tests"],
      courseText: "Start Foundation Course",
      pointsArray: [
        "96 Current General knowledge CT",
        "269 Chapter test",
        "+756 more test",
      ],
      users: 80,
    },
    {
      name: "RRB NTPC (CBT 1 + CBT 2) 2024 Mock Test Series",
      iconImage: images.userTestWithVideoImage,
      description: "🌐 English, Hindi + 6 More",
      descriptionArray: ["1100 Total Tests", "10 Free tests"],
      courseText: "Start Foundation Course",
      pointsArray: [
        "96 Current General knowledge CT",
        "269 Chapter test",
        "+756 more test",
      ],
      users: 80,
    },
  ];
  const [selectedItem, setSelectedItem] = useState(0);
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const [pages, setPages] = useState("");
  const [duration, setDuration] = useState("");
  const [subTopics, setSubTopics] = useState("");
  const [rating, setRating] = useState("");
  const [itemId, setItemId] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);

  const getUserData = () => {
    getRequest({
      endpoint: endpoints.getCartItems,
      setIsLoading,
    }).then((res) => {
      setData(res?.data);
      setTotalPrice(res?.data?.totalPrice);
      const itemData = res?.data?.items?.[0];

      const itemType =
        itemData?.skill ||
        itemData?.course ||
        itemData?.capsuleCourse ||
        itemData?.studyPlanner ||
        itemData?.test ||
        itemData?.testSeries ||
        itemData?.previousYearQuestion ||
        null;

      setName(itemType?.name);
      setImage(itemType?.image || itemType?.iconImage || itemType?.courseImage);
      setTitle(itemType?.title || itemType?.name || itemType?.bundleName);
      setDuration(itemType?.duration);
      setSubTopics(itemType?.subTopics);
      setRating(itemType?.rating);
      setItemId(itemType?._id);
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

 

  const removeCartItem = (id) => {
    deleteRequest({
      endpoint: endpoints.deleteCart,
      setIsLoading,
    }).then((res) => {
      getUserData();
    });
  };

  return (
    <div>
      <div>
        <UserCart />
      </div>
      <div className="myWishlistContainer">
        <div className="CoursePage5MainContainer">
          <div>
            <div className="UserSearchBarDivContainer1">
              <p className="d-flex justify-content-between">
                <p
                  onClick={() => navigate("/user/home")}
                  className="UserSearchBarDivContainerP1"
                >
                  <span>
                    <img src={images.userNavbarHome} alt="homeIcon" />
                  </span>
                  <span>Home</span>
                </p>
                <p className="UserSearchBarDivContainerP1">
                  <span>
                    <Icon
                      icon="flowbite:cart-outline"
                      style={{ fontSize: "2.2rem" }}
                    />
                  </span>
                  <span>Cart</span>
                </p>
              </p>

              <div>
                <div className="UserWishlistBarDivContainer2">
                  <p className="UserSearchBarDivContainerP23x mt-1">
                    <p className="UserSearchBarDivContainerP231">
                      Your{" "}
                      {data?.items?.[0]?.skill
                        ? "Skills"
                        : data?.items?.[0]?.course
                        ? "Courses"
                        : data?.items?.[0]?.test
                        ? "Tests"
                        : data?.items?.[0]?.capsuleCourse
                        ? "Capsule Courses"
                        : data?.items?.[0]?.studyPlanner
                        ? "Study Planner"
                        : data?.items?.[0]?.testSeries
                        ? "Test Series"
                        : data?.items?.[0]?.handwrittenNotes
                        ? "Hand Written Notes"
                        : ""}
                    </p>
                    <div className="userHandWrittenNotesCards gridLayout4">
                      {(data?.items?.[0]?.course ||
                        data?.items?.[0]?.skill ||
                        data?.items?.[0]?.capsuleCourse ||
                        data?.items?.[0]?.studyPlanner ||
                        data?.items?.[0]?.testSeries ||
                        data?.items?.[0]?.previousYearQuestion ||
                        data?.items?.[0]?.handwrittenNotes) && (
                        <SkillsUserCardComponentCart
                          name={data?.name || data?.bundleName || ""}
                          image={
                            image ||
                            data?.iconImage ||
                            data?.courseImage ||
                            data?.tileImage
                          }
                          title={title || data?.bundleName || ""}
                          pages={duration || 0}
                          subtopics={subTopics}
                          rating={rating || 0}
                          id={itemId}
                          price={totalPrice}
                          key={itemId}
                        />
                      )}
                      {/* {data?.skill &&
                        data?.skill?.map((i, index) => (
                          <SkillsUserCardComponent
                            key={index}
                            name={i.name}
                            image={i.image}
                            title={i.title}
                            pages={i.duration || 0}
                            subtopics={i.subTopics}
                            rating={i.rating || 0}
                            id={i._id}
                          />
                        ))} */}
                    </div>
                  </p>
                  <p className="d-flex justify-content-end">
                    {(data?.items?.[0]?.course ||
                      data?.items?.[0]?.skill ||
                      data?.items?.[0]?.capsuleCourse ||
                      data?.items?.[0]?.studyPlanner ||
                      data?.items?.[0]?.testSeries ||
                      data?.items?.[0]?.previousYearQuestion ||
                      data?.items?.[0]?.handwrittenNotes) && (
                      <p>
                        <Button
                          className="UserWishlistBarDivContainer2Button"
                          variant="primary"
                          onClick={() => removeCartItem()}
                        >
                          Remove
                        </Button>
                        <Button
                          className="UserWishlistBarDivContainer2Button"
                          variant="primary"
                          onClick={() =>
                            navigate("/user/RazorPayment", {
                              state: {
                                data: data?.items?.[0],
                                price: totalPrice,
                                itemId: data?.items?.[0]?.course
                                  ? data?.items?.[0]?.course?._id
                                  : data?.items?.[0]?.skill
                                  ? data?.items?.[0]?.skill?._id
                                  : data?.items?.[0]?.test
                                  ? data?.items?.[0]?.test?._id
                                  : data?.items?.[0]?.capsuleCourse
                                  ? data?.items?.[0]?.capsuleCourse?._id
                                  : data?.items?.[0]?.studyPlanner
                                  ? data?.items?.[0]?.studyPlanner?._id
                                  : data?.items?.[0]?.testSeries
                                  ? data?.items?.[0]?.testSeries?._id
                                  : data?.items?.[0]?.previousYearQuestion
                                  ? data?.items?.[0]?.previousYearQuestion?._id
                                  : data?.items?.[0]?.handwrittenNotes
                                  ? data?.items?.[0]?.handwrittenNotes?._id
                                  : data?.items?.[0]?.course
                                  ? data?.items?.[0]?.course?._id
                                  : null,
                              },
                            })
                          }
                        >
                          Checkout
                        </Button>
                      </p>
                    )}
                  </p>
                  <p>
                    <hr />
                  </p>
                  <p></p>
                </div>
                {/* <div className="UserWishlistBarDivContainer2">
                  <p className="UserSearchBarDivContainerP23x mt-1">
                    <p className="UserSearchBarDivContainerP231">
                      Your Courses
                    </p>
                    <div className="gridLayout3 ">
                      {data &&
                        data?.courses?.map((i, index) => {
                          return (
                            <TestWithVideoCard
                              key={index}
                              name={i.name}
                              image={images.userStudyPlannerAiImage1 || i.image}
                              description={i.description}
                              descriptionArray={i.descriptionArray}
                              courseText={i.courseText}
                              pointsArray={i.pointsArray}
                              users={i.users}
                            />
                          );
                        })}
                    </div>
                  </p>
                  <p className="d-flex justify-content-end">
                    <Button
                      className="UserWishlistBarDivContainer2Button"
                      variant="primary"
                    >
                      Add to Cart
                    </Button>
                  </p>
                  <p>
                    <hr />
                  </p>
                  <p></p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCartDiv;
