import { useEffect, useState } from "react";
import "./GoalsPage.css";
import { SwiperComponentGoals } from "../../components/common/Cards";
import images from "../../utils/images";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { getRequest, putRequest } from "../../services/apiService";
import { endpoints } from "../../services/endPoints";
import {
  LoaderComponent,
  showNotification
} from "../../components/common/APIComponents";
const GoalsPage = () => {
  const navigate = useNavigate();
  // const slides1 = [
  //   {
  //     name: "Upcoming",
  //   },
  //   {
  //     name: "Bank",
  //   },
  //   {
  //     name: "Upcoming",
  //   },
  //   {
  //     name: "Bank",
  //   },
  //   {
  //     name: "Upcoming",
  //   },
  //   {
  //     name: "Bank",
  //   },
  //   {
  //     name: "Gate",
  //   },
  //   {
  //     name: "Gate",
  //   },
  //   {
  //     name: "Gate",
  //   },
  //   {
  //     name: "Gate",
  //   },
  //   {
  //     name: "Gate",
  //   },
  //   {
  //     name: "Gate",
  //   },
  // ];

  const exams = [
    {
      name: "JEE",
      image: images.goalsPageCourseImage1,
    },
    {
      name: "NEET",
      image: images.goalsPageCourseImage2,
    },
    {
      name: "JEE",
      image: images.goalsPageCourseImage3,
    },
    {
      name: "NEET",
      image: images.goalsPageCourseImage2,
    },
    {
      name: "NEET",
      image: images.goalsPageCourseImage2,
    },
  ];
  const [slectedSlide, setSelectedSlide] = useState("");
  const [selectedExam, setSelectedExam] = useState(0);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  const [selectedSlideId, setSelectedSlideId] = useState("");
  const [selectedSlideData, setSelectedSlideData] = useState([]);
const startingPage=localStorage.getItem("startingPage");
  const getData = () => {
    getRequest({ endpoint: endpoints.getGoalsData, setIsLoading }).then(
      (res) => {
        setData(res.data);
        setSelectedSlide(res?.data[0]?._id);
      }
    );
  };

  const getSelectedSlideData = () => {
    if(!slectedSlide) return;
    getRequest({
      endpoint: `${endpoints.getGoalById(slectedSlide)}`,
      setIsLoading,
    }).then((res) => {
      setSelectedSlideData(res.data);
    });
  };

  const handleSubmit = () => {
    const payload = {
      goalCategory: slectedSlide,
      goal: selectedSlideId,
    };
    if (!payload.goalCategory) {
      showNotification({
        type: "error",
        message: "Please select a goal category",
      });
      return;
    }
    if (!payload.goal) {
      showNotification({
        type: "error",
        message: "Please select a goal",
      });
      return;
    }
    putRequest({
      endpoint: endpoints.updateUserProfile,
      data: payload,
      setIsLoading,
    }).then((res) => {
      navigate(("/"+startingPage)||"/user/home");
    });
  };

  useEffect(() => {
    getSelectedSlideData();
  }, [slectedSlide]);

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="GoalsPage">
      <div className="GoalsPage__container">
        <p className="GoalsPage__container__1">
          Select Your <span>Goals</span>
        </p>
        <div className="GoalsPage__container__2">
          <div className="GoalsPage__container__2__1">
            {data && (
              <SwiperComponentGoals
                slides={data}
                spaceBetween={10}
                setSelectedSlide={setSelectedSlide}
                slectedSlide={slectedSlide}
              />
            )}
          </div>
          <div className="GoalsPage__container__2__2">
            {isLoading ? (
              <LoaderComponent />
            ) : data?.length === 0 ? (
              <></>
            ) : (
              <div className="GoalsPage__container__2__2__1__container">
                {selectedSlideData &&
                  selectedSlideData?.map((item, index) => (
                    <div
                      className={`GoalsPage__container__2__2__1__container__1 ${
                        selectedSlideId === item._id
                          ? "GoalsPage__container__2__2__1__container__1__active"
                          : ""
                      }`}
                      key={index}
                      onClick={() => setSelectedSlideId(item._id)}
                    >
                      <span>
                        <img src={item.image} alt="Img" />
                      </span>
                      <span>{item.name}</span>
                    </div>
                  ))}
              </div>
            )}
          </div>
          {data?.length > 0 && (
            <div className="GoalsPage__container__2__3__container">
              <Button loading={isLoading}
                onClick={() => handleSubmit()}
                className="GoalsPage__container__2__3__container__1"
               disabled={isLoading}
              >
             {isLoading ? "Loading..." : "Start Your Preparation"}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GoalsPage;
