import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../CapsuleCourse.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import { endpoints } from "../../../../../services/endPoints";
import { getRequest } from "../../../../../services/apiService";
import { UserMenuBar } from "../../../../../components/common/MenuBar";
import {
  BannerComponent,
  UserBannerTopComponent,
} from "../../../../../components/common/Banners";
import HOC from "../../../../../components/layout/HOC";
import images from "../../../../../utils/images";
import { CapsuleUserCardComponentCourseP4CC } from "../../../../../components/common/Cards";

const CapsuleCoursePage4 = () => {
  const navigate = useNavigate();
  const coursePage4Items = [
    {
      name: "Chapter 1",
      index: 1,
      Videos: [
        {
          name: "Topic 1",
          time: "0.16 min",
          isLocked: false,
          image: images.userDashboardTopBanner,
        },
        {
          name: "Topic 2",
          time: "0.16 min",
          isLocked: false,
          image: images.userDashboardTopBanner,
        },
      ],
      Docs: [
        {
          name: "Topic 1",
          pages: 14,
          isLocked: false,
          image: images.userDashboardTopBanner,
        },
        {
          name: "Topic 2",
          pages: 14,
          isLocked: false,
          image: images.userDashboardTopBanner,
        },
      ],
      Tests: [
        {
          name: "Topic 1",
          isLocked: false,
          questions: 10,
          time: "40 min",
          image: images.userDashboardTopBanner,
        },
        {
          name: "Topic 2",
          isLocked: false,
          questions: 10,
          time: "40 min",
          image: images.userDashboardTopBanner,
        },
      ],
    },
  ];
  const [subjectTitle, setSubjectTitle] = useState("");

  const [data, setData] = useState([]);
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const getUserData = () => {
    getRequest({
      endpoint: endpoints.getCapsuleCourseByIdFilter,
      setIsLoading,
      params: {
        courseId: params.id,
        // courseCategoryId: params.CourseType,
        subjectId: params.subjectId,
      },
    }).then((res) => {
      const getTitle = res?.data?.[0]?.subjects?.find(
        (subject) => subject?.subject?._id === params.subjectId
      )?.subject?.name;
      setSubjectTitle(getTitle);

      const allSubjectChapters = res?.data?.[0]?.subjects?.find(
        (subject) => subject?.subject?._id === params.subjectId
      )?.subSubjects;
      const allChapters = allSubjectChapters?.map((chapter) => {
        return chapter;
      });
      setData(allChapters);
    });
  };

  useEffect(() => {
    getUserData();
  }, [params.course]);
  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar link={"/user/dashboard"} />
        </div>
        <div className="user_container_width">
          <div>
            <div>
              <BannerComponent
                image={images.userHandWrittenNotesImage}
                imagecss={"userHandWrittenNotesBannerImage"}
                alt="alttext"
              />
            </div>
            <div>
              <div className="user_containerCoursePage4">
                <span className="user_containerCoursePage4Span">
                  <span>{subjectTitle}</span>
                  <span>42,826 students learning this week</span>
                </span>
                <span>
                  {Array.from({ length: Math.floor(5) }, (_, i) => (
                    <Icon
                      icon="emojione:star"
                      className="testWithVideoCardIcon"
                    />
                  ))}
                </span>
              </div>
              <div>
                <div className="user_containerCoursePage4Div212">
                  {data?.map((item, index) => {
                    const allTopicsinChapter = item.chapters?.flatMap(
                      (chapter) => chapter.topics
                    );
                    return (
                      <div
                        className="user_containerCoursePage4Div2Card"
                        key={index}
                      >
                        
                        <CapsuleUserCardComponentCourseP4CC
                          index={item.index}
                          name={item?.subSubject?.name || subjectTitle || ""}
                          chapterId={item.subSubject?._id}
                          Videos={allTopicsinChapter?.flatMap(
                            (topic) => topic.courseVideos || []
                          )}
                          Docs={allTopicsinChapter?.flatMap(
                            (topic) => topic.handwrittenNotes || []
                          )}
                          Tests={allTopicsinChapter?.flatMap(
                            (topic) => topic.testSeries || []
                          )}
                          PracticeTests={allTopicsinChapter?.flatMap(
                            (topic) => topic.previousYearQuestions || []
                          )}
                          itemName={"CapsuleCourse"}
                          itemId={params.id}
                          params={params}
                          path={`/user/courses/crash-course/${item.index}/1`}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(CapsuleCoursePage4);
