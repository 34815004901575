import { useContext, useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import {
  BannerComponent,
  UserBannerTopComponent,
} from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { useNavigate, useParams } from "react-router-dom";
import "./StudyPlanner.css";
import {
  DashboardCardSingleItem,
  // Component,
  StudyPlannerUserCardComponentPage5,
} from "../../../components/common/Cards";
import { UserContext } from "../../../Context/Context";
import { endpoints } from "../../../services/endPoints";
import { getRequest } from "../../../services/apiService";
import { Spinner } from "react-bootstrap";
const StudyPlannerPurchasedPage = () => {
  const navigate = useNavigate();

  const { id, idName, purchasedId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const userData = useContext(UserContext);
  const [data, setData] = useState([]);
  const [studyPlanId, setStudyPlanId] = useState("");

  const daysAvailable = localStorage.getItem("StudyPlannerDaysAvailable");
  const hoursAvailable = localStorage.getItem("StudyPlannerHoursAvailable");
  const examStartDate = localStorage.getItem("StudyPlannerstartDate");
  const examEndDate = localStorage.getItem("StudyPlannerendDate");
  const daysRemaning = localStorage.getItem("StudyPlannerTotalDays");
  const goalCategory = userData?.user?.goalCategory;
  const goal = id;
  const studyPlan = idName;
  const [studyPlannerId, setStudyPlannerId] = useState("");
  const [isPurchased, setIsPurchased] = useState(false);
  const [userStudyPlannerId, setUserStudyPlannerId] = useState("");
  

  const getData = () => {
    getRequest({
      endpoint: endpoints.getStudyPlannerById(purchasedId),

      setIsLoading,
    }).then((res) => {
      console.log(res,"REsData");
      setStudyPlanId(res?.data?.studyPlannerId||"");
      setData(res?.data?.weeklyPlans);
      setStudyPlannerId(
        res?.data?.plannersWithPurchaseInfo?.[0]?.studyPlannerId
      );
      setUserStudyPlannerId(res?.data?.userStudyPlanner);
      setIsPurchased(res?.data?.isBuy || false);
      // navigate("/user/home");
    });
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar DashboardName={"Dashboard"} link={"/user/dashboard"} />
        </div>
        <div className="user_container_width">
          <div>
            <div>
              <BannerComponent
                image={images.userHandWrittenNotesImage}
                imagecss={"userHandWrittenNotesBannerImage"}
                alt="alttext"
              />
            </div>
            <div className="userStudyPlannerPage5Container1">
            {isLoading && (
                <div className="loader mt-4">
                  <Spinner animation="border" />
                </div>
              )}
              {!isLoading && data?.length > 0 && (
                <>
                  <p>🥳 Congratulations! </p>
                  <p>You have successfully created your study planner.</p>
                  <p>
                    Based on your inputs, here is your customized study
                    schedule:
                  </p>
                </>
              )}
            </div>
            <div className="userStudyPlannerPage5Container2 ">
              <div className="mb-4 gridLayout3">
               
                {!isLoading && data &&
                  data?.map((i, index) => {
                    {console.log(i,"i");}
                    return (
                      <StudyPlannerUserCardComponentPage5
                        key={index}
                        todo={i.tasks}
                        week={i.week}
                        weekResources={i.weekResources}
                        isLocked={false}
                        studyPlannerId={studyPlanId}
                        userId={setUserStudyPlannerId}
                        subjectTime={i.subjectData}
                      />
                    );
                  })}
              {!isLoading&&  <DashboardCardSingleItem
                  image={images.userCourseViewDashboardImage}
                  link={"/user/my-sucess-roadmap"}
                />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(StudyPlannerPurchasedPage);
