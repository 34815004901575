import React, { useState } from "react";
import UserNavbar from "../Component/UserNavbar";
import { Accordion, Form } from "react-bootstrap";
import VideoPlayer from "../../../components/common/VideoPlayer";

import { Icon } from "@iconify/react/dist/iconify.js";
import images from "../../../utils/images";
import UserSearchBar from "./UserSearchBar";
import { TestWithVideoCard } from "../../../components/common/Cards";
import { useNavigate } from "react-router-dom";

const UserSearchBarDiv = () => {
  const Items = [
    "OverView",
    "Teacher Notes",
    "Q & A",
    "Hand written notes by topper",
  ];
  const testwithVideoUserCard = [
    {
      name: "RRB NTPC (CBT 1 + CBT 2) 2024 Mock Test Series",
      iconImage: images.userTestWithVideoImage,
      description: "🌐 English, Hindi + 6 More",
      descriptionArray: ["1100 Total Tests", "10 Free tests"],
      courseText: "Start Foundation Course",
      pointsArray: [
        "96 Current General knowledge CT",
        "269 Chapter test",
        "+756 more test",
      ],
      users:80
    },
    {
      name: "RRB NTPC (CBT 1 + CBT 2) 2024 Mock Test Series",
      iconImage: images.userTestWithVideoImage,
      description: "🌐 English, Hindi + 6 More",
      descriptionArray: ["1100 Total Tests", "10 Free tests"],
      courseText: "Start Foundation Course",
      pointsArray: [
        "96 Current General knowledge CT",
        "269 Chapter test",
        "+756 more test",
      ],
      users:80
    },
  ];
  const [selectedItem, setSelectedItem] = useState(0);
  const navigate=useNavigate();
  return (
    <div>
      <div>
        <UserSearchBar />
      </div>
      <div className="CoursePage5MainContainer">
        <div>
          <div className="UserSearchBarDivContainer">
            <p>
              <p onClick={() => navigate("/user/home")} className="UserSearchBarDivContainerP1">
                <span>
                  <img src={images.userNavbarHome} alt="homeIcon" />
                </span>
                <span>Home</span>
              </p>
              <p className="UserSearchBarDivContainerr1">
                <p> Treanding Searches</p>
                <p>
                  <span>
                    search{" "}
                    <Icon
                      icon="cuida:open-in-new-tab-outline"
                      style={{
                        color: "#6090F7",
                        width: "20px",
                        height: "20px",
                        
                      }}
                    />{" "}
                  </span>
                  <span>
                    {" "}
                    search{" "}
                    <Icon
                      icon="cuida:open-in-new-tab-outline"
                      style={{
                        color: "#6090F7",
                        width: "20px",
                        height: "20px",
                      }}
                    />{" "}
                  </span>
                </p>
              </p>
            </p>
           <p>
                <p className="UserSearchBarDivContainerP22">
                  <span>
                    <span>
                      <Form.Control
                        className="UserSearchBarDivContainerP22Input"
                        type="search"
                        placeholder="Search courses, test series and exams"
                        aria-label="Search courses, test series and exams"
                      />
                    </span>
                    <span>Search</span>
                  </span>
                </p>
                <p className="UserSearchBarDivContainerP23x">
                    <p className="UserSearchBarDivContainerP231">Popular Courses</p>
                <div className="gridLayout3">
              {testwithVideoUserCard.map((i, index) => {
                return (
                  <TestWithVideoCard
                    key={index}
                    name={i.name}
                    image={i.iconImage}
                    description={i.description}
                    descriptionArray={i.descriptionArray}
                    courseText={i.courseText}
                    pointsArray={i.pointsArray}
                    users={i.users}
                  />
                );
              })}
            </div>
                </p>
           </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSearchBarDiv;
