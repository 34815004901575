import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "swiper/css";
import '@fontsource/lateef'; 
import { Router, Toaster } from "./utils/imports";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <Toaster position="top-right" reverseOrder={false} richColors />
    <App />
  </Router>
);

reportWebVitals();
