import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./TestWithVideo.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import HOC from "../../../../../components/layout/HOC";
import { getRequest } from "../../../../../services/apiService";
import { endpoints } from "../../../../../services/endPoints";
import images from "../../../../../utils/images";
import { UserBannerTopComponent } from "../../../../../components/common/Banners";
import { UserMenuBar } from "../../../../../components/common/MenuBar";

const CourseTestSeriesPage2 = () => {
  const navigate = useNavigate();
  const testwithVideoUserCard = [
    {
      name: "RRB NTPC (CBT 1 + CBT 2) 2024 Mock Test Series",
      iconImage: images.userTestWithVideoImage,
      description: "🌐 English, Hindi + 6 More",
      descriptionArray: ["1100 Total Tests", "10 Free tests"],
      courseText: "Start Foundation Course",
      pointsArray: [
        "96 Current General knowledge CT",
        "269 Chapter test",
        "+756 more test",
      ],
      users: 80,
    },
    {
      name: "RRB NTPC (CBT 1 + CBT 2) 2024 Mock Test Series",
      iconImage: images.userTestWithVideoImage,
      description: "🌐 English, Hindi + 6 More",
      descriptionArray: ["1100 Total Tests", "10 Free tests"],
      courseText: "Start Foundation Course",
      pointsArray: [
        "96 Current General knowledge CT",
        "269 Chapter test",
        "+756 more test",
      ],
      users: 80,
    },
  ];

  const rrbData = [
    {
      title: "All RRB (Railway) Exams General Knowledge - Mega Live Test",
      question: "10",
      time: "30 min",
      marks: "100",
      isLocked: false,
      others: "English, Hindi + 6 More",
    },
    {
      title: "All RRB (Railway) Exams General Knowledge - Mega Live Test",
      question: "10",
      time: "30 min",
      marks: "100",
      isLocked: true,
      others: "English, Hindi + 6 More",
    },
  ];
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalMockTest, setTotalMockTest] = useState(0);
  const [totalSubjectTest, setTotalSubjectTest] = useState(0);
  const [totalChapterTest, setTotalChapterTest] = useState(0);
  const [totalPartialTest, setTotalPartialTest] = useState(0);
  const [testSeriesData, setTestSeriesData] = useState([]);
  const location=useLocation();  
  const {courseId1,isMainTest}=location.state;
 
  const initialTest =
    totalMockTest > 0
      ? "Mock Test"
      : totalSubjectTest > 0
      ? "Subject Test"
      : totalChapterTest > 0
      ? "Chapter Test"
      : totalPartialTest > 0
      ? "Partial Test"
      : "";
  const [selectedTest, setSelectedTest] = useState(initialTest);
  const { id } = useParams();
  const [mainData,setMainData] = useState({});

  const getUserData = () => {
    getRequest({
      endpoint: endpoints.getTestSeriesById(id),
      setIsLoading,
    }).then((res) => {
      
      setMainData(res?.data);
      const totalMockTest = res?.data?.test?.filter(
        (item) => item.testType === "Mock Test"
      ).length;
      const totalSubjectTest = res?.data?.test?.filter(
        (item) => item.testType === "Subject Test"
      ).length;
      const totalChapterTest = res?.data?.test?.filter(
        (item) => item.testType === "Chapter Test"
      ).length;
      const totalPartialTest = res?.data?.test?.filter(
        (item) => item.testType === "Partial Test"
      ).length;
      setTotalMockTest(totalMockTest);
      setTotalSubjectTest(totalSubjectTest);
      setTotalChapterTest(totalChapterTest);
      setTotalPartialTest(totalPartialTest);
    });
  };

  const getSeletedTestData = () => {
    const params = {
      type: selectedTest,
    };
    getRequest({
      endpoint: endpoints.getTestSeriesById(id),
      setIsLoading,
      params,
    }).then((res) => {
      
      setTestSeriesData(res?.data?.test);
    });
  };

  useEffect(() => {
    if (selectedTest) getSeletedTestData();
  }, [selectedTest]);
  useEffect(() => {
    getUserData();
  }, [id]);
  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar link={"/user/dashboard"} />
        </div>
        <div className="user_container_width">
          <div className="TestWithVideoPage2Container">
            <div className="TestWithVideoPage2Containe1">
              <p className="TestWithVideoPage2Containe1P1">
                <span>
                  <img src={mainData?.bundleImage|| ""} alt="imageItem" />
                </span>
                <span>{mainData?.bundleName||""}</span>
              </p>
              <hr />
              <p className="TestWithVideoPage2Containe1P2">
                
                <span>{mainData?.test?.flatMap((item) => item?.title).length} Total Tests</span>
                <span>{mainData?.test?.filter((item) => item?.testCost==="Free").length} Free tests</span>
                <span>800 users</span>
                <span>{mainData?.locale||""}</span>
                <span></span>
              </p>
              <hr />
              <p>
                <ui className="TestWithVideoPage2Containe1P3">
                {mainData?.bundleDescription?.split("\n")?.map((item) => <li>{item}</li>)||""}
                </ui>{" "}
              </p>
              <hr />
              
            </div>
            <div className="TestWithVideoPage2Containe2">
              <p className="TestWithVideoPage2Containe2P1">
              {mainData?.bundleName||""}
              </p>
              <p className="TestWithVideoPage2Containe2P">
                {totalMockTest > 0 && (
                  <span
                    style={{
                      color: selectedTest === "Mock Test" ? "blue" : "black",
                    }}
                    onClick={() => setSelectedTest("Mock Test")}
                  >
                    Mock Test
                  </span>
                )}{" "}
                {totalSubjectTest > 0 && (
                  <span
                    style={{
                      color: selectedTest === "Subject Test" ? "blue" : "black",
                    }}
                    onClick={() => setSelectedTest("Subject Test")}
                  >
                    Subject Test
                  </span>
                )}{" "}
                {totalChapterTest > 0 && (
                  <span
                    style={{
                      color: selectedTest === "Chapter Test" ? "blue" : "black",
                    }}
                    onClick={() => setSelectedTest("Chapter Test")}
                  >
                    Chapter Test
                  </span>
                )}{" "}
                {totalPartialTest > 0 && (
                  <span
                    style={{
                      color: selectedTest === "Partial Test" ? "blue" : "black",
                    }}
                    onClick={() => setSelectedTest("Partial Test")}
                  >
                    Partial Test
                  </span>
                )}
              </p>
            </div>
            {selectedTest && (
              <div className="TestWithVideoPage2Containe3cont">
                {testSeriesData?.map((item, index) => (
                  <div key={index} className="TestWithVideoPage2Containe3">
                    <p className="TestWithVideoPage2Containe3P">
                      {item?.chapter?.name||""}
                      {}
                    </p>
                    <p className="TestWithVideoPage2Containe3P2">
                      <span className="TestWithVideoPage2Containe3P2Spanb">
                        <span>{item?.testSeriesFiles?.question} Questions</span>
                        <span>
                          {item?.testSeriesFiles?.[0]?.instructionFile
                            ?.totalMarks || 0}{" "}
                          Marks
                        </span>
                        <span>
                          {
                            item?.testSeriesFiles?.[0]?.instructionFile
                              ?.totalTime
                          }{" "}
                          Time
                        </span>
                      </span>
                      <span
                        onClick={() =>
                          navigate(
                            `/user/course-instructions/${id}/${item?._id}/${item?.testSeriesFiles?.[0]?._id}`,{
                              state: {
                                courseId1,isMainTest
                            }}
                          )
                        }
                        style={{
                          backgroundColor: item?.isLocked
                            ? "#6090F7"
                            : "#7DE079",
                          color: "white",
                          borderRadius: "10px",
                          padding: "0.5rem 1rem",
                          cursor: "pointer",
                        }}
                      >
                        {item?.isLocked ? "Start Now" : "Start Now Free"}
                      </span>
                    </p>
                    <p className="TestWithVideoPage2Containe3P3rkt">
                      {item?.others}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(CourseTestSeriesPage2);
