import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./Course.css";
import { Spinner } from "react-bootstrap";
import HOC from "../../../../../components/layout/HOC";
import { UserMenuBar } from "../../../../../components/common/MenuBar";
import images from "../../../../../utils/images";
import { UserBannerTopComponent } from "../../../../../components/common/Banners";
import { getRequest } from "../../../../../services/apiService";
import { endpoints } from "../../../../../services/endPoints";
import AddToCart from "../../../../../components/common/AddToCart";
import {
  DashboardUserCoursesTypeCardCoursePage3,
  DashboardUserCoursesTypeCardCoursePage31,
  TestWithVideoCard1s,
} from "../../../../../components/common/Cards";
import { showNotification } from "../../../../../components/common/APIComponents";
const StudyPlannerPage6 = () => {
  const navigate = useNavigate();
  const courseUserCard3 = [
    {
      name: "Mathmatics",
      iconImage: images.homePageCourseIcon1,
      subTopics: 14,
      path: "/user/course/1/subjects/1",
    },
    {
      name: "English",
      iconImage: images.homePageCourseIcon2,
      subTopics: 14,
      path: "/user/courses/crash-course/1",
    },
    {
      name: "Science",
      iconImage: images.homePageCourseIcon3,
      subTopics: 14,
      path: "/user/courses/express-course/1",
    },
    {
      name: "Social Science",
      iconImage: images.homePageCourseIcon2,
      subTopics: 14,
      path: "/user/courses/express-course/1",
    },
    {
      name: "Computer Science",
      iconImage: images.homePageCourseIcon1,
      subTopics: 14,
      path: "/user/courses/express-course/1",
    },
  ];
  const testwithVideoUserCard = [
    {
      name: "RRB NTPC (CBT 1 + CBT 2) 2024 Mock Test Series",
      iconImage: images.userTestWithVideoImage,
      description: "🌐 English, Hindi + 6 More",
      descriptionArray: ["1100 Total Tests", "10 Free tests"],
      courseText: "Start Foundation Course",
      pointsArray: [
        "96 Current General knowledge CT",
        "269 Chapter test",
        "+756 more test",
      ],
      users: 80,
    },
  ];

  const addToCartItems = {
    name: "Foundation Course For JEE",
    image: images.homePageCourseIcon2,
    courseHeighlights: [
      "Includes 95 Courses",
      "1000+ notes & videos",
      "Unlimited practice test",
      "Personalize analysis",
    ],
    Price: "₹ 500",
    PromoCodes: [
      {
        code: "JEE50",
        discount: "50%",
      },
      {
        code: "JEE20",
        discount: "20%",
      },
      {
        code: "JEE10",
        discount: "10%",
      },
    ],
  };
  const [data, setData] = useState([]);
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [courseCategoryData, setCourseCategoryData] = useState({});
  const [courseData, setCourseData] = useState({});
  const [courseTestSeries, setCourseTestSeries] = useState([]);
  const location = useLocation();
  const { todo, week, itemName, itemId, isPurchased ,subjectTime} = location.state;
  const getUserData = () => {
    console.log(subjectTime,'st')
    const payload = {};

    params.course && (payload.course = params.course);
    payload.CourseType = params.CourseType;

    getRequest({
      endpoint: endpoints.getCourseByIdFilterStudyPlanner(params.id),
      setIsLoading,
      params: payload,
    }).then((res) => {
      console.log(todo);
      setData(todo);
      setCourseTestSeries(res?.data?.testSeries);
      setCourseCategoryData(res?.data);
      setCourseData(res?.data);
    });
  };

  useEffect(() => {
    getUserData();
  }, [params.course, params.CourseType]);
  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar name="" link={"/user/dashboard"} />
        </div>
        <div className="user_container_width">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
              className="loader"
            >
              <Spinner animation="border" />
            </div>
          ) : (
            <div className="userCourseContainerPage3">
              <div>
                <div className="userCourseContainerPage3Div1">
                123  {todo &&
                    todo.map((i, index) => (
                      <DashboardUserCoursesTypeCardCoursePage31
                        name={i.subject?.name}
                        image={images.UserSubjectsImage}
                        subTopics={i?.subSubjects?.length}
                        path={i.path}
                        onClick={() => {
                          i?.subject?.subSubject?.length > 0
                            ? navigate(
                                `/user/study-planner-ai/subject/subSubject/study/${params.id}/${week}`,
                                {
                                  state: {
                                    todo: i,
                                    week,
                                    itemName,
                                    itemId,
                                    isPurchased,
                                  },
                                }
                              )
                            : navigate(
                                `/user/study-planner-ai/subject/${params.id}/${week}`,
                                {
                                  state: {
                                    todo: i,
                                    week,
                                    itemName,
                                    itemId,
                                    isPurchased,
                                  },
                                }
                              );
                          if (courseData?.[0]?.isPurchased) {
                            navigate(
                              `/user/course/${params.CourseType}/${params.course}/${i.subject?._id}`
                            );
                          } else {
                            // showNotification({
                            //   type: "error",
                            //   message: "Please buy the study planner first",
                            // });
                          }
                        }}
                        subjectTime={subjectTime}
                        id={i.subject?._id}
                        key={index}
                      />
                    ))}
                  <div className="gridLayout1">
                    {courseTestSeries?.map((i, index) => {
                      return (
                        <TestWithVideoCard1s
                          key={index}
                          name={i.bundleName}
                          image={i.tileImage}
                          description={i.bundleDescription}
                          descriptionArray={i.descriptionArray}
                          courseText={i.courseText}
                          pointsArray={i.pointsArray}
                          users={i.users}
                          id={i._id}
                          isPurchased={true}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="user_testwithvideo_container2 "></div>
              </div>
              <div className="userCourseContainerPage3Div2">
                {/* <AddToCart
                  name={courseCategoryData?.courseCategoryId?.name}
                  image={courseCategoryData?.courseCategoryId?.image}
                  courseHighlights={
                    courseCategoryData?.courseCategoryId?.desc || ""
                  }
                  price={courseData?.Price}
                  PromoCodes={courseCategoryData?.PromoCodes}
                  purchased={
                    courseData?.isPurchased || courseData?.isPurchased || false
                  }
                  purchasedDate={
                    courseData?.purchaseDate || courseData?.purchaseDate || ""
                  }
                /> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HOC(StudyPlannerPage6);
