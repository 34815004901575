import { useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import { UserBannerTopComponent } from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./Course.css";
import {
  DashboardUserCoursesTypeCardCoursePage3,
  DashboardUserCoursesTypeCardCoursePage32,
  TestWithVideoCard1,
} from "../../../components/common/Cards";
import AddToCart from "../../../components/common/AddToCart";
import { getRequest, postRequest } from "../../../services/apiService";
import { endpoints } from "../../../services/endPoints";
import { Spinner } from "react-bootstrap";
import { showNotification } from "../../../components/common/APIComponents";
const Coursepage31 = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [courseCategoryData, setCourseCategoryData] = useState({});
  const [courseData, setCourseData] = useState({});
  const [courseTestSeries, setCourseTestSeries] = useState([]);
  const location = useLocation();
  const { todo } = location.state;

  if (!todo) {
    navigate(`/user/course/${params.CourseType}/${params.course}`);
  }
  const getUserData = () => {
    const payload = {};

    params.course && (payload.course = params.course);
    payload.CourseType = params.CourseType;
    payload.subjectId = params.subject;

    getRequest({
      endpoint: endpoints.getCourseByIdFilter,
      setIsLoading,
      params: payload,
    }).then((res) => {
      setData(res?.data?.[0]?.subjects);
      setCourseTestSeries(res?.data?.[0]?.testSeries);
      setCourseCategoryData(res?.data?.[0]);
      setCourseData(res?.data);
    });
  };

  useEffect(() => {
    getUserData();
  }, [params.course, params.CourseType]);

  const addToCart = (id) => {
    postRequest({
      endpoint: endpoints.addToCart,
      setIsLoading,
      data: {
        courseId: id,
        quantity: 1,
      },
    }).then((res) => {
      navigate("/user/cart");
    });
  };

  const addToWishlist = (id) => {
    postRequest({
      endpoint: endpoints.addToWishlist,
      setIsLoading,
      params: {
        courseId: id,
      },
    }).then((res) => {
      showNotification({
        type: "success",
        message: "Added to wishlist successfully",
      });
    });
  };

  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar name="" link={"/user/dashboard"} />
        </div>
        <div className="user_container_width">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
              className="loader"
            >
              <Spinner animation="border" />
            </div>
          ) : (
            <div className="userCourseContainerPage3">
              <div>
                <div className="userCourseContainerPage3Div1">
                  {todo &&
                    todo?.map((i, index) => (
                      <DashboardUserCoursesTypeCardCoursePage32
                        name={i?.subSubject?.name}
                        image={images.UserSubjectsImage}
                        subTopics={i?.subSubjects?.length}
                        path={i.path}
                        onClick={() => {
                          if (courseData?.[0]?.isPurchased) {
                            navigate(
                              `/user/course/${params.CourseType}/${params.course}/${params.subject}`,
                              {
                                state: {
                                  todo: i,
                                  itemName: "Course",
                                  itemId: params.course,
                                  isPurchased: true,
                                  isSubSubject: true,
                                },
                              }
                            );
                          } else {
                            showNotification({
                              type: "error",
                              message: "Please buy the course first",
                            });
                          }
                        }}
                      />
                    ))}
                  <div className="gridLayout1"></div>
                </div>
                <div className="user_testwithvideo_container2 "></div>
              </div>
              <div className="userCourseContainerPage3Div2"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HOC(Coursepage31);
