import { useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import { UserBannerTopComponent } from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { useNavigate } from "react-router-dom";
import "./Course.css";
import { DashboardUserCourseCard } from "../../../components/common/Cards";
import { Button } from "react-bootstrap";
import ProgressBar from "@ramonak/react-progress-bar";
import { endpoints } from "../../../services/endPoints";
import { getRequest } from "../../../services/apiService";

const Course = () => {
  const navigate = useNavigate();
  const courseUserCard = [
    {
      name: "Foundation Course",
      iconImage: images.homePageCourseIcon1,
      description:
        " (suitable for learners having at least 6-8 months of prep time in Govt Exams segment and 1 year of prep time in PUG and K12 exams)",
      courseText: "Start Foundation Course",
    },
    {
      name: "Crash Course",
      iconImage: images.homePageCourseIcon2,
      description:
        "(suitable for learners having 3-4 months of prep time in Govt. Exams segment and 6-8 month of prep time in PUG & K12 exams)",
      courseText: "Start Crash Course",
    },
    {
      name: "Express Course",
      iconImage: images.homePageCourseIcon3,
      description:
        "(suitable for learners who want to brush up their concepts in 1-2 months of prep time in any segment. This course is ideal for those learners who already have a deep understanding of the concepts (thorough understanding)",
      courseText: "Start Express Course",
    },
  ];
  const [data, setData] = useState([]);
  const [purchasedCourses, setPurchasedCourses] = useState([]);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const getUserData = () => {
    getRequest({
      endpoint: endpoints.getCourses,
      setIsLoading,
    }).then((res) => {
      setData(res?.data);
    });
  };

  const getPurchaseCourses = () => {
    getRequest({
      endpoint: endpoints.getMyCourses,

      params: { type: "Course", limit: 1000 },
      setIsLoading2,
    }).then((res) => {
      setPurchasedCourses(res?.data);
    });
  };

  useEffect(() => {
    getPurchaseCourses();
    getUserData();
  }, []);
  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar
            DashboardName={"Dashboard"}
            link={"/user/my-sucess-roadmap"}
          />
        </div>
        <div className="user_container_width">
          <div className="mt-2 mb-2">
            {purchasedCourses.length > 0 && (
              <p className="dashboardPage_continueWhereYouLeft_0">
                Your Purchased Courses
              </p>
            )}
            {purchasedCourses &&
              purchasedCourses.length > 0 &&
              purchasedCourses?.map((i, index) => (
                <div
                  onClick={() =>
                    navigate(
                      `/user/course/${i?.course?.courseCategoryId}/${i?.course?._id}`
                    )
                  }
                  className="dashboardPage_continueWhereYouLeft_1 user_container_width"
                >
                  <div className="dashboardPage_continueWhereYouLeft_1_1">
                    <img src={i?.course?.courseImage?.[0] || ""} alt="" />
                  </div>
                  <div className="dashboardPage_continueWhereYouLeft_1_2">
                    <span>{i?.course?.title || ""}</span>
                    <span>
                      <ProgressBar
                        completed={i?.progress || 0}
                        labelColor="gray"
                        bgColor="linear-gradient(90deg, #A37BF5, #F3A952) "
                        isLabelVisible={false}
                      />
                    </span>
                  </div>
                  <div className={"dashboardPage_continueWhereYouLeft_1_3"}>
                    <Button>Start</Button>
                  </div>
                </div>
              ))}
          </div>
          <div>
            <div className="gridLayout3">
              {data &&
                data.map((i, index) => (
                  <DashboardUserCourseCard
                    key={index}
                    name={i.name}
                    image={i?.image || i.iconImage}
                    description={i?.desc || i.description}
                    link={`/user/course/${i?._id}`}
                  />
                ))}
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(Course);
