import { useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import { UserBannerTopComponent } from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { useNavigate, useParams } from "react-router-dom";
import "./Dashboard.css";
import { getRequest } from "../../../services/apiService";
import { endpoints } from "../../../services/endPoints";
import { Spinner } from "react-bootstrap";
const ContentPageDataById = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [goalId, setGoalId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  const getUserData = () => {
    getRequest({
      endpoint: endpoints.getTopContentById(id),
      setIsLoading,
    }).then((res) => {
      
      setData(res?.data);
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar />
        </div>
        <div className="user_container_width">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
              className="loader"
            >
              <Spinner animation="border" />
            </div>
          ) : data ? (
            <div className="user_aboutPage_container1 user_container_width">
              <p className="user_aboutPage_heading text-center">
                {data?.headingText || ""}
              </p>
              <p className="user_aboutPage_text">
                <span className="user_aboutPage_text_span">
                  <span className="user_aboutPage_text_span2">
                    {data?.description || ""}
                  </span>
                </span>
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default HOC(ContentPageDataById);
