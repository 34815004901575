import { useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import { UserBannerTopComponent } from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { useNavigate } from "react-router-dom";

import { Icon } from "@iconify/react/dist/iconify.js";

const StudyPlannerBanner = () => {
  const navigate = useNavigate();
  const [goalId, setGoalId] = useState("");
  const [planSelected, setPlanSelected] = useState("lite");

  const features = [
    "Time Management",
    "Exam Readiness Analysis",
    "Personalized Feedbacks",
    "Exam Eligibility Check",
    "Topic-Wise Mastery",
    "Goal-Oriented Counseling",
    "Flexible Pacing",
  ];

  const liteFeatures = [true, true, true, false, false, false, false];
  const proFeatures = [true, true, true, true, true, true, true];

  const renderFeatures = (isPro) => {
    return (isPro ? proFeatures : liteFeatures).map((enabled, index) => (
      <p key={index} style={{ fontSize: "1.1rem", padding: "0.5rem 0" }}>
        <Icon
          icon={enabled ? "mdi:tick-circle" : "mdi:close-circle"}
          width="24"
          height="24"
          style={{ color: enabled ? "#00c853" : "#d50000" }}
        />
      </p>
    ));
  };

  return (
    <div style={{ padding: "2rem" }}>
      <UserBannerTopComponent
        divCSS="UserMainTopBannerDiv"
        imagecss="UserMainTopBannerDivImage"
        image={images.userDashboardTopBanner}
      />
      <div style={{ marginTop: "2rem" }}>
        <UserMenuBar />
      </div>
      <div style={{ marginTop: "2rem" }}>
        <div
          style={{
            backgroundColor: "#f9f9f9",
            padding: "2rem",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "2fr 1fr 1fr",
              gap: "1rem",
              margin: "0 auto",
              width: "90%",
              textAlign: "left",
              marginBottom:"2rem"
            }}
          >
            <div>
              <div
                style={{
                  
                  padding: "0.7rem 0",
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                }}
              >
                Choose Your Plan and Start Learning
              </div>

              {features.map((feature, index) => (
                <p
                  key={index}
                  style={{
                    fontSize: "1.2rem",
                    paddingBottom: "0.5rem",
                    paddingTop:  "0.4rem",
                    
                  }}
                >
                  {feature}
                </p>
              ))}
            </div>

            <div style={{ textAlign: "center" }}>
              <div
                onClick={() => setPlanSelected("lite")}
                style={{
                  padding: "0.5rem 1rem",
                  fontWeight: "bold",
                  borderRadius: "8px",
                  textTransform: "uppercase",
                  backgroundColor:
                    planSelected === "lite" ? "#B3DEF7" : "transparent",
                  cursor: "pointer",
                }}
              >
                Personalized Lite
              </div>
              {renderFeatures(false)}
            </div>

            <div style={{ textAlign: "center" }}>
              <div
                onClick={() => setPlanSelected("pro")}
                style={{
                  padding: "0.5rem 1rem",
                  fontWeight: "bold",
                  borderRadius: "8px",
                  textTransform: "uppercase",
                  backgroundColor:
                    planSelected === "pro" ? "#B3DEF7" : "transparent",
                  cursor: "pointer",
                }}
              >
                Personalized Pro
              </div>
              {renderFeatures(true)}
            </div>
          </div>

          <div style={{ marginTop: "2rem", textAlign: "center" }}>
            <button
              disabled={planSelected === "pro"}
              onClick={() => navigate("/user/study-planner-ai")}
              style={{
                backgroundColor: planSelected === "pro" ? "#a3a3a3" : "#0334A1",
                color: planSelected === "pro" ? "#000" : "#fff",
                padding: "0.75rem 1.5rem",
                fontSize: "1.5rem",
                border: "none",
                borderRadius: "8px",
                cursor: planSelected === "pro" ? "not-allowed" : "pointer",
                width: "90%",
                fontWeight: "bold",
              }}
            >
            { planSelected === "pro" ? "COMING SOON" :  "CONTINUE WITH YOUR STUDY PLAN"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(StudyPlannerBanner);
