import UserNavbar from "../Component/UserNavbar";
import PageFlipPDF from "../../../components/common/PDFviewer";
import { useLocation } from "react-router-dom";
import "./Course.css";

const CoursePage6 = () => {
  const defaultPdfUrl = "https://res.cloudinary.com/dvwecihog/image/upload/v1731919696/Digital-Benchers-Backend/userProfile/nots2fprn7ffrmku9hfh.pdf";
  
  const location = useLocation();
  const noteData = location.state?.noteData;
  const title=location.state?.title

  // Function to validate URLs
  const isValidUrl = (url) => {
    
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  
  const pdfUrl =  isValidUrl?noteData: defaultPdfUrl;

  return (
    <div>
      <UserNavbar title={title||""}  />
      <div className="CoursePage5MainContainer">
        <div className="CoursePage5Containerboss">
        <div style={{border:"1px solid #a3a3a3",maxWidth:"1300px"}}>

          <PageFlipPDF pdfUrl={pdfUrl} />
        </div>
        </div>
      </div>
    </div>
  );
};

export default CoursePage6;
