import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL || "https://default-url.com/api";

const api = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 20000,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      // if (
      //   error.response?.data?.message === "No token provided! Access prohibited"
      // ) {
      //   ShowNotification({
      //     type: "error",
      //     message: "You are not authorized to access this page",
      //   });
      //   return (window.location.href = "/");
      // }
      console.error("API Error:", error.response.data.message || error.message);
    } else if (error.request) {
      console.error("No response from server:", error.message);
    } else {
      console.error("Unexpected error:", error.message);
    }
    return Promise.reject(error);
  }
);

export default api;
