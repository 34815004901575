import HandWrittenNotes from "../pages/UserPages/HandWrittenNotes/HandWrittenNotes";
import GoalsPage from "../pages/Goals/GoalsPage";
import Home from "../pages/Home/Home";
import LoginPage from "../pages/LoginSignUp/LoginPage";
import AboutExam from "../pages/UserPages/AboutExam/AboutExam";
import Course from "../pages/UserPages/Courses/Course";
import Dashboard from "../pages/UserPages/Dashboard/Dashboard";
import TestWithVideo from "../pages/UserPages/TestWithVideo/TestWithVideo";
import PYQwithVideos from "../pages/UserPages/PYQwithVideos/PYQwithVideos";
import CapsuleCourse from "../pages/UserPages/CapsuleCourse/CapsuleCourse";
import Skills from "../pages/UserPages/Skills/Skills";
import Community from "../pages/UserPages/Community/Community";
import CoursePage2 from "../pages/UserPages/Courses/CoursePage2";
import Coursepage3 from "../pages/UserPages/Courses/Coursepage3";
import HandWrittenNotesPage2 from "../pages/UserPages/HandWrittenNotes/HandWrittenNotesPage2";
import StudyPlanner from "../pages/UserPages/StudyPlanner/StudyPlanner";
import StudyPlannerPage2 from "../pages/UserPages/StudyPlanner/StudyPlannerPage2";
import StudyPlannerPage3 from "../pages/UserPages/StudyPlanner/StudyPlannerPage3";
import StudyPlannerPage4 from "../pages/UserPages/StudyPlanner/StudyPlannerPage4";
import StudyPlannerPage5 from "../pages/UserPages/StudyPlanner/StudyPlannerPage5";
import CoursePage4 from "../pages/UserPages/Courses/CoursePage4";
import Settings from "../pages/UserPages/Settings/Settings";
import MySuccessRoadMap from "../pages/UserPages/UserDashboard/MySuccessRoadMap";
import VideoAnalysis from "../pages/UserPages/UserDashboard/VideoAnalysis";
import PracticeAnalysis from "../pages/UserPages/UserDashboard/PracticeAnalysis";
import CoursePage5 from "../pages/UserPages/Courses/CoursePage5";
import TestWithVideoPage3 from "../pages/UserPages/TestWithVideo/TestWithVideoPage3";
import TestWithVideoPage4 from "../pages/UserPages/TestWithVideo/TestWithVideoPage4";
import TestWithVideoPage5 from "../pages/UserPages/TestWithVideo/TestWithVideoPage5";
import TestWithVideoPage2 from "../pages/UserPages/TestWithVideo/TestWithVideoPage2";
import CurrentAffairs from "../pages/UserPages/Current Affairs/CurrentAffairs";
import TestAnalysis from "../pages/UserPages/UserDashboard/TestAnalysis";
import HandwrittenNotesByTopperAnalysis from "../pages/UserPages/UserDashboard/HandwrittenNotesByTopperAnalysis";
import CapsuleCourseAnalysis from "../pages/UserPages/UserDashboard/CapsuleCourseAnalysis";
import UserSearchBarDiv from "../pages/UserPages/Component/UserSearchBarDiv";
import CoursePage6 from "../pages/UserPages/Courses/CoursePage6";
import UserWishlistBarDiv from "../pages/UserPages/Component/UserWishlistBarDiv";
import SkillsPage2 from "../pages/UserPages/Skills/SkillsPage2";
import UserTestDiv from "../pages/UserPages/Component/UserTestDiv";
import DetailSolutionDiv from "../pages/UserPages/Component/DetailSolutionDiv";
import PurchasedCourse from "../pages/UserPages/Courses/PurchasedCourse";
import PurchasedTestSeries from "../pages/UserPages/TestWithVideo/PurchasedTestSeries"
import PurchasedHandwrittenNotes from "../pages/UserPages/HandWrittenNotes/PurchasedHandwrittenNotes";
import AboutUs from "../pages/Home/AboutUs";
import PYQwithVideosPage2 from "../pages/UserPages/PYQwithVideos/PYQwithVideosPage2";
import SkillsPage3 from "../pages/UserPages/Skills/SkillsPage3";
import CapsuleCoursePage3 from "../pages/UserPages/CapsuleCourse/InnerPages/Courses/CapsuleCoursePage3";
import CapsuleCoursePage4 from "../pages/UserPages/CapsuleCourse/InnerPages/Courses/CapsuleCoursePage4";
import CapsuleCoursePage5 from "../pages/UserPages/CapsuleCourse/InnerPages/Courses/CapsuleCoursePage5";
import CheckoutPage from "../components/common/CheckoutPage";
import UserCartDiv from "../pages/UserPages/Component/UserCartDiv";
import PYQwithVideosPage3 from "../pages/UserPages/PYQwithVideos/Inner/TestWithVideo/PYQwithVideosPage3";
import PYQwithVideosPage4 from "../pages/UserPages/PYQwithVideos/Inner/TestWithVideo/PYQwithVideosPage4";
import PYQwithVideosPage5 from "../pages/UserPages/PYQwithVideos/Inner/TestWithVideo/PYQwithVideosPage5";
import StudyPlannerPage6 from "../pages/UserPages/StudyPlanner/inner/Courses/StudyPlannerPage6";
import StudyPlannerPage7 from "../pages/UserPages/StudyPlanner/inner/Courses/StudyPlannerPage7";
import StudyPlannerPage8 from "../pages/UserPages/StudyPlanner/inner/Courses/StudyPlannerPage8";
import ContentPageDataById from "../pages/UserPages/Dashboard/ContentPageDataById";
import RazorpayPayment from "../services/RazorPayComponent";
import StudyPlannerPurchasedPage from "../pages/UserPages/StudyPlanner/StudyPlannerPurchasedPage";
import CourseTestSeriesPage2 from "../pages/UserPages/Courses/inner/TestWithVideo/CourseTestSeriesPage2";
import CourseTestSeriesPage3 from "../pages/UserPages/Courses/inner/TestWithVideo/CourseTestSeriesPage3";
import CourseTestSeriesPage4 from "../pages/UserPages/Courses/inner/TestWithVideo/CourseTestSeriesPage4";
import CourseTestSeriesPage5 from "../pages/UserPages/Courses/inner/TestWithVideo/CourseTestSeriesPage5";
import StudyPlannerTestWithVideo1 from "../pages/UserPages/StudyPlanner/inner/TestWithVideo/StudyPlannerTestWithVideo1";
import TestWithVideoPage3tca from "../pages/UserPages/Current Affairs/Test/TestWithVideo/TestWithVideoPage3tca";
import TestWithVideoP from "../pages/UserPages/TestWithVideo/TestWithVideoP";
import PrivacyAndPolicy from "../pages/Home/PrivacyAndPolicy";
import TermsAndCondition from "../pages/Home/TermsAndCondition";
import UG from "../pages/Home/UG";
import RP from "../pages/Home/RP";
import TP from "../pages/Home/TP";
import GP from "../pages/Home/GP";
import StudyPlannerTestWithVideo2 from "../pages/UserPages/StudyPlanner/inner/TestWithVideo/StudyPlannerTestWithVideo2";
import UserMainTest2 from "../pages/UserPages/AllTestComponent/TestWithVideo/UserMainTest2";
import UserMainTest3 from "../pages/UserPages/AllTestComponent/TestWithVideo/UserMainTest3";
import UserMainTest4 from "../pages/UserPages/AllTestComponent/TestWithVideo/UserMainTest4";
import UserMainTest5 from "../pages/UserPages/AllTestComponent/TestWithVideo/UserMainTest5";
import StudyPlannerBanner from "../pages/UserPages/StudyPlanner/StudyPlannerBanner";
import TestWithVideoPage4tca from "../pages/UserPages/Current Affairs/Test/TestWithVideo/TestWithVideoPage4tca";
import TestWithVideoPage5tca from "../pages/UserPages/Current Affairs/Test/TestWithVideo/TestWithVideoPage5tca";
import HandwrittenNotesP22 from "../pages/UserPages/HandWrittenNotes/HandwrittenNotesP22";
import HandwrittenNotesP23 from "../pages/UserPages/HandWrittenNotes/HandwrittenNotesP23";
import StudyPlannerPage61 from "../pages/UserPages/StudyPlanner/inner/Courses/StudyPlannerPage61";
import CoursePage31 from "../pages/UserPages/Courses/CoursePage31";
export const routes = [
  {
    path: "/",
    name: "Home",
    element: <Home />,
  },
  {
    path: "/signup",
    name: "Signup",
    element: <LoginPage />,
  },
  {
    path: "/select-goals",
    name: "Select Goals",
    element: <GoalsPage />,
  },
  {
    path: "/user/home",
    name: "User Dashboard",
    element: <Dashboard />,
  },
  {
    path: "/user/top-content/:id",
    name: "User Dashboard",
    element: <ContentPageDataById />,
  },
  {
    path: "/user/about-exam",
    name: "User About Exam",
    element: <AboutExam />,
  },
  {
    path: "/user/top-content/:id",
    name: "User About Exam",
    element: <ContentPageDataById />,
  },
  {
    path: "/user/course",
    name: "User Courses",
    element: <Course />,
  },
  {
    path: "/user/study-planner-ai",
    name: "User Study Planner AI",
    element: <StudyPlanner />,
  },
  {
    path: "/user/study-planner-ai/:id",
    name: "User Study Planner AI",
    element: <StudyPlannerPage2 />,
  },
  {
    path: "/user/study-planner-ai/:id/:idName",
    name: "User Study Planner AI",
    element: <StudyPlannerPage3 />,
  },
  {
    path: "/user/study-planner-ai/:id/:idName/:idName2",
    name: "User Study Planner AI",
    element: <StudyPlannerPage4 />,
  },
  {
    path: "/user/study-planner-ai/:id/:idName/:idName2/:idName3",
    name: "User Study Planner AI",
    element: <StudyPlannerPage5 />,
  },
  {
    path: "/user/study-planner-aip/:purchasedId",
    name: "User Study Planner AI",
    element: <StudyPlannerPurchasedPage />,
  },
  {
    path: "/user/study-planner-ai/subject/:id",
    name: "User Study Planner AI",
    element: <StudyPlannerPage6 />,
  },
  {
    path: "/user/study-planner-ai/subject/subSubject/study/:id/:week",
    name: "User Study Planner AI",
    element: <StudyPlannerPage61 />,
  },
  {
    path: "/user/study-planner-ai/subject/:id/:week",
    name: "User Study Planner AI",
    element: <StudyPlannerPage7 />,
  },
  {
    path: "/user/study-planner-ai/subject/:id/:week/:videoId",
    name: "User Study Planner AI",
    element: <StudyPlannerPage8 />,
  },
  {
    path: "/user/study-planner-ai/test-with-video/:id",
    name: "User Study Planner AI",
    element: <StudyPlannerTestWithVideo1 />,
  },
  {
    path: "/user/study-planner-ai/test-with-video/:id/2",
    name: "User Study Planner AI",
    element: <StudyPlannerTestWithVideo2 />,
  },
  {
    path: `/user/course/:CourseType/`,
    name: "Course Type",
    element: <CoursePage2 />,
  },
  {
    path: `/user/course/:CourseType/:course`,
    name: "Course Type",
    element: <Coursepage3 />,
  },
  {
    path: `/user/course/:CourseType/:course/:subject/subSubject`,
    name: "Course Type",
    element: <CoursePage31 />,
  },
  {
    path: `/user/course/:CourseType/:course/:subject`,
    name: "Course Type",
    element: <CoursePage4 />,
  },
  {
    path: `/user/course/:CourseType/:course/:subject/:chapterId/:videoId`,
    name: "Course Type",
    element: <CoursePage5 />,
  },
  {
    path: "/user/notes/:documentLink",
    name: "Course Type",
    element: <CoursePage6 />,
  },
  {
    path: "/user/course-test/:id",
    name: "Course Type",
    element: <CourseTestSeriesPage2 />,
  },
  {
    path: "/user/test-video-solution",
    name: "Test With Video Solution",
    element: <TestWithVideo />,
  },
  {
    path: "/user/test/:id",
    name: "Test With Video Solution",
    element: <TestWithVideoPage2 />,
  },
  {
    path: "/user/testp/:id",
    name: "Test With Video Solution",
    element: <TestWithVideoP />,
  },
  {
    path: "/user/test-instructions/:id/:testId/:testSeriesFileId",
    name: "Test With Video Solution",
    element: <TestWithVideoPage3 />,
  },
  {
    path: "/user/course-instructions/:id/:testId/:testSeriesFileId",
    name: "Test With Video Solution",
    element: <CourseTestSeriesPage3 />,
  },
  {
    path: "/user/test-instructions/:id/:testId/:testSeriesFileId/2",
    name: "Test With Video Solution",
    element: <TestWithVideoPage4 />,
  },
  {
    path: "/user/course-instructions/:id/:testId/:testSeriesFileId/2",
    name: "Test With Video Solution",
    element: <CourseTestSeriesPage4 />,
  },
  {
    path: "/user/test-instructions/:id/:testId/:testSeriesFileId/3",
    name: "Test With Video Solution",
    element: <TestWithVideoPage5 />,
  },
  {
    path: "/user/course-instructions/:id/:testId/:testSeriesFileId/3",
    name: "Test With Video Solution",
    element: <CourseTestSeriesPage5 />,
  },

  {
    path: "/user/test-instructions/:id/:testId/:testSeriesFileId/4",
    name: "Practice Analysis",
    element: <UserTestDiv/>,
  },
  {
    path: "/user/handwritten-notes",
    name: "Handwritten Notes",
    element: <HandWrittenNotes />,
  },
  {
    path: "/user/handwritten-notes/subjects/:id",
    name: "Handwritten Notes",
    element: <HandwrittenNotesP22 />,
  },
  {
    path: "/user/handwritten-notes/subjects/:subjectName/:id",
    name: "Handwritten Notes",
    element: <HandwrittenNotesP23 />,
  },
  {
    path: "/user/handwritten-notes/:id",
    name: "Handwritten Notes",
    element: <HandWrittenNotesPage2 />,
  },
  {
    path: "/user/handwritten-notesn/:id",
    name: "Handwritten Notes",
    element: <PurchasedHandwrittenNotes />,
  },
  {
    path: "/user/pyq-with-videos",
    name: "PYQ with Videos",
    element: <PYQwithVideos />,
  },
  {
    path: `/user/pyq-with-videos/:id`,
    name: "PYQ with Videos",
    element: <PYQwithVideosPage2 />,
  },
  {
    path: `/user/pyq-with-videos/:id/:testId/:testSeriesFileId`,
    name: "PYQ with Videos",
    element: <PYQwithVideosPage3 />,
  },
  {
    path: `/user/pyq-with-videos/:id/:testId/:testSeriesFileId/2`,
    name: "PYQ with Videos",
    element: <PYQwithVideosPage4 />,
  },
  {
    path: `/user/pyq-with-videos/:id/:testId/:testSeriesFileId/3`,
    name: "PYQ with Videos",
    element: <PYQwithVideosPage5 />,
  },
  {
    path: "/user/community",
    name: "Community",
    element: <Community />,
  },
  {
    path: "/user/current-affairs",
    name: "Current Affairs",
    element: <CurrentAffairs />,
  },
  {
    path: "/user/current-affairs/targetCurrentAffairs/:id/:testId",
    name: "Current Affairs",
    element: <TestWithVideoPage3tca />,
  },
  {
    path: "/user/current-affairs/targetCurrentAffairs/:id/:testId/4",
    name: "Current Affairs",
    element: <TestWithVideoPage4tca />,
  },
  {
    path: "/user/current-affairs/targetCurrentAffairs/:id/:testId/5",
    name: "Current Affairs",
    element: <TestWithVideoPage5tca />,
  },
  {
    path: "/user/current-affairs",
    name: "Current Affairs",
    element: <CurrentAffairs />,
  },
  {
    path: "/user/capsule-course",
    name: "Capsule Course Analysis",
    element: <CapsuleCourse />,
  },
  // {
  //   path: "/user/capsule-course/:id",
  //   name: "User Wishlist Bar",
  //   element: <CapsuleCoursePage2/>,
  // },
  {
    path: "/user/capsule-course/:capsuleId",
    name: "User Wishlist Bar",
    element: <CapsuleCoursePage3/>,
  },
  {
    path: "/user/capsule-course/:id/:capsuleId/:subjectId",
    name: "User Wishlist Bar",
    element: <CapsuleCoursePage4/>,
  },
  {
    path: "/user/capsule-course/:id/:capsuleId/:subjectId/:topic",
    name: "User Wishlist Bar",
    element: <CapsuleCoursePage5/>,
  },
  {
    path: "/user/skills",
    name: "Skills",
    element: <Skills />,
  },
  {
    path: "/user/skills/:id",
    name: "Skills",
    element: <SkillsPage2 />,
  },
  {
    path: "/user/skills/:id/:skillId",
    name: "Skills",
    element: <SkillsPage3 />,
  },

  {
    path: "/user/settings",
    name: "Settings",
    element: <Settings />,
  },
  {
    path: "/user/my-sucess-roadmap",
    name: "My Sucess Roadmap",
    element: <MySuccessRoadMap />,
  },
  {
    path: "/user/RazorPayment",
    name: "My Sucess Roadmap",
    element: <RazorpayPayment />,
  },
  {
    path: "/user/cart",
    name: "Checkout",
    element: <UserCartDiv />,
  },
  {
    path: "/user/checkout",
    name: "Checkout",
    element: <CheckoutPage />,
  },
  {
    path: "/user/videos-analysis",
    name: "Videos Analysis",
    element: <VideoAnalysis />,
  },
  {
    path: "/user/practice-analysis",
    name: "Practice Analysis",
    element: <PracticeAnalysis/>,
  },
  {
    path: "/user/tests-analysis",
    name: "Practice Analysis",
    element: <TestAnalysis/>,
  },
  {
    path: "/test/analysis/:testId/:testSeriesFileId",
    name: "Practice Analysis",
    element: <UserTestDiv/>,
  },
  {
    path: "/test-with-video/page7",
    name: "Detail Solution",
    element: <DetailSolutionDiv/>,
  },
  {
    path: "/user/handwritten-notes-by-toppers-analysis",
    name: "Handwritten Notes By Toppers Analysis",
    element: <HandwrittenNotesByTopperAnalysis/>,
  },
  {
    path: "/user/capsule-course-analysis",
    name: "Capsule Course Analysis",
    element: <CapsuleCourseAnalysis/>,
  },
  {
    path: "/user-search",
    name: "User Search Bar",
    element: <UserSearchBarDiv/>,
  },
  {
    path: "/user-wishlist",
    name: "User Wishlist Bar",
    element: <UserWishlistBarDiv/>,
  },
  {
    path: "/user-course-purchased",
    name: "User Wishlist Bar",
    element: <PurchasedCourse/>,
  },
  {
    path: "/user-testSeries-purchased",
    name: "User Wishlist Bar",
    element: <PurchasedTestSeries/>,
  },
  {
    path: "/user-handwritten-notes-purchased",
    name: "User Wishlist Bar",
    element: <PurchasedHandwrittenNotes/>,
  },
  {
    path: "/about-us",
    name: "User Wishlist Bar",
    element: <AboutUs/>,
  },
  {
    path: "/privacy-policy",
    name: "User Wishlist Bar",
    element: <PrivacyAndPolicy/>,
  },
  {
    path: "/terms-and-conditions",
    name: "User Wishlist Bar",
    element: <TermsAndCondition/>,
  },
  {
    path: "/user-guidelines",
    name: "User Wishlist Bar",
    element: <UG/>,
  },
  {
    path: "/refund-policy",
    name: "User Wishlist Bar",
    element: <RP/>,
  },
  {
    path: "/take-down-policy",
    name: "User Wishlist Bar",
    element: <TP/>,
  },
  {
    path: "/grievance-policy",
    name: "User Wishlist Bar",
    element: <GP/>,
  },
  {
    path:"/user/mainTest/:id",
    name: "User Main Test",
    element: <UserMainTest2/>
  },
  {
    path:"/user/mainTest/:id/2",
    name: "User Main Test",
    element: <UserMainTest3/>
  },
  {
    path:"/user/mainTest/:id/3",
    name: "User Main Test",
    element: <UserMainTest4/>
  },
  {
    path:"/user/mainTest/:id/4",
    name: "User Main Test",
    element: <UserMainTest5/>
  },
  {
    path:"/user/studPlannerBanner",
    name: "User Main Test",
    element: <StudyPlannerBanner/>
  },
 
  
  

  {
    path: "*",
    name: "404",
    element: <Home />,
  },
];
