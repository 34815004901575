import { Navigate, useLocation } from "react-router-dom";
import { showNotification } from "../components/common/APIComponents";

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem("authToken");
  const { pathname } = useLocation();

  if (!token && pathname !== "/signup" && pathname !== "/") {
    showNotification({
      type: "error",message: "Please login to access this page",
    });
    return <Navigate to="/signup" replace />;
  }

  return children;
};

export default ProtectedRoute;
