import { useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import {
  BannerComponent,
  UserBannerTopComponent
} from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { useNavigate, useParams } from "react-router-dom";
import "./StudyPlanner.css";
import { Button} from "react-bootstrap";
import { Icon } from "@iconify/react/dist/iconify.js";
import Calendar from "react-calendar";
import { showNotification } from "../../../components/common/APIComponents";
import { getRequest } from "../../../services/apiService";
import { endpoints } from "../../../services/endPoints";
const StudyPlannerPage3 = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));
  const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 7)));
  const { id, idName } = useParams();

  const totalDays = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));

  const handleSubmit = (startDate, endDate) => {
    if (!startDate || !endDate) {
      return showNotification({
        type: "error",
        message: "Both start and end dates are required.",
      });
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    if (end < start) {
      return showNotification({
        type: "error",
        message: "End date cannot be earlier than the start date.",
      });
    }

    localStorage.setItem("StudyPlannerstartDate", startDate);
    localStorage.setItem("StudyPlannerendDate", endDate);
    localStorage.setItem("StudyPlannerTotalDays", totalDays);

    navigate(`/user/study-planner-ai/${id}/${idName}/1`);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [bannerData, setBannerData] = useState(null);
  const getBanner = () => {
    const params = {
      position: "TOP",
      type: "StudyPlanner",
    };
    getRequest({
      endpoint: endpoints.getHomePageBanners,
      setIsLoading,
      params,
    }).then((res) => {
      setBannerData(res?.data);
    });
  };
  useEffect(() => {
    getBanner();
  }, []);
  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar DashboardName={"Dashboard"} link={"/user/dashboard"} />
        </div>
        <div className="user_container_width">
          <div>
            <div>
         {bannerData &&     <BannerComponent
                image={bannerData?.image?.[bannerData?.image?.length - 1] || images.userHandWrittenNotesImage}
                imagecss={"userHandWrittenNotesBannerImage"}
                alt="alttext"
              />}
            </div>
            <div className="userStudyPlannerContainer">
              <div className="userStudyPlannerCardsContainer">
                <p className="userStudyPlanner2CardsHeading">
                  Kindly choose the examination date
                </p>

                <div className="userStudyPlannerPage3Cards">
                  <div className="userStudyPlannerPage3CardsDiv1">
                    <span>
                      <Icon
                        icon="solar:calendar-outline"
                        style={{ color: "#000", width: "20px", height: "20px" }}
                      />
                    </span>
                    <span>
                      {startDate
                        ? startDate.toLocaleDateString()
                        : "Select Date"}{" "}
                      - {endDate ? endDate.toLocaleDateString() : "Select Date"}
                    </span>
                  </div>
                </div>
                <div>
                  <div className="userStudyPlannerPage3CardsDiv21 ">
                    <Calendar
                      className={"CalanderClass"}
                      onChange={(date) => setStartDate(date)}
                      value={startDate}
                      minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                    />
                    <Calendar
                      className={"CalanderClass"}
                      onChange={(date) => setEndDate(date)}
                      value={endDate}
                      minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                    />
                  </div>
                </div>
                <div className="userStudyPlanner3ButtonContainer">
                  <span className="userStudyPlanner3ButtonContainerSpan">
                    Days remaning for exam <span> {totalDays} Days</span>
                  </span>
                  <span>
                    {startDate && endDate && (
                      <Button onClick={() => handleSubmit(startDate, endDate)}>
                        Save & Next
                      </Button>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(StudyPlannerPage3);
