import { useEffect, useState } from "react";
import "./TestWithVideo.css";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { startTest, startTestTWV } from "../../../../../utils/otherApi";
import { getRequest } from "../../../../../services/apiService";
import { endpoints } from "../../../../../services/endPoints";
const TestWithVideoPage3tca = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [instructionFileData, setInstructionFileData] = useState({});
  const { testId, testSeriesFileId } = useParams();
  const [goalId, setGoalId] = useState("");
const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const location=useLocation();
  const {todo}=location.state;
  const getUserData = () => {
    console.log(todo)
    const payloadData={
      testSeriesId:todo?.testSeriesFiles?.[0]?._id,

    };
    payloadData.targetcurrentaffairsId=id;
    startTest({
      payload:payloadData
    })
    setData(todo?.testSeriesFiles?.[0]?.instructionFile);
    // const parmas = {
    //   testId,
    //   testSeriesFileId:id,
    // };
    // startTest({
    //   payload: {
    //     targetcurrentaffairsId: id,
    //     testSeriesFileId:testId,
    //   },
    // });
    // getRequest({
    //   endpoint: endpoints.getTestInstructions,
    //   setIsLoading,
    //   params: parmas,
    // }).then((res) => {
      
    //   setData(res?.data?.instructionFile);
    // });
  };

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <div className="TestWithVideoPage3Container">
      <div className="TestWithVideoPage3">
        <div className="TestWithVideoPage3Content">
          <p>Instructions</p>
          <h5>
            {data?.instructionsPage1 &&
              data?.instructionsPage1?.split("\n").map((item, index) => {
                return <p key={index}>{item}</p>;
              })}
          </h5>
          {/* <ul>
            <li>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus
              eum officia dolores rerum, animi laborum dolorem hic? Magnam, rem
              veritatis cum eligendi, optio quod numquam dolorem eum quae harum
              sapiente.
            </li>
            <li>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus
              eum officia dolores rerum, animi laborum dolorem hic? Magnam, rem
              veritatis cum eligendi, optio quod numquam dolorem eum quae harum
              sapiente.
            </li>
            <li>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus
              eum officia dolores rerum, animi laborum dolorem hic? Magnam, rem
              veritatis cum eligendi, optio quod numquam dolorem eum quae harum
              sapiente.
            </li>
          </ul> */}
          <div className="TestWithVideoPage3Buttons">
            <Button onClick={() => navigate(-1)}>Go Back</Button>
            <Button
              onClick={() =>
                navigate(
                  `/user/current-affairs/targetCurrentAffairs/${id}/${testId}/4`,{
                    state:{todo}
                  }
                )
              }
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestWithVideoPage3tca;
