import React, { useState } from "react";
import HOC from "../../../components/layout/HOC";
import styles from "./CSS/MySuccessRoadMap.module.css";

import images from "../../../utils/images";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate } from "react-router-dom";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

import { UserBannerTopComponent } from "../../../components/common/Banners";
import { Button, Form, OverlayTrigger, Popover } from "react-bootstrap";
import { SwiperComponent2Settings } from "../../../components/common/Cards";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
const CapsuleCourseAnalysis = () => {
  const navigate = useNavigate();
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <div className={styles.MySuccessRoadMapDiv2Popover}>
          <span>Filter</span>
          <span>Overall Progress</span>
          <span>Weekly Progress</span>
        </div>
      </Popover.Body>
    </Popover>
  );

  const comp1 = [
    {
      icon: "bx:calendar",
      text: "Course Completion",
      number: "30",
    },
    
    {
      icon: "mdi:alarm-clock-tick",
      text: "Overall time spent",
      number: "80H 1M",
    },
  ];
  const comp2 = [
    {
      videos: 80,
      practice: 30,
      notes: 20,
      test: 10,
      timeLeft: 10,
      isLocked: false,
      week: 1,
    },
    {
      videos: 80,
      practice: 10,
      notes: 0,
      test: 10,
      timeLeft: 10,
      isLocked: true,
      week: 2,
    },
    {
      videos: 80,
      practice: 30,
      notes: 20,
      test: 10,
      timeLeft: 10,
      isLocked: true,
      week: 3,
    },
  ];
  const chartData = [
    { name: "Correct", value: 40, color: "#66cc66" },
    { name: "Incorrect", value: 40, color: "#ff6600" },
    { name: "Unattempt", value: 20, color: "#cccccc" },
  ];
  const [selectedWeek, setSelectedWeek] = useState(1);
  return (
    <div className="MySuccessRoadMapContainer">
      <div>
        <UserBannerTopComponent
          divCSS="UserMainTopBannerDiv"
          imagecss={"UserMainTopBannerDivImage"}
          image={images.userDashboardTopBanner}
        />
      </div>
      <div className={styles.MySuccessRoadMapDiv1}>
        <span className={styles.MySuccessRoadMapDiv1Span1}>
          My Capsule Course Analysis{" "}
        </span>
       
      </div>
      <div className={styles.MySuccessRoadMapDiv2Main}>
        <div className={styles.MySuccessRoadMapDiv2}>
          {comp1.map((item, index) => {
            return (
              <div className={styles.MySuccessRoadMapDiv2Div1} key={index}>
                <span className={styles.MySuccessRoadMapDiv2Div1Span1}>
                  <Icon
                    icon={item.icon}
                    style={{ width: "25px", height: "25px" }}
                  />
                </span>
                <span className={styles.MySuccessRoadMapDiv2Div1Span4}>
                  <span className={styles.MySuccessRoadMapDiv2Div1Span3}>
                    {item.number}
                  </span>
                  <span className={styles.MySuccessRoadMapDiv2Div1Span2}>
                    {item.text}
                  </span>
                </span>
              </div>
            );
          })}
        </div>
        <div className={styles.MySuccessRoadMapDiv3}>
          <p className={styles.MySuccessRoadMapDiv3P1}>Overall Progress</p>
          <div className={styles.MySuccessRoadMapDiv4Div}>
            <div className={styles.MySuccessRoadMapDiv3Div1}>
              {comp2.map((item, index) => (
                <div
                  key={index}
                  onClick={() => setSelectedWeek(item.week)}
                  className={`${styles.MySuccessRoadMapDiv3Div1} ${
                    selectedWeek === item.week ? styles.Active : ""
                  }`}
                >
                  <span className={styles.MySuccessRoadMapDiv3Div1Span1}>
                    <span> Week {item.week}</span>
                    <span>{item.isLocked && "🔒"}</span>
                  </span>
                </div>
              ))}
            </div>
            <div className={styles.MySuccessRoadMapDiv3Div2}>
              <SwiperComponent2Settings
                data={comp2?.find((item) => item.week === selectedWeek)}
              />
            </div>
          </div>
        </div>
      
        <div style={{marginTop:"2rem"}} className={styles.MySuccessRoadMapDiv6}>
          <div className={styles.MySuccessRoadMapDiv6Div1}>
            <p className={styles.MySuccessRoadMapDiv6Div1P0}>Strong Topics</p>
            <p className={styles.MySuccessRoadMapDiv6Div1P1}>
              <span>
                <Icon
                  className={styles.MySuccessRoadMapDiv6Div1P1Icon}
                  icon="lets-icons:book-check"
                />
              </span>
              <span>Topic 1</span>
            </p>
            <p className={styles.MySuccessRoadMapDiv6Div1P2}>
              <Button>25 Expected Questions</Button>
            </p>
          </div>
          <div className={styles.MySuccessRoadMapDiv6Div1}>
            <p className={styles.MySuccessRoadMapDiv6Div1P0}>Weak Topics</p>
            <p className={styles.MySuccessRoadMapDiv6Div1P1}>
              <span>
                <Icon
                  className={styles.MySuccessRoadMapDiv6Div1P1Icon}
                  icon="lets-icons:book-check"
                />
              </span>
              <span>Topic 1</span>
            </p>
            <p className={styles.MySuccessRoadMapDiv6Div1P22}>
              <Button>25 Expected Questions</Button>
            </p>
          </div>
        </div>
        <div style={{marginTop:"2rem"}} className={styles.MySuccessRoadMapDiv3}>
          <p className={styles.MySuccessRoadMapDiv3P1}>
            Module wise time spent
          </p>
          <div className={styles.MySuccessRoadMapDiv4Div}>
            <div className={styles.MySuccessRoadMapDiv3Div2}>
              <SwiperComponent2Settings
                data={comp2?.find((item) => item.week === selectedWeek)}
              />
            </div>
          </div>
        </div>
        <div className={styles.MySuccessRoadMapDiv67}>
          <div className={styles.MySuccessRoadMapDiv7Div1}>
            <p className={styles.MySuccessRoadMapDiv7Div1P1}>
              <span style={{ color: "#6090F7" }}>80</span>
              <span>Expected Questions Cutoff for Exam</span>
            </p>
            
          </div>
          
        </div>
        
      </div>
    </div>
  );
};

export default HOC(CapsuleCourseAnalysis);
