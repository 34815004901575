import React, { useState } from "react";
import HOC from "../../../components/layout/HOC";
import styles from "./CSS/MySuccessRoadMap.module.css";

import images from "../../../utils/images";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate } from "react-router-dom";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

import { UserBannerTopComponent } from "../../../components/common/Banners";
import { Button, Form, OverlayTrigger, Popover } from "react-bootstrap";
import { SwiperComponent2Settings } from "../../../components/common/Cards";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
const VideoAnalysis = () => {
  const navigate = useNavigate();
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <div className={styles.MySuccessRoadMapDiv2Popover}>
          <span>Filter</span>
          <span>Overall Progress</span>
          <span>Weekly Progress</span>
        </div>
      </Popover.Body>
    </Popover>
  );

  const comp1 = [
    {
      icon: "bx:calendar",
      text: "Videos completed",
      number: "30",
    },
    {
      icon: "mdi:tick-circle-outline",
      text: "Videos to Watch  ",
      number: "40%",
    },
    {
      icon: "mdi:alarm-clock-tick",
      text: "Overall time spent",
      number: "80H 1M",
    },
  ];
  const comp2 = [
    {
      videos: 80,
      practice: 30,
      notes: 20,
      test: 10,
      timeLeft: 10,
      isLocked: false,
      week: 1,
    },
    {
      videos: 80,
      practice: 10,
      notes: 0,
      test: 10,
      timeLeft: 10,
      isLocked: true,
      week: 2,
    },
    {
      videos: 80,
      practice: 30,
      notes: 20,
      test: 10,
      timeLeft: 10,
      isLocked: true,
      week: 3,
    },
  ];
  const chartData = [
    { name: "Correct", value: 40, color: "#66cc66" },
    { name: "Incorrect", value: 40, color: "#ff6600" },
    { name: "Unattempt", value: 20, color: "#cccccc" },
  ];
  const [selectedWeek, setSelectedWeek] = useState(1);
  return (
    <div className="MySuccessRoadMapContainer">
      <div>
        <UserBannerTopComponent
          divCSS="UserMainTopBannerDiv"
          imagecss={"UserMainTopBannerDivImage"}
          image={images.userDashboardTopBanner}
        />
      </div>
      <div className={styles.MySuccessRoadMapDiv1}>
        <span className={styles.MySuccessRoadMapDiv1Span1}>
          Video Analysis{" "}
        </span>
      </div>
      <div className={styles.MySuccessRoadMapDiv2Main}>
        <div className={styles.MySuccessRoadMapDiv5}>
          <p className={styles.MySuccessRoadMapDiv5P1}>Weekly Progress</p>
          <p className={styles.MySuccessRoadMapDiv5P2}>
            <span className={styles.MySuccessRoadMapDiv5P2Span1}>
              <span className={styles.MySuccessRoadMapDiv5P2Span2}>
                <Icon
                  className={styles.MySuccessRoadMapDiv5P2Span2Icon}
                  icon="mdi:tick-circle-outline"
                />
              </span>
              <span>20 Watched Out of 30</span>
            </span>
            <span className={styles.MySuccessRoadMapDiv5P2Span3}>
              {" "}
              <span>
                <CircularProgressbar
                  value={20}
                  text={`${20}%`}
                  styles={buildStyles({
                    pathColor: "#FFC851",
                    trailColor: "#D9D9D9",
                    textColor: "blue",
                  })}
                />
                <span
                  style={{
                    display: "block",
                    marginTop: "10px",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {20}
                </span>
              </span>
            </span>
            <span className={styles.MySuccessRoadMapDiv5P2Span4}>
              <span className={styles.MySuccessRoadMapDiv5P2Span4Span1}>
                <span>
                  <img src={images.userAllDashboardImage} alt="DasImage" />
                </span>
                <span>
                  <Icon
                    className={styles.MySuccessRoadMapDiv5P2Span2Icon2}
                    icon="mdi:tick-circle-outline"
                  />
                </span>
                <span className={styles.MySuccessRoadMapDiv5P2Span4Span2}>
                  Subject
                </span>
                <span className={styles.MySuccessRoadMapDiv5P2Span4Span3}>
                  topic
                </span>
                <span className={styles.MySuccessRoadMapDiv5P2Span4Span4}>
                  Duration
                </span>
              </span>
            </span>
          </p>
        </div>
        <div className={styles.MySuccessRoadMapDiv5}>
          <p className={styles.MySuccessRoadMapDiv5P1}>Overall Progress</p>
          <p className={styles.MySuccessRoadMapDiv5P2}>
            <span className={styles.MySuccessRoadMapDiv5P2Span1}>
              <span className={styles.MySuccessRoadMapDiv5P2Span2}>
                <Icon
                  className={styles.MySuccessRoadMapDiv5P2Span2Icon}
                  icon="mdi:tick-circle-outline"
                />
              </span>
              <span>20 Watched Out of 30</span>
            </span>
            <span className={styles.MySuccessRoadMapDiv5P2Span3}>
              {" "}
              <span>
                <CircularProgressbar
                  value={20}
                  text={`${20}%`}
                  styles={buildStyles({
                    pathColor: "#FFC851",
                    trailColor: "#D9D9D9",
                    textColor: "blue",
                  })}
                />
                <span
                  style={{
                    display: "block",
                    marginTop: "10px",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {20}
                </span>
              </span>
            </span>
            <span className={styles.MySuccessRoadMapDiv5P2Span4}>
              <span className={styles.MySuccessRoadMapDiv5P2Span4Span1}>
                <span>
                  <img src={images.userAllDashboardImage} alt="DasImage" />
                </span>
                <span>
                  <Icon
                    className={styles.MySuccessRoadMapDiv5P2Span2Icon2}
                    icon="mdi:tick-circle-outline"
                  />
                </span>
                <span className={styles.MySuccessRoadMapDiv5P2Span4Span2}>
                  Subject
                </span>
                <span className={styles.MySuccessRoadMapDiv5P2Span4Span3}>
                  topic
                </span>
                <span className={styles.MySuccessRoadMapDiv5P2Span4Span4}>
                  Duration
                </span>
              </span>
            </span>
          </p>
        </div>
        <div className={styles.MySuccessRoadMapDiv6}>
          <div className={styles.MySuccessRoadMapDiv6Div1}>
            <p className={styles.MySuccessRoadMapDiv6Div1P0}>Most Watched</p>
            <p className={styles.MySuccessRoadMapDiv6Div1P1}>
              <span>
                <Icon
                  className={styles.MySuccessRoadMapDiv6Div1P1Icon}
                  icon="lets-icons:book-check"
                />
              </span>
              <span>Topic 1</span>
            </p>
            <p className={styles.MySuccessRoadMapDiv6Div1P2}>
              <Button>25 Expected Questions</Button>
            </p>
          </div>
          <div className={styles.MySuccessRoadMapDiv6Div1}>
            <p className={styles.MySuccessRoadMapDiv6Div1P0}>Weak Topics</p>
            <p className={styles.MySuccessRoadMapDiv6Div1P1}>
              <span>
                <Icon
                  className={styles.MySuccessRoadMapDiv6Div1P1Icon}
                  icon="lets-icons:book-check"
                />
              </span>
              <span>Topic 1</span>
            </p>
            <p className={styles.MySuccessRoadMapDiv6Div1P22}>
              <Button>25 Expected Questions</Button>
            </p>
          </div>
        </div>
        <div className={styles.MySuccessRoadMapDiv67}>
          <div className={styles.MySuccessRoadMapDiv7Div1}>
            <p className={styles.MySuccessRoadMapDiv7Div1P1}>
              <span style={{ color: "#6090F7" }}>80</span>
              <span>Average Completation Rate</span>
            </p>
            <p className={styles.MySuccessRoadMapDiv7Div1P1}>
              <span>80 %</span>
              <span>Average Watch Duration</span>
            </p>

            <p className={styles.MySuccessRoadMapDiv7Div1P1}>
              <span>2</span>
              <span>Overall Drop%</span>
            </p>
          </div>
          <div className={styles.MySuccessRoadMapDiv6Div1}>
            <p className={styles.MySuccessRoadMapDiv6Div1P0}>Dropped Videos</p>
            <p className={styles.MySuccessRoadMapDiv6Div1P1}>
              <span>
                <Icon
                  className={styles.MySuccessRoadMapDiv6Div1P1Icon}
                  icon="lets-icons:book-check"
                />
              </span>
              <span>Topic 1</span>
            </p>
            <p className={styles.MySuccessRoadMapDiv6Div1P22}>
              <Button>25 Expected Questions</Button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(VideoAnalysis);
