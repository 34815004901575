import React from "react";
import images from "../../../utils/images";
import "./UserNavbar.css";
import { useNavigate } from "react-router-dom";
const DetailSolutionNavBar = () => {
  const navigate = useNavigate();
  return (
    <div className="userNavbarContainer">
      <div className="userNavbar">
        <div className="userNavbarLogo">
          <span>
            <img
              onClick={() => navigate("user/home")}
              src={images.userNavbarLogo}
              alt="imageLogo"
            />
          </span>
          <span>Detail Solutions</span>
        </div>
        <div className="userNavbarHome">
          <span
            onClick={() => navigate(-1)}
            style={{ color: "A3A3A3", fontSize: "20px", cursor: "pointer" }}
          >
            Back
          </span>
        </div>
      </div>
    </div>
  );
};

export default DetailSolutionNavBar;
