import { useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import { UserBannerTopComponent } from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { useNavigate, useParams } from "react-router-dom";
import "../AboutExam/AboutExam.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import { getRequest, postRequest } from "../../../services/apiService";
import { endpoints } from "../../../services/endPoints";
import { showNotification } from "../../../components/specific/NotificationItem";
import { Spinner } from "react-bootstrap";
const SkillsPage2 = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const getUserData = () => {
    getRequest({
      endpoint: endpoints.getSkillsById(id),
      setIsLoading,
    }).then((res) => {
      
      setData(res?.data);
    });
  };

  const addToCart = (id) => {
    postRequest({
      endpoint: endpoints.addToCart,
      setIsLoading,
      data: {
        skillId: id,
        quantity: 1,
      },
    }).then((res) => {
      
      navigate("/user/cart");
    });
  };

  const addToWishlist = (id) => {
    postRequest({
      endpoint: endpoints.addToWishlist,
      setIsLoading,
      params: {
        skillId: id,
      },
    }).then((res) => {
      
      showNotification({
        type: "success",
        message: "Added to wishlist successfully",
      });
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar />
        </div>
        {isLoading ? (
           <div style={{ display: "flex", justifyContent: "center",marginTop:"2rem" }} className="loader">
           <Spinner animation="border" />
         </div>
        ) : (
          <div className="user_container_width">
            <div className="user_aboutPage_container1 user_container_width">
              <div>
                <UserBannerTopComponent
                  divCSS="userAboutPageTopBannerDiv"
                  imagecss={"userAboutPageTopBannerDivImage"}
                  image={images.userDashboardSkillsBanner}
                />
              </div>
              <p className="user_aboutPage_heading skillContainer">
                <span>{data?.name || ""}</span>
                <span>
                  {Array.from({ length: Math.floor(5) }, (_, i) => (
                    <Icon key={i} icon="noto:star" />
                  ))}{" "}
                  5 Star
                </span>
              </p>
              <p>
                <p className="user_SkillsPage2_heading">Course Heighlights</p>
                <p className="user_SkillsPage2_text">
                  {data?.courseHighlights &&
                    data?.courseHighlights?.split("\n").map((item, index) => (
                      <span>
                        <span>
                          <Icon
                            className="user_SkillsPage2_icon"
                            icon="icon-park-outline:dot"
                          />
                        </span>
                        <span>{item}</span>
                      </span>
                    ))}

                  {/* <span>
                  <Icon
                    className="user_SkillsPage2_icon"
                    icon="icon-park-outline:dot"
                  />
                  <span>1000+ notes & videos</span>
                </span>
                <span>
                  <span>
                    <Icon
                      className="user_SkillsPage2_icon"
                      icon="icon-park-outline:dot"
                    />
                  </span>
                  <span>Unlimited practice test</span>
                </span>
                <span>
                  <span>
                    <Icon
                      className="user_SkillsPage2_icon"
                      icon="icon-park-outline:dot"
                    />
                  </span>
                  <span>Personalize analysis</span>
                </span> */}
                </p>
                <p className="user_SkillsPage2_line">
                  <hr />
                </p>
                <hr />
                <p className="user_SkillsPage2_price">
                  <p>
                    {data?.isPurchased ? (
                      <span>
                        Purchased on{" "}
                        {data?.purchaseDate
                          ?.slice(0, 10)
                          ?.split("-")
                          .reverse()
                          .join("/") || ""}
                      </span>
                    ) : (
                      <span>${data?.price}</span>
                    )}
                    {data?.isPurchased ? (
                      <span>
                        <span
                          onClick={() => {
                            navigate(`/user/skills/${data._id}/${data?._id}`);
                          }}
                          className="user_SkillsPage2_priceText"
                        >
                          Start Course
                        </span>
                      </span>
                    ) : (
                      <span>
                        <span>
                          <Icon
                            onClick={() => {
                              addToWishlist(data?._id);
                            }}
                            icon={"twemoji:red-heart"}
                            style={{
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                          />
                        </span>
                        <span
                          onClick={() => addToCart(data?._id)}
                          className="user_SkillsPage2_priceText"
                        >
                          Add to Cart
                        </span>
                      </span>
                    )}
                  </p>
                </p>
                <hr />
                <p className="user_SkillsPage2_line">
                  <hr />
                </p>
              </p>
              <p>
                <p className="user_SkillsPage2_heading">Overview</p>
                <p className="user_SkillsPage2_text">
                  {data?.overviewPoints &&
                    data?.overviewPoints?.split("\n").map((item, index) => (
                      <span>
                        <span>
                          <Icon
                            className="user_SkillsPage2_icon"
                            icon="icon-park-outline:dot"
                          />
                        </span>
                        <span>{item}</span>
                      </span>
                    ))}

                  {/* <span>
                  <Icon
                    className="user_SkillsPage2_icon"
                    icon="icon-park-outline:dot"
                  />
                  <span>Duration: Short-term Skill Development course</span>
                </span> */}
                </p>
                <p className="user_SkillsPage2_line">
                  <hr />
                </p>
              </p>
              <p>
                <p className="user_SkillsPage2_heading">What you will learn</p>
                <p className="user_SkillsPage2_text">
                  <span>
                    <span>
                      <Icon
                        className="user_SkillsPage2_icon"
                        icon="icon-park-outline:dot"
                      />
                    </span>
                    <span>
                      Basic Vocabulary: Start building your English vocabulary
                      from the ground up.
                    </span>
                  </span>

                  {data?.overviewPoints &&
                    data?.whatYouWillLearn?.map((item, index) => (
                      <span>
                        <Icon
                          className="user_SkillsPage2_icon"
                          icon="icon-park-outline:dot"
                        />
                        <span>{item} </span>
                      </span>
                    ))}
                  {/* <span>
                  <Icon
                    className="user_SkillsPage2_icon"
                    icon="icon-park-outline:dot"
                  />
                  <span>
                    Model Conversations: Engage in role plays with guided
                    conversations.
                  </span>
                </span>
                <span>
                  <Icon
                    className="user_SkillsPage2_icon"
                    icon="icon-park-outline:dot"
                  />
                  <span>
                    Grammar Drills: Practice basic grammar structures and
                    receive error correction.
                  </span>
                </span> */}
                </p>
                <p className="user_SkillsPage2_line">
                  <hr />
                </p>
              </p>

              <p>
                <p className="user_SkillsPage2_heading">
                  By the end of the course, you will be able to
                </p>
                <p className="user_SkillsPage2_text">
                  {data?.endOfCoursePoints &&
                    data?.endOfCoursePoints?.map((item, index) => (
                      <span>
                        <span>
                          <Icon
                            className="user_SkillsPage2_icon"
                            icon="icon-park-outline:dot"
                          />
                        </span>
                        <span>{item} </span>
                      </span>
                    ))}

                  {/* <span>
                  <Icon
                    className="user_SkillsPage2_icon"
                    icon="icon-park-outline:dot"
                  />
                  <span>
                    Form Simple Statements: Create simple positive and negative
                    statements using present, past, and future tenses.
                  </span>
                </span>
                <span>
                  <Icon
                    className="user_SkillsPage2_icon"
                    icon="icon-park-outline:dot"
                  />
                  <span>
                    Ask Questions: Frame common questions using various tenses.
                  </span>
                </span> */}
                </p>
                <p className="user_SkillsPage2_line">
                  <hr />
                </p>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HOC(SkillsPage2);
