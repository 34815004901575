import { useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import {
  BannerComponent,
  UserBannerTopComponent,
} from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { useNavigate } from "react-router-dom";
import "./Skills.css";
import { SkillsUserCardComponent } from "../../../components/common/Cards";
import { Button, Spinner } from "react-bootstrap";
import { getRequest } from "../../../services/apiService";
import { endpoints } from "../../../services/endPoints";
const Skills = () => {
  const navigate = useNavigate();
  const handWrittenNotesUserCard = [
    {
      name: "Ram Kumar",
      iconImage: images.userHandWrittenNotesImage2,
      title: "GATE SCA Digital Logic ",
      pages: 20,
      subTopics: 14,
      rating: 5,
    },
    {
      name: "Kamlesh Kumar",
      iconImage: images.userHandWrittenNotesImage2,
      title: "GATE SCA Digital Logic ",
      pages: 20,
      subTopics: 14,
      rating: 5,
    },
    {
      name: "Rajesh Kumar",
      iconImage: images.userHandWrittenNotesImage2,
      title: "GATE SCA Digital Logic ",
      pages: 20,
      subTopics: 14,
      rating: 5,
    },
    {
      name: "Kamlesh Kumar",
      iconImage: images.userHandWrittenNotesImage2,
      title: "GATE SCA Digital Logic ",
      pages: 20,
      subTopics: 14,
      rating: 5,
    },
    {
      name: "Rajesh Kumar",
      iconImage: images.userHandWrittenNotesImage2,
      title: "GATE SCA Digital Logic ",
      pages: 20,
      subTopics: 14,
      rating: 5,
    },
    {
      name: "Kamlesh Kumar",
      iconImage: images.userHandWrittenNotesImage2,
      title: "GATE SCA Digital Logic ",
      pages: 20,
      subTopics: 14,
      rating: 5,
    },
  ];
  const [data, setData] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const getUserData = () => {
    getRequest({
      endpoint: endpoints.getSkills,
      setIsLoading,
      params: {
        page: page,
        limit: 12,
      },
    }).then((res) => {
      setData(res?.data);
      setHasNextPage(res?.pagination?.hasNextPage);
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    getBanner();
  }, []);
  const [bannerData, setBannerData] = useState({});

  const getBanner = () => {
    const params = {
      position: "TOP",
      type: "Skills",
    };
    getRequest({
      endpoint: endpoints.getHomePageBanners,
      setIsLoading,
      params,
    }).then((res) => {
      setBannerData(res?.data);
    });
  };
  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar
            DashboardName={"Practice Analysis"}
            link={"/user/practice-analysis"}
          />
        </div>
        <div className="user_container_width">
          <div>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2rem",
                }}
                className="loader"
              >
                <Spinner animation="border" />
              </div>
            ) : (
              <div>
                <div>
                  {bannerData?.image?.length > 0 && (
                    <BannerComponent
                      image={bannerData?.image?.[bannerData?.image?.length - 1]}
                      imagecss={"userHandWrittenNotesBannerImage"}
                      alt="alttext"
                    />
                  )}
                </div>
                <div>
                  <div className="userHandWrittenNotesCards gridLayout4">
                    {data &&
                      data?.map((i, index) => (
                        <SkillsUserCardComponent
                          key={index}
                          name={i.name}
                          image={i.image}
                          title={i.title}
                          pages={i.duration || 0}
                          subtopics={i.subTopics}
                          rating={i.rating || 0}
                          id={i._id}
                        />
                      ))}
                  </div>
                </div>
              </div>
            )}

            {hasNextPage && !isLoading && (
              <div className="d-flex justify-content-center">
                <Button
                  onClick={() => {
                    setPage(page + 1);
                    getUserData();
                  }}
                  variant="outline-primary"
                >
                  Load More
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(Skills);
