import "./App.css";
import AppRoutes from "./routes/AppRoutes";
import { getRequest } from "./services/apiService";
import { endpoints } from "./services/endPoints";
import { useEffect, useState, useMemo } from "react";
import { UserContext } from "./Context/Context";

function App() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { pathname } = window.location;

  const getUserData = async () => {
    const token = localStorage.getItem("authToken");
    if (!token) return;
    setLoading(true);
    setError(null);
    try {
      const res = await getRequest({
        endpoint: endpoints.getUserProfile,
      });
      setData(res?.data || []);
    } catch (err) {
      console.error("Failed to fetch user data:", err);
      setError("Failed to load user data. Please try again.");
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    
    if (pathname !== "/" && pathname !== "/signup") {
      getUserData();
    } else {
      setLoading(false); 
    }
  }, [pathname]);

  const contextValue = useMemo(() => data, [data]);

  return (
    <div className="App">
      <UserContext.Provider value={contextValue|| []}>
        {loading ? (
          <div></div>
        )  : (
          <AppRoutes />
        )}
      </UserContext.Provider>
    </div>
  );
}

export default App;
