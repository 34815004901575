import React, { useState } from "react";
import "./LoginPage.css";
import { useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { login, signUp } from "../../services/auth";
const LoginPage = () => {
  const [isLogin, setIsLogin] = useState(true);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) =>
    signUp({ payload: data, setIsLoading, setOtpSent, reset, setIsLogin });
  const onSubmitLogin = (data) =>
    login({
      payload: data,
      setIsLoading,
      setOtpSent,
      navigate,
    });
  return (
    <div className="login_page__container">
      <div className="login_page__container1 fixMaxWidth">
        <p className="login_page__container1__1">
          <span> Welcome Back! </span>Log In to Continue Learning or Sign Up to{" "}
          <span>Get Started</span>
        </p>
        <p className="login_page__container1__2">
          Access Your Personalized Dashboard, Courses, and More
        </p>
        <div className="login_page__container2">
          {isLogin ? (
            <>
              {" "}
              <div className="login_page__container2__1">
                <span>Create Your Free Account and Start Learning</span>
                <span>
                  <Form
                    onSubmit={handleSubmit(onSubmit)}
                    className="login_page__container2__1__1"
                  >
                    {otpSent ? (
                      <>
                        <div>
                          <Form.Group className="login_page__container2__1__1__1_1">
                            <Form.Control
                              className={`login_page__conta`}
                              type="text"
                              placeholder="Enter OTP"
                              {...register("otp", {
                                required: true,
                                maxLength: 6,
                                minLength: 4,
                              })}
                            />
                          </Form.Group>
                          <p className="login_page__container2__1__1__1_2Resend">
                            Resend
                          </p>
                          <Form.Group className="login_page__container2__1__1__1_1VerifyC">
                            <button className="login_page__container2__1__1__1_2Verify">
                              Verify
                            </button>
                          </Form.Group>
                        </div>
                      </>
                    ) : (
                      <Form.Group className="login_page__container2__1__1__1_2">
                        <Form.Control
                          className={`login_page__container2__1__1__1 ${
                            errors.email ? "errorfullName" : ""
                          }`}
                          type="text"
                          placeholder="Name"
                          {...register("fullName", { required: true })}
                        />
                        <Form.Control
                          className={`login_page__container2__1__1__1 ${
                            errors.email ? "errormobileNumber" : ""
                          }`}
                          type="number"
                          placeholder="Phone Number"
                          {...register("mobileNumber", {
                            required: true,
                            maxLength: 10,
                            minLength: 10,
                          })}
                        />
                        <Form.Control
                          className={`login_page__container2__1__1__1 ${
                            errors.email ? "error" : ""
                          }`}
                          type="email"
                          placeholder="Email"
                          {...register("email", { required: true })}
                        />
                        <Form.Select
                          className={`login_page__container2__1__1__1 ${
                            errors.email ? "errorhearAboutUs" : ""
                          }`}
                          aria-label="Default select example"
                          {...register("hearAboutUs", { required: true })}
                        >
                          <option value="">Where Did You Here About Us</option>
                          <option value="FaceBook">FaceBook</option>
                          <option value="LinkedIn">LinkedIn</option>
                          <option value="YouTube">YouTube</option>
                          <option value="Websites">Websites</option>
                        </Form.Select>
                        <Form.Group className="login_page__container2__1__1__2r">
                          <Button disabled={isLoading} variant="primary" type="submit">
                           {isLoading?"Loading...":  "Create Account"}
                          </Button>
                        </Form.Group>
                      </Form.Group>
                    )}
                  </Form>
                </span>
              </div>
              <div className="login_page__container2__2">
                <div className="login_page__container2__2__1">
                  <p>Already have an account?</p>
                  <Button
                    onClick={() => {
                      reset();
                      setIsLogin(!isLogin);
                      setOtpSent(false);
                      setIsLoading(false);
                    }}
                    className="login_page__container2__2__1__1"
                    type="submit"
                  >
                    Log In
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <>
              <>
                {" "}
                <div className="login_page__container2__2">
                  <div className="login_page__container2__2__1">
                    <p> Don't have an account?</p>
                    <Button
                      onClick={() => {
                        reset();
                        setOtpSent(false);
                        setIsLogin(!isLogin);
                      }}
                      className="login_page__container2__2__1__1"
                      type="submit"
                    >
                      Create Account
                    </Button>
                  </div>
                </div>
                <div className="login_page__container2__1">
                  <span>Login To Your Account</span>
                  <span>
                    <Form
                      onSubmit={handleSubmit(onSubmitLogin)}
                      className="login_page__container2__1__1"
                    >
                      {otpSent ? (
                        <>
                          <div>
                            <Form.Group className="login_page__container2__1__1__1_1">
                              <Form.Control
                                className={`login_page__container2__1__1__1 ${
                                  errors.password ? "error" : ""
                                }`}
                                type="number"
                                placeholder="Otp"
                                {...register("otp", {
                                  required: true,
                                })}
                              />
                            </Form.Group>
                            {/* <p
                              className="login_page__container2__1__1__1_2Resend"
                              onClick={() => {
                                
                                login({
                                  payload: {
                                    mobileNumber: data.mobileNumber,
                                  },
                                  setIsLoading,
                                });
                              }}
                            >
                              Resend
                            </p> */}
                            <Form.Group className="login_page__container2__1__1__1_1VerifyC">
                              <button
                                disabled={isLoading}
                                type="submit"
                                className="login_page__container2__1__1__1_2Verify"
                              >
                                { isLoading ? "Loading..." : "Verify OTP"}
                              </button>
                            </Form.Group>
                          </div>
                        </>
                      ) : (
                        <>
                          <Form.Group className="login_page__container2__1__1__1_2r">
                            <Form.Control
                              className={`login_page__container2__1__1__1 ${
                                errors.phone ? "error" : ""
                              }`}
                              type="number"
                              minLength={10}
                              maxLength={10}
                              placeholder="Phone Number"
                              {...register("mobileNumber", {
                                required: true,
                                maxLength: 10,
                                minLength: 10,
                              })}
                            />
                          </Form.Group>
                          <Form.Group className="login_page__container2__1__1__2r">
                            <Button disabled={isLoading} variant="primary" type="submit">
                              {isLoading ? "Loading..." : "Login"}
                            </Button>
                          </Form.Group>
                        </>
                      )}
                    </Form>
                  </span>
                </div>
              </>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
