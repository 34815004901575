import React, { useState } from "react";
import HOC from "../../../components/layout/HOC";
import styles from "./CSS/MySuccessRoadMap.module.css";

import images from "../../../utils/images";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate } from "react-router-dom";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

import { UserBannerTopComponent } from "../../../components/common/Banners";
import { Button, Form, OverlayTrigger, Popover } from "react-bootstrap";
import {
  SwiperComponent2Settings,
  SwiperComponent2Settingsrk,
} from "../../../components/common/Cards";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
const MySuccessRoadMap = () => {
  const navigate = useNavigate();
  const [filterStatus, setFilterStatus] = useState("All");
  const popover = (
      <Popover id="popover-basic">
        <Popover.Body className={styles.popoverBodyTitle}>
          <div  className={styles.MySuccessRoadMapDiv2Popover}>
            <span onClick={() => setFilterStatus("All")}>Filter</span>
            <span onClick={() => setFilterStatus("Overall")}>
              Overall Progress
            </span>
            <span onClick={() => setFilterStatus("Weekly")}>Weekly Progress</span>
          </div>
        </Popover.Body>
      </Popover>
  );

  const comp1 = [
    {
      icon: "bx:calendar",
      text: "Days Remains to Prepare",
      number: "30",
    },
    {
      icon: "mdi:tick-circle-outline",
      text: "Course Completation",
      number: "40%",
    },
    {
      icon: "mdi:alarm-clock-tick",
      text: "Overall time spent",
      number: "80H 1M",
    },
  ];
  const comp2 = [
    {
      videos: 80,
      practice: 30,
      notes: 20,
      test: 10,
      timeLeft: 10,
      isLocked: false,
      week: 1,
    },
    {
      videos: 80,
      practice: 10,
      notes: 0,
      test: 10,
      timeLeft: 10,
      isLocked: true,
      week: 2,
    },
    {
      videos: 80,
      practice: 30,
      notes: 20,
      test: 10,
      timeLeft: 10,
      isLocked: true,
      week: 3,
    },
  ];
  const chartData = [
    { name: "Correct", value: 40, color: "#66cc66" },
    { name: "Incorrect", value: 40, color: "#ff6600" },
    { name: "Unattempt", value: 20, color: "#cccccc" },
  ];
  const [selectedWeek, setSelectedWeek] = useState(1);
  return (
    <div className="MySuccessRoadMapContainer">
      <div>
        <UserBannerTopComponent
          divCSS="UserMainTopBannerDiv"
          imagecss={"UserMainTopBannerDivImage"}
          image={images.userDashboardTopBanner}
        />
      </div>
      <div className={styles.MySuccessRoadMapDiv1}>
        <span className={styles.MySuccessRoadMapDiv1Span1}>
          {filterStatus === "All"
            ? "My Success Roadmap"
            : filterStatus === "Overall"
            ? "My Success Roadmap"
            : "Week 1 Progress"}{" "}
        </span>
        <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
          <span className={styles.MySuccessRoadMapDiv1Span2}>
            <span>
              <Icon icon="mage:filter" />
            </span>
            <span>Filter</span>
          </span>
        </OverlayTrigger>
      </div>
      <div className={styles.MySuccessRoadMapDiv2Main}>
        <div className={styles.MySuccessRoadMapDiv2}>
          {comp1.map((item, index) => {
            return (
              <div className={styles.MySuccessRoadMapDiv2Div1} key={index}>
                <span className={styles.MySuccessRoadMapDiv2Div1Span1}>
                  <Icon
                    icon={item.icon}
                    style={{ width: "25px", height: "25px" }}
                  />
                </span>
                <span className={styles.MySuccessRoadMapDiv2Div1Span4}>
                  <span className={styles.MySuccessRoadMapDiv2Div1Span3}>
                    {item.number}
                  </span>
                  <span className={styles.MySuccessRoadMapDiv2Div1Span2}>
                    {item.text}
                  </span>
                </span>
              </div>
            );
          })}
        </div>
        {filterStatus === "All" && (
          <div className={styles.MySuccessRoadMapDiv3}>
            <p className={styles.MySuccessRoadMapDiv3P1}>Weekly Progress</p>
            <div className={styles.MySuccessRoadMapDiv4Div}>
              <div className={styles.MySuccessRoadMapDiv3Div1}>
                {comp2.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => setSelectedWeek(item.week)}
                    className={`${styles.MySuccessRoadMapDiv3Div1} ${
                      selectedWeek === item.week ? styles.Active : ""
                    }`}
                  >
                    <span className={styles.MySuccessRoadMapDiv3Div1Span1}>
                      <span> Week {item.week}</span>
                      <span>{item.isLocked && "🔒"}</span>
                    </span>
                  </div>
                ))}
              </div>
              <div className={styles.MySuccessRoadMapDiv3Div2}>
                <SwiperComponent2Settings
                  data={comp2?.find((item) => item.week === selectedWeek)}
                />
              </div>
            </div>
          </div>
        )}
        {(filterStatus === "Weekly" || filterStatus === "Overall") && (
          <div className={styles.MySuccessRoadMapDiv3}>
            <p className={styles.MySuccessRoadMapDiv3P1}>
              Overall Progress towarards your goal
            </p>
            <div className={styles.MySuccessRoadMapDiv4Div}>
              <div className={styles.MySuccessRoadMapDiv3Div2}>
                <SwiperComponent2Settingsrk
                  data={comp2?.find((item) => item.week === selectedWeek)}
                />
              </div>
            </div>
          </div>
        )}
        <div className={styles.MySuccessRoadMapDiv5}>
          <p className={styles.MySuccessRoadMapDiv5P1}>Subject wise Progress</p>
          <p className={styles.MySuccessRoadMapDiv5P2}>
            <span className={styles.MySuccessRoadMapDiv5P2Span1}>
              <span className={styles.MySuccessRoadMapDiv5P2Span2}>
                <Icon
                  className={styles.MySuccessRoadMapDiv5P2Span2Icon}
                  icon="mdi:tick-circle-outline"
                />
              </span>
              <span>20 Watched Out of 30</span>
            </span>
            <span className={styles.MySuccessRoadMapDiv5P2Span3}>
              {" "}
              <span>
                <CircularProgressbar
                  value={20}
                  text={`${20}%`}
                  styles={buildStyles({
                    pathColor: "#FFC851",
                    trailColor: "#D9D9D9",
                    textColor: "blue",
                  })}
                />
                <span
                  style={{
                    display: "block",
                    marginTop: "10px",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {20}
                </span>
              </span>
            </span>
            <span className={styles.MySuccessRoadMapDiv5P2Span4}>
              <span className={styles.MySuccessRoadMapDiv5P2Span4Span1}>
                <span>
                  <img src={images.userAllDashboardImage} alt="DasImage" />
                </span>
                <span>
                  <Icon
                    className={styles.MySuccessRoadMapDiv5P2Span2Icon2}
                    icon="mdi:tick-circle-outline"
                  />
                </span>
                <span className={styles.MySuccessRoadMapDiv5P2Span4Span2}>
                  Subject
                </span>
                <span className={styles.MySuccessRoadMapDiv5P2Span4Span3}>
                  topic
                </span>
                <span className={styles.MySuccessRoadMapDiv5P2Span4Span4}>
                  Duration
                </span>
              </span>
            </span>
          </p>
        </div>
        <div className={styles.MySuccessRoadMapDiv6}>
          <div className={styles.MySuccessRoadMapDiv6Div1}>
            <p className={styles.MySuccessRoadMapDiv6Div1P0}>Strong Topics</p>
            <p className={styles.MySuccessRoadMapDiv6Div1P1}>
              <span>
                <Icon
                  className={styles.MySuccessRoadMapDiv6Div1P1Icon}
                  icon="lets-icons:book-check"
                />
              </span>
              <span>Topic 1</span>
            </p>
            <p className={styles.MySuccessRoadMapDiv6Div1P2}>
              <Button>25 Expected Questions</Button>
            </p>
          </div>
          <div className={styles.MySuccessRoadMapDiv6Div1}>
            <p className={styles.MySuccessRoadMapDiv6Div1P0}>Weak Topics</p>
            <p className={styles.MySuccessRoadMapDiv6Div1P1}>
              <span>
                <Icon
                  className={styles.MySuccessRoadMapDiv6Div1P1Icon}
                  icon="lets-icons:book-check"
                />
              </span>
              <span>Topic 1</span>
            </p>
            <p className={styles.MySuccessRoadMapDiv6Div1P22}>
              <Button>25 Expected Questions</Button>
            </p>
          </div>
        </div>
        <div className={styles.MySuccessRoadMapDiv67}>
          <div className={styles.MySuccessRoadMapDiv7Div1}>
            <p className={styles.MySuccessRoadMapDiv7Div1P1}>
              <span style={{ color: "#6090F7" }}>80</span>
              <span>Expected Questions Cutoff for Exam</span>
            </p>
            <p className={styles.MySuccessRoadMapDiv7Div1P1}>
              <span>80 %</span>
              <span>Average Completion Rate</span>
            </p>
            <p className={styles.MySuccessRoadMapDiv7Div1P1}>
              <span>80</span>
              <span>Average Watch Duration</span>
            </p>
            <p className={styles.MySuccessRoadMapDiv7Div1P1}>
              <span>2</span>
              <span>Overall Drop%</span>
            </p>
          </div>
          <div className={styles.MySuccessRoadMapDiv6Div1}>
            <p className={styles.MySuccessRoadMapDiv6Div1P0}>Dropped Videos</p>
            <p className={styles.MySuccessRoadMapDiv6Div1P1}>
              <span>
                <Icon
                  className={styles.MySuccessRoadMapDiv6Div1P1Icon}
                  icon="lets-icons:book-check"
                />
              </span>
              <span>Topic 1</span>
            </p>
            <p className={styles.MySuccessRoadMapDiv6Div1P22}>
              <Button>25 Expected Questions</Button>
            </p>
          </div>
        </div>
        <div className={styles.MySuccessRoadMapDiv3}>
          <p className={styles.MySuccessRoadMapDiv3P1}>
            Module wise time spent
          </p>
          <div className={styles.MySuccessRoadMapDiv4Div}>
            <div className={styles.MySuccessRoadMapDiv3Div2}>
              <SwiperComponent2Settings
                data={comp2?.find((item) => item.week === selectedWeek)}
              />
            </div>
          </div>
        </div>
        <div className={styles.MySuccessRoadMapDiv8}>
          <p className={styles.MySuccessRoadMapDiv8P1}>
            Topic-wise Learning Outcomes
          </p>

          <div className="p-1 gridLayout4">
            <PieChart width={200} height={200} style={{ padding: "10px" }}>
              <Pie
                data={chartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={80}
                label
                labelPosition="inside"
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
          <div className={styles.MySuccessRoadMapDiv9Div}>
            <Form.Group className="mb-3">
              <Form.Check
                label="Sort Maximum Correct Topics First"
                className="mb-2" // Add margin for spacing
              />
              <Form.Check
                label="Sort Maximum Incorrect Topics First"
                className="mb-2" // Add margin for spacing
              />
              <Form.Check
                label="Sort Maximum Unattempted Topics First"
                className="mb-3" // Add margin for spacing
              />
            </Form.Group>
            <Button
              className={styles.MySuccessRoadMapDiv9DivButton}
              variant="primary"
              size="sm"
            >
              Apply Filter
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(MySuccessRoadMap);
