import { useEffect, useState } from "react";
import "./TestWithVideo.css";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { startTest, startTestTWV } from "../../../../../utils/otherApi";
import { getRequest } from "../../../../../services/apiService";
import { endpoints } from "../../../../../services/endPoints";
const CourseTestSeriesPage3 = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [instructionFileData, setInstructionFileData] = useState({});
  const { testId, testSeriesFileId } = useParams();
  const [goalId, setGoalId] = useState("");
const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const location=useLocation();  
  const {courseId1,isMainTest,CourseType}=location.state;
  const getUserData = () => {
    const parmas = {
    };
    if(!isMainTest) parmas.testSeriesId=id
    if(isMainTest) parmas.mainTestSeriesId=id
    if(isMainTest) parmas.courseId=courseId1
   if(isMainTest){
     startTest({
       payload:parmas,
      });

   }else{
   

    //  startTestTWV({
    //    payload:parmas,
    //   });
    }
    const parmas2 = {
      testId,
      testSeriesFileId,
    };
    getRequest({
      endpoint: endpoints.getTestInstructions,
      setIsLoading,
      params: parmas2,
    }).then((res) => {
      
      setData(res?.data?.instructionFile);
    });
  };

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <div className="TestWithVideoPage3Container">
      <div className="TestWithVideoPage3">
        <div className="TestWithVideoPage3Content">
          <p>Instructions</p>
          <h5>
            {data?.instructionsPage1 &&
              data?.instructionsPage1?.split("\n").map((item, index) => {
                return <p key={index}>{item}</p>;
              })}
          </h5>
          {/* <ul>
            <li>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus
              eum officia dolores rerum, animi laborum dolorem hic? Magnam, rem
              veritatis cum eligendi, optio quod numquam dolorem eum quae harum
              sapiente.
            </li>
            <li>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus
              eum officia dolores rerum, animi laborum dolorem hic? Magnam, rem
              veritatis cum eligendi, optio quod numquam dolorem eum quae harum
              sapiente.
            </li>
            <li>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus
              eum officia dolores rerum, animi laborum dolorem hic? Magnam, rem
              veritatis cum eligendi, optio quod numquam dolorem eum quae harum
              sapiente.
            </li>
          </ul> */}
          <div className="TestWithVideoPage3Buttons">
            <Button>Go Back</Button>
            <Button
              onClick={() =>
                navigate(
                  `/user/course-instructions/${id}/${testId}/${testSeriesFileId}/2`,{state:{courseId1:courseId1,mainTestSeriesId:id,isMainTest:isMainTest,CourseType}}
                )
              }
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseTestSeriesPage3;
