import { useState, useContext } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Offcanvas } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import "./css/HOC.css";
import { UserContext } from "../../Context/Context";

const HOC = (WrappedComponent) => {
  const WrappedWithHOC = () => {
    const [show, setShow] = useState(true);
    const [modalShowCanvas, setModalShowCanvas] = useState(false);
    const { user } = useContext(UserContext); 
    const navigate = useNavigate();
    const { pathname } = useLocation(); 

    
    const sideBarItems = [
      { name: "Home", path: "/user/home", iconName: "ic:baseline-home" },
      {
        name: "Study Planner AI ✨",
        path: "/user/study-planner-ai",
        iconName: "mynaui:book-check",
      },
      {
        name: "About Exam",
        path: "/user/about-exam",
        iconName: "ic:outline-event-note",
      },
      { name: "Course", path: "/user/course", iconName: "mdi:school-outline" },
      {
        name: "Tests with Video Solution",
        path: "/user/test-video-solution",
        iconName: "ic:baseline-ondemand-video",
      },
      {
        name: "Handwritten Notes",
        path: "/user/handwritten-notes",
        iconName: "ic:outline-event-note",
      },
      {
        name: "PYQs with Videos",
        path: "/user/pyq-with-videos",
        iconName: "mynaui:book-check",
      },
      {
        name: "Community",
        path: "/user/community",
        iconName: "ic:baseline-group",
      },
      {
        name: "Capsule Course",
        path: "/user/capsule-course",
        iconName: "mdi:format-list-numbered",
      },
      {
        name: "Skills",
        path: "/user/skills",
        iconName: "mdi:lightning-bolt-outline",
      },
      {
        name: "Current Affairs",
        path: "/user/current-affairs",
        iconName: "mdi:newspaper-variant-outline",
        isShow: user?.goalCategory?.isGovernmentExam ?? false, 
      },
      {
        name: "Setting",
        path: "/user/settings",
        iconName: "ic:outline-settings",
      },
    ];

    
    const toggleSidebar = () => setShow(!show);
    const handleCloseCanvas = () => setModalShowCanvas(false);
    const handleShowCanvas = () => setModalShowCanvas(true);

    
    const renderSidebarItems = () =>
      sideBarItems
        .filter((item) => item.isShow === undefined || item.isShow) 
        .map((item, index) => (
          <div
            key={index}
            onClick={() => {
              navigate(item.path);
              handleCloseCanvas(); 
            }}
            className={`sidebar__container1__2__1 ${
              item.path === pathname ? "active" : ""
            }`}
          >
            <span className="sidebar__container1__2__1__1">
              <Icon icon={item.iconName} className="sidebar__icon" />
            </span>
            <span className="sidebar__container1__2__1__2">{item.name}</span>
          </div>
        ));

    return (
      <>
        {/* Mobile Sidebar (Offcanvas) */}
        <Offcanvas
          className="offCanvasMobile"
          show={modalShowCanvas}
          onHide={handleCloseCanvas}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Digital Benchers</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>{renderSidebarItems()}</Offcanvas.Body>
        </Offcanvas>

        {/* Desktop Layout */}
        <div className="FullWidthHOC">
          <div className="HOC">
            <div className={`${show ? "HOC_Admin_Panel" : "toggle_hoc"}`}>
              {/* Sidebar */}
              {show && (
                <div className={`${show ? "admin_sidebar" : "toggle_sidebar"}`}>
                  <Sidebar toggleSidebar={toggleSidebar} show={show} />
                </div>
              )}

              {/* Main Content */}
              <div className="content">
                <div className="child-component">
                  {/* Mobile Menu Icon */}
                  {!modalShowCanvas && (
                    <Icon
                      onClick={handleShowCanvas}
                      icon="ic:baseline-menu"
                      className="navbar_logo_container_1_1"
                    />
                  )}
                  <WrappedComponent />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return WrappedWithHOC;
};

export default HOC;
