import { useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import { UserBannerTopComponent } from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { useNavigate, useParams } from "react-router-dom";
import "./HandWrittenNotes.css";
import { Button } from "react-bootstrap";
import { Icon } from "@iconify/react/dist/iconify.js";
import { endpoints } from "../../../services/endPoints";
import { getRequest, postRequest } from "../../../services/apiService";
import { showNotification } from "../../../components/common/APIComponents";
const HandWrittenNotesPage2 = () => {
  const navigate = useNavigate();
  const handWrittenNotesUserCard = [
    {
      name: "Toppers Name",
      iconImage: images.userHandWrittenNotesImage2,
      title: "GATE SCA Digital Logic ",
      pages: 20,
      subTopics: 14,
      rating: 5,
    },
  ];

  const listItems = [
    {
      index: 1,
      title: "Evolution",
      link: "/user/evolution",
      isLocked: false,
    },
    {
      index: 2,
      title: "Genetic",
      link: "/user/genetic",
      isLocked: true,
    },
    {
      index: 3,
      title: "Evolution",
      link: "/user/evolution",
      isLocked: false,
    },
    {
      index: 4,
      title: "Genetic",
      link: "/user/genetic",
      isLocked: true,
    },
  ];
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [handWrittenNotesData, setHandWrittenNotesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getUserData = () => {
    getRequest({
      endpoint: endpoints.getHandWrittenNotesById(id),
      setIsLoading,
    }).then((res) => {
      setData(res?.data);
      const handWrittenNotesDataInput =
        res?.data?.subjects[0]?.subSubjects[0]?.chapters?.[0]?.topics;
      setHandWrittenNotesData(handWrittenNotesDataInput);
      // setHasNextPage(res?.pagination?.hasNextPage);
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  const addToCart = (id) => {
    postRequest({
      endpoint: endpoints.addToCart,
      setIsLoading,
      data: {
        handwrittenNotesId: id,
        quantity: 1,
      },
    }).then((res) => {
      navigate("/user/cart");
    });
  };

  const addToWishlist = (id) => {
    postRequest({
      endpoint: endpoints.addToWishlist,
      setIsLoading,
      params: {
        handwrittenNotesId: id,
      },
    }).then((res) => {
      showNotification({
        type: "success",
        message: "Added to wishlist successfully",
      });
    });
  };
  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar DashboardName={"Dashboard"} link={"/user/dashboard"} />
        </div>
        <div className="user_container_width">
          <div className="HandWrittenNotesPage2_container">
            <div className="HandWrittenNotesPage2_div1">
              <p className="HandWrittenNotesPage2_div1_p1">
                <img
                  style={{ minWidth: "200px", minHeight: "200px" }}
                  src={data?.image || images.userHandWrittenNotesImage}
                  alt="image"
                />
              </p>
              <p className="HandWrittenNotesPage2_div1_p2">
                <span className="HandWrittenNotesPage2_div1_p2_span1">
                  {} {data?.bundleName || ""}
                </span>
                <span className="HandWrittenNotesPage2_div1_p2_span2">
                  {data?.topperName || ""}
                </span>
                <span className="HandWrittenNotesPage2_div1_p2_span3">
                  {" "}
                  <Icon
                    icon="gravity-ui:book-open"
                    style={{ color: "#A3A3A3", width: "20px", height: "20px" }}
                  />{" "}
                  {data?.pagesCount || ""} Pages
                </span>
                <span className="HandWrittenNotesPage2_div1_p2_span4">
                  {" "}
                  <Icon
                    icon="octicon:stack-24"
                    style={{ color: "#A3A3A3", width: "20px", height: "20px" }}
                  />{" "}
                  16 Sub Topics
                </span>
                <span className="HandWrittenNotesPage2_div1_p2_span5">
                  {Array.from({ length: Math.floor(5) }, (_, i) => (
                    <Icon key={i} icon="noto:star" />
                  ))}{" "}
                  5 Star
                </span>
                <span className="HandWrittenNotesPage2_div1_p2_span6">
                  {" "}
                  ₹ {data?.price || 0}{" "}
                </span>
              </p>
              <p className="HandWrittenNotesPage2_div1_p3">
                <span></span>
                <span className="HandWrittenNotesPage2_div1_p3_span1"></span>
              </p>
            </div>
            <div className="HandWrittenNotesPage2_div2">
              {handWrittenNotesData?.map((item, index) => {
                return (
                  <div key={index} className="HandWrittenNotesPage2_div2_div1">
                    <p className="HandWrittenNotesPage2_div2_div1_p1">
                      <span>{index + 1}</span>
                    </p>
                    <p className="HandWrittenNotesPage2_div2_div1_p2">
                      <span>{item?.name}</span>
                      <span
                        onClick={() =>
                          // console.log(item?.handwrittenNotes?.[0])
                          navigate(`/user/notes/${index}`, {
                            state: {
                              noteData: item?.handwrittenNotes?.[0],
                              title: "Notes",
                            },
                          })
                        }
                        className={
                          item.isLocked
                            ? "HandWrittenNotesPage2_div2_div1_p2_span1"
                            : "HandWrittenNotesPage2_div2_div1_p2_span2"
                        }
                      >
                        {item.isLocked ? "Unlock" : "View Free"}
                      </span>
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(HandWrittenNotesPage2);
