import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./Course.css";
import HOC from "../../../../../components/layout/HOC";
import { UserMenuBar } from "../../../../../components/common/MenuBar";
import images from "../../../../../utils/images";
import {
  BannerComponent,
  UserBannerTopComponent,
} from "../../../../../components/common/Banners";
import { getRequest } from "../../../../../services/apiService";
import { endpoints } from "../../../../../services/endPoints";
import { Icon } from "@iconify/react/dist/iconify.js";
import {
  CapsuleUserCardComponentCourseP4Study,
  CapsuleUserCardComponentCourseP4Studyb,
  CapsuleUserCardComponentCourseP4Studyb61,
} from "../../../../../components/common/Cards";
const StudyPlannerPage7 = () => {
  const navigate = useNavigate();

  const [subjectTitle, setSubjectTitle] = useState("");

  const [data, setData] = useState([]);
  const params = useParams();
  const location = useLocation();
  const { todo, week, itemName, itemId, isPurchased, isSubSubject } =
    location.state;
  const [isLoading, setIsLoading] = useState(false);
  const getUserData = () => {
    getRequest({
      endpoint: endpoints.getCourseByIdFilter,
      setIsLoading,
      params: {
        courseId: params.course,
        courseCategoryId: params.CourseType,
        subjectId: params.subject,
      },
    }).then((res) => {
      const getTitle = todo?.subject?.name;
      setSubjectTitle(getTitle);

      const allSubjectChapters = todo?.subSubjects;
      const allChapters = allSubjectChapters?.map((chapter) => {
        return chapter;
      });
      setData(allChapters);
    });
  };

  useEffect(() => {
    getUserData();
  }, [params.course]);
  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar link={"/user/dashboard"} />
        </div>
        <div className="user_container_width">
          <div>
            <div>
              <BannerComponent
                image={images.userHandWrittenNotesImage}
                imagecss={"userHandWrittenNotesBannerImage"}
                alt="alttext"
              />
            </div>
            <div>
              <div className="user_containerCoursePage4">
                <span className="user_containerCoursePage4Span">
                  <span>{subjectTitle}</span>
                  <span>42,826 students learning this week</span>
                </span>
                <span>
                  {Array.from({ length: Math.floor(5) }, (_, i) => (
                    <Icon
                      icon="emojione:star"
                      className="testWithVideoCardIcon"
                    />
                  ))}
                </span>
              </div>
              <div>
                <h3>Chapters</h3>
                <div className="user_containerCoursePage4Div212">
                  {console.log(todo)}{" "}
                  {isSubSubject
                    ? todo?.chapters?.map((item, index) => {
                        return (
                          <div
                            className="user_containerCoursePage4Div2Card"
                            key={index}
                          >
                           
                            <CapsuleUserCardComponentCourseP4Studyb61
                              index={index}
                              item={item}
                              todo={todo}
                              week={week}
                              itemName={item?.chapter?.name || ""}
                              itemId={itemId}
                              name={
                                item?.subSubject?.name || subjectTitle || ""
                              }
                              chapterId={item?._id}
                              Videos={item?.topics?.flatMap(
                                (chapter) => chapter?.courseVideos || []
                              )}
                              Topics={item?.topics?.flatMap(
                                (chapter) => chapter?.topics || []
                              )}
                              params={params}
                            />
                          </div>
                        );
                      })
                    : todo?.subSubjects?.[0]?.chapters?.map((item, index) => {
                        return (
                          <div
                            className="user_containerCoursePage4Div2Card"
                            key={index}
                          >{console.log(item,"abc")}
                            <CapsuleUserCardComponentCourseP4Study
                              index={index}
                              item={item?.subSubjects?.[0]}
                              todo={todo}
                              week={week}
                              itemName={itemName}
                              itemId={itemId}
                              name={item?.chapter?.name || ""}
                              chapterId={item?._id}
                              Videos={item?.topics?.flatMap(
                                (chapter) => chapter?.courseVideos || []
                              )}  
                              Topics={item?.topics||[]}
                              params={params}
                            />
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(StudyPlannerPage7);
