import { useEffect, useState } from "react";
import "./TestWithVideo.css";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getRequest } from "../../../../../services/apiService";
import { endpoints } from "../../../../../services/endPoints";
const TestWithVideoPage4tca = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [instructionFileData, setInstructionFileData] = useState({});
  const { testId, testSeriesFileId } = useParams();
  const [goalId, setGoalId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const location=useLocation();
  const {todo}=location.state;
  const getUserData = () => {
    const parmas = {
      testId,
      testSeriesFileId,
    };
    setData(todo?.testSeriesFiles?.[0]?.instructionFile);
    // getRequest({
    //   endpoint: endpoints.getTestInstructions,
    //   setIsLoading,
    //   params: parmas,
    // }).then((res) => {
    //   setData(res?.data?.instructionFile);
    // });
  };

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <div className="TestWithVideoPage3Container">
      <div className="TestWithVideoPage3">
        <div className="TestWithVideoPage3Content">
          <p>General Instructions</p>
          <h5>
            {data?.instructionsPage2 &&
              data?.instructionsPage2?.split("\n").map((item, index) => {
                if (item.includes("@img@")) {
                  return (
                    <div key={index}>
                      {data?.instructionsPage2Image && (
                        <img
                          src={data?.instructionsPage2Image}
                          alt="Instruction Visual"
                          style={{ maxWidth: "100%", margin: "10px 0" }}
                        />
                      )}
                    </div>
                  );
                }

                if (item.trim()) {
                  return <p key={index}>{item}</p>;
                }
                return null;
              })}
          </h5>
          {/* <ul>
            <li>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus
              eum officia dolores rerum, animi laborum dolorem hic? Magnam, rem
              veritatis cum eligendi, optio quod numquam dolorem eum quae harum
              sapiente.
            </li>
            <li>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus
              eum officia dolores rerum, animi laborum dolorem hic? Magnam, rem
              veritatis cum eligendi, optio quod numquam dolorem eum quae harum
              sapiente.
            </li>
            <li>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus
              eum officia dolores rerum, animi laborum dolorem hic? Magnam, rem
              veritatis cum eligendi, optio quod numquam dolorem eum quae harum
              sapiente.
            </li>
          </ul> */}
          <div className="TestWithVideoPage3Select">
            <Form.Select
              className="mb-3 w-25"
              aria-label="Default select example"
            >
              <option>English</option>
              {/* <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option> */}
            </Form.Select>
          </div>
          <div className="TestWithVideoPage3Buttons">
            <Button>Go Back</Button>
            <Button
              onClick={() =>
                navigate(
                  `/user/current-affairs/targetCurrentAffairs/${id}/${testId}/5`,{
                    state:{todo}
                  }
                )
              }
            >
              I Am Ready To Begin
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestWithVideoPage4tca;
