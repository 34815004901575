const images = {
  navBarLogo: require("../assets/images/NavBar/NavbarLogo.svg").default,
  navBarGiftIcon: require("../assets/images/NavBar/gifticon.png"),
  homePageBanner1: require("../assets/images/HomePage/homePageBanner1.svg")
    .default,
  homePageLearningIcon1: require("../assets/images/HomePage/learningIcons/calander.png"),
  homePageLearningIcon2: require("../assets/images/HomePage/learningIcons/teacher.png"),
  homePageLearningIcon3: require("../assets/images/HomePage/learningIcons/pen.png"),
  homePageLearningIcon4: require("../assets/images/HomePage/learningIcons/hand.png"),
  homePageLearningIcon5: require("../assets/images/HomePage/learningIcons/notebook.png"),
  homePageLearningIcon6: require("../assets/images/HomePage/learningIcons/hands.png"),
  homePageLearningIcon7: require("../assets/images/HomePage/learningIcons/arrow.png"),
  homePageLearningIcon8: require("../assets/images/HomePage/learningIcons/rocket.png"),
  homePageAIIcons1: require("../assets/images/HomePage/AiIcons/Frame.png"),
  homePageAIIcons2: require("../assets/images/HomePage/AiIcons/Frame-1.png"),
  homePageAIIcons3: require("../assets/images/HomePage/AiIcons/Frame-2.png"),
  homePageAIImage: require("../assets/images/HomePage/AiIcons/AiImage.png"),
  homePageCourseIcon1: require("../assets/images/HomePage/CoursesIcon/1.png"),
  homePageCourseIcon2: require("../assets/images/HomePage/CoursesIcon/2.png"),
  homePageCourseIcon3: require("../assets/images/HomePage/CoursesIcon/3.png"),
  fundationCourseUserImage:
    require("../assets/images/User/Home/Courses/userImageFundationCourse.svg")
      .default,
  homePageHandWrittenNotes: require("../assets/images/HomePage/HandWrittenNotes.png"),
  testSeriesImage: require("../assets/images/HomePage/TestSeriesImage.png"),
  pyqImage: require("../assets/images/HomePage/PYQImage.png"),
  homePageComunityImage1: require("../assets/images/HomePage/Community/Frame.png"),
  homePageComunityImage2: require("../assets/images/HomePage/Community/Frame-1.png"),
  homePageComunityImage3: require("../assets/images/HomePage/Community/Frame-2.png"),
  homePageComunityImage4: require("../assets/images/HomePage/Community/Vector.png"),
  hompageDownloadAppImage: require("../assets/images/HomePage/iphonePic.png"),
  loginPageBackgroundImage:
    require("../assets/images/Login/loginBackground.svg").default,
  goalsPageCourseImage1: require("../assets/images/Goals/image.svg").default,
  goalsPageCourseImage2: require("../assets/images/Goals/image-1.svg").default,
  goalsPageCourseImage3: require("../assets/images/Goals/image-2.svg").default,
  UserSidebarIcons: require("../assets/images/User/Sidebar/Frame.svg").default,
  UserSidebarIcons1: require("../assets/images/User/Sidebar/Frame-1.svg")
    .default,
  UserSidebarIcons2: require("../assets/images/User/Sidebar/Frame-2.svg")
    .default,
  UserSidebarIcons3: require("../assets/images/User/Sidebar/Frame-3.svg")
    .default,
  UserSidebarIcons4: require("../assets/images/User/Sidebar/Frame-4.svg")
    .default,
  UserSidebarIcons5: require("../assets/images/User/Sidebar/Frame-5.svg")
    .default,
  UserSidebarIcons6: require("../assets/images/User/Sidebar/Frame-6.svg")
    .default,
  UserSidebarIcons7: require("../assets/images/User/Sidebar/Frame-7.svg")
    .default,
  UserSidebarIcons8: require("../assets/images/User/Sidebar/Frame-8.svg")
    .default,
  UserSidebarIcons9: require("../assets/images/User/Sidebar/Frame-9.svg")
    .default,
  UserSidebarIcons10: require("../assets/images/User/Sidebar/Frame-10.svg")
    .default,
  UserSidebarIcons11: require("../assets/images/User/Sidebar/Frame-11.svg")
    .default,
  UserSubjectsImage: require("../assets/images/User/subjectImage.png"),
    
  UserTestSubmitted: require("../assets/images/User/SkillsPage/testCompleted.png"),
  UserTestTrophy: require("../assets/images/User/TestWithVideo/trophy.png"),
  footerLogo: require("../assets/images/HomePage/footerLogo.svg").default,

  // User Dashboard
  userDashboardTopBanner: require("../assets/images/User/topBanner.svg")
    .default,
  userDashboardSkillsBanner:
    require("../assets/images/User/SkillsPage/skillsPage.svg").default,
  menubarIcon1: require("../assets/images/User/MenuBar/1.jpeg"),
  menubarIcon2: require("../assets/images/User/MenuBar/2.jpeg"),
  menubarIcon3: require("../assets/images/User/MenuBar/3.jpeg"),
  menubarIcon4: require("../assets/images/User/MenuBar/4.jpeg"),
  menubarIcon5: require("../assets/images/User/MenuBar/5.jpeg"),
  userHomeCoursesImage1:
    require("../assets/images/User/Home/Courses/image-1.svg").default,
  userHomeCoursesImage2:
    require("../assets/images/User/Home/Courses/image-2.svg").default,
  userHomeRewardsImage1: require("../assets/images/User/Home/Rewards/image-1.png"),
  userHomeRewardsImage2: require("../assets/images/User/Home/Rewards/image-2.png"),
  userHomeTopContentImage1: require("../assets/images/User/Home/TopConent/image.png"),
  userAboutExamImage: require("../assets/images/User/AboutExam/image-1.svg")
    .default,
  userTestWithVideoImage: require("../assets/images/User/TestWithVideo/testPageImage.png"),
  userHandWrittenNotesImage: require("../assets/images/User/HandWrittenNotes/HandWrittenNotes.png"),
  userHandWrittenNotesImage2: require("../assets/images/User/HandWrittenNotes/studentImage.png"),
  userPYQsWithNotesImages1:
    require("../assets/images/User/PYQsWithVideo/PYQswithVideoBanner.svg")
      .default,
  userStudyPlannerAiImage1:
    require("../assets/images/User/StudyPlannerAi/Page2/image-1.svg").default,
  userStudyPlannerAiImage2:
    require("../assets/images/User/StudyPlannerAi/Page2/image-2.svg").default,
  userStudyPlannerAiImage3:
    require("../assets/images/User/StudyPlannerAi/Page2/image-3.svg").default,
  userStudyPlannerAiImage4:
    require("../assets/images/User/StudyPlannerAi/Page2/image-4.svg").default,
  userSettingsImage: require("../assets/images/User/Settings/profileImage.svg")
    .default,
  userSettingsImage1: require("../assets/images/User/Settings/Dashboard Roadmap/1.png"),
  userSettingsImage2: require("../assets/images/User/Settings/Dashboard Roadmap/2.png"),
  userSettingsImage3: require("../assets/images/User/Settings/Dashboard Roadmap/3.png"),
  userSettingsImage4: require("../assets/images/User/Settings/Dashboard Roadmap/4.png"),
  userSettingsImage5: require("../assets/images/User/Settings/Dashboard Roadmap/5.png"),
  userSettingsImage6: require("../assets/images/User/Settings/Dashboard Roadmap/6.png"),
  userSettingsImage7: require("../assets/images/User/Settings/Dashboard Roadmap/7.png"),
  userAllDashboardImage: require("../assets/images/User/AllDashboard/videoPic.png"),
  userAllDashboardTestImage: require("../assets/images/User/AllDashboard/testPic.png"),
  userAllDashboardPracticeImage: require("../assets/images/User/AllDashboard/practiceAnalysis.png"),
  userNavbarLogo: require("../assets/images/User/mainItems/siteLogo.svg")
    .default,
  userNavbarHome: require("../assets/images/User/mainItems/logoHome.png"),
  userNavbarCart: require("../assets/images/User/mainItems/abc.png"),
  userCourseViewDashboardImage:
    require("../assets/images/User/Home/Courses/viewDashboard.svg").default,
  userTestUserPageImage1: require("../assets/images/User/TestWithVideo/testPageImages1/1.png"),
  userTestUserPageImage2: require("../assets/images/User/TestWithVideo/testPageImages1/2.png"),
  userTestUserPageImage3: require("../assets/images/User/TestWithVideo/testPageImages1/3.png"),
  userTestUserPageImage4: require("../assets/images/User/TestWithVideo/testPageImages1/4.png"),
  userTestUserPageImage5: require("../assets/images/User/TestWithVideo/testPageImages1/5.png"),
  userTestUserPageImage6: require("../assets/images/User/TestWithVideo/testPageImages1/6.png"),
  userAboutPageImage: require("../assets/images/HomePage/aboutImage.png"),
  userAboutPageTeacherImage: require("../assets/images/HomePage/teachers/image.png"),
  userAboutPageTeacherImage2: require("../assets/images/HomePage/teachers/image-1.png"),
  userAboutPageTeacherImage3: require("../assets/images/HomePage/teachers/image-2.png"),
  userAboutPageTeacherImage4: require("../assets/images/HomePage/teachers/image-3.png"),
  userAboutPageDownloadBtn1: require("../assets/images/HomePage/downloadButton/image.png"),
  userAboutPageDownloadBtn2: require("../assets/images/HomePage/downloadButton/image 4.png"),
  userTickImage: require("../assets/images/Login/tickImage.png"),
  userStudyModal: require("../assets/images/Login/modalImageMain.png"),
  userCommingSoon: require("../assets/images/CommingSoon.png"),
};

export default images;
