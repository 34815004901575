import { useContext, useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import ProgressBar from "@ramonak/react-progress-bar";
import {
  UserBannerMultipleImages,
  UserBannerTopComponent,
} from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import "./Dashboard.css";
import { Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  DashboardUserHomeCourseCard,
  DashboardUserHomeCoursesCard,
  DashboardUserHomeRewardCard,
  DashboardUserHomeTopContentCard,
} from "../../../components/common/Cards";
import { getRequest } from "../../../services/apiService";
import { endpoints } from "../../../services/endPoints";
import { UserContext } from "../../../Context/Context";
const Dashboard = () => {
  const imagesCard = [
    { image: images.UserSidebarIcons1 },
    { image: images.UserSidebarIcons2 },
    { image: images.UserSidebarIcons3 },
    { image: images.UserSidebarIcons4 },
    { image: images.UserSidebarIcons5 },
    { image: images.UserSidebarIcons6 },
    { image: images.UserSidebarIcons7 },
    { image: images.UserSidebarIcons8 },
    { image: images.UserSidebarIcons9 },
    { image: images.UserSidebarIcons10 },
    { image: images.UserSidebarIcons11 },
  ];
  const userData = useContext(UserContext);
  const myCourseCard = [
    {
      name: "My Purchases",
      iconImage: "mynaui:book-check",
      link: "/user/home",
    },
    {
      name: "Study Planner",
      iconImage: "mynaui:book-check",
      link: "/user/studPlannerBanner",
    },
    {
      name: "Explore Courses",
      iconImage: "mingcute:telescope-line",
      link: "/user/course",
    },

    {
      name: "Test Series",
      iconImage: "ic:baseline-ondemand-video",
      link: "/user/test-video-solution",
    },
  ];

  const courseCards = [
    {
      image: images.userHomeCoursesImage1,
      title: "Quantitative Techniques for CLAT",
      time: "22 Hrs",
    },
    {
      image: images.userHomeCoursesImage1,
      title: "Quantitative Techniques for CLAT",
      time: "22 Hrs",
    },
    {
      image: images.userHomeCoursesImage2,
      title: "CLAT Crash Course",
      time: "22 Hrs",
    },
    {
      image: images.userHomeCoursesImage2,
      title: "CLAT Crash Course",
      time: "22 Hrs",
    },
  ];

  const rewardsCard = [
    {
      image: images.userHomeRewardsImage1,
      text: "FLASH30",
      description: "Coupon code for you ",
    },
    {
      image: images.userHomeRewardsImage2,
      text: "FLASH30",
      description: "Coupon code for you ",
    },
  ];

  const topContentCard = [
    {
      image: images.userHomeTopContentImage1,
      title: "Schedule & Details of CLAT 2025 Mock Test Series",
    },
    {
      image: images.userHomeTopContentImage1,
      title: "Schedule & Details of CLAT 2025 Mock Test Series",
    },
    {
      image: images.userHomeTopContentImage1,
      title: "Schedule & Details of CLAT 2025 Mock Test Series",
    },
  ];
  const [data, setData] = useState([]);
  const [goalId, setGoalId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [topBanners, setTopBanners] = useState([]);
  const [myCourses, setMyCourses] = useState([]);
  const [isLoading2, setIsLoading2] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [isCouponLoading, setIsCouponLoading] = useState(false);
  const [topContent, setTopContent] = useState([]);
  const [isTopContentLoading, setIsTopContentLoading] = useState(false);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [hasNextPage2, setHasNextPage2] = useState(false);

  const getUserData = () => {
    const params = {
      position: "TOP",
      type: "Home",
    };
    getRequest({
      endpoint: endpoints.getHomePageBanners,
      setIsLoading,
      params,
    }).then((res) => {
      setTopBanners(res?.data);
    });
  };

  const getMyCourses = () => {
    setIsLoading2(true);
    const params = {
      page: currentPage,
      limit: 10,
      type: "Course",
    };
    getRequest({
      endpoint: endpoints.getMyCourses,
      setIsLoading: setIsLoading2,
      params,
    }).then((res) => {
      setMyCourses(res?.data);
    });
  };

  const getCoupons = () => {
    setIsCouponLoading(true);
    getRequest({
      endpoint: endpoints.getCoupons,
      setIsLoading: setIsCouponLoading,
    }).then((res) => {
      setCoupons(res?.data);
    });
  };

  const getTopContent = () => {
    setIsTopContentLoading(true);
    const params = {
      page: currentPage2,
      limit: 10,
    };
    getRequest({
      endpoint: endpoints.getTopContent,
      setIsLoading: setIsTopContentLoading,
      params,
    }).then((res) => {
      setTopContent(res?.data);
      setHasNextPage2(res?.pagination?.hasNextPage);
    });
  };

  useEffect(() => {
    getTopContent();
    getUserData();
    getMyCourses();
    getCoupons();
  }, []);
  const navigate = useNavigate();

  const handleSubmit = () => {};
  return (
    <div className="user_container">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="DashboardBannerCSSTop"
            imagecss={"DashboardBannerCSSTopImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar name={`Welcome,  ${userData?.user?.fullName} 🤗`} />
        </div>
        {topBanners && (
          <div className="user_container_width">
            <div>
              <UserBannerMultipleImages
                images={topBanners?.map((i) => i.image)}
                divCss="DashboardBannerCSS"
                alt="images"
                imageCss="DashboardBannerCSSImage"
              />
            </div>
          </div>
        )}

        {myCourseCard && (
          <div className="userDashboardPage_continer3 user_container_width">
            <p className="userDashboardPage_continer3_1">
              {myCourseCard?.map((i) => (
                <DashboardUserHomeCourseCard
                  name={i.name}
                  image={i.iconImage}
                  link={i.link}
                />
              ))}
            </p>
          </div>
        )}
      
        {myCourses && (
          <div className="userDashboardPage_continer4 user_container_width mt-1">
            <div className="userDashboardPage_continer4_0 gridLayout3">
              {myCourses &&
                myCourses.map((i) => (
                  <DashboardUserHomeCoursesCard
                    name={i.course?.title}
                    image={i.course?.courseImage?.[0]}
                    time={i.course?.duration}
                    item={i}
                  />
                ))}
            </div>
            <div className="d-flex justify-content-center" >
              {isLoading2 ? (
                <div className="loader">
                  <Spinner animation="border" />
                </div>
              ) : hasNextPage ? (
                <Button
                  className="userDashboardPage_continer4_1_1"
                  onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
                >
                  Load More
                </Button>
              ) : null}
            </div>
          </div>
        )}
        <div className="userDashboardPage_continer5 user_container_width">
          <p>My Rewards</p>
          <p className="userDashboardPage_continer5_1 ">
            {coupons?.map((i) => (
              <DashboardUserHomeRewardCard
                image={i.image}
                text={i.title}
                description={i.desc}
                couponCode={i.code}
                discount={i.discount}
              />
            ))}
          </p>
        </div>
        <div className="userDashboardPage_continer6 user_container_width">
          <p>Top Content This Week</p>

          <div className="userDashboardPage_continer6_1 gridLayout2">
            {topContent?.map((i) => (
              <DashboardUserHomeTopContentCard
                image={i.thumbnail}
                title={i.headingText}
                onClick={() => navigate(`/user/top-content/${i._id}`)}
              />
            ))}
          </div>
          <div className="userDashboardPage_continer4_1">
            {!isTopContentLoading && hasNextPage ? (
              <Button
                className="userDashboardPage_continer4_1_1"
                onClick={() => setCurrentPage2((prevPage) => prevPage + 1)}
              >
                Load More
              </Button>
            ) : isTopContentLoading ? (
              <div className="loader">
                <Spinner animation="border" />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(Dashboard);
