import { useEffect, useState } from "react";
import UserNavbar from "../Component/UserNavbar";
import { Accordion, Form } from "react-bootstrap";
import VideoPlayer from "../../../components/common/VideoPlayer";
import "../Courses/Course.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import images from "../../../utils/images";
import { useNavigate, useParams } from "react-router-dom";
import { endpoints } from "../../../services/endPoints";
import { getRequest, postRequest } from "../../../services/apiService";
import { formatToMonthYear } from "../../../utils/dateFormatter";

const SkillsPage3 = () => {
  const navigate = useNavigate();
  const Items = [
    "OverView",
    "Teacher Notes",
    "Q & A",
    "Hand written notes by topper",
  ];
  const params = useParams();
  const [selectedItem, setSelectedItem] = useState(0);
  const [subjectTitle, setSubjectTitle] = useState("");
  const [courseVideo, setCourseVideo] = useState([]);
  const [currentVideoId, setCurrentVideoId] = useState(params?.videoId || "");
  const [selectedVideo, setSelectedVideo] = useState("");
  const [currentVideoLink, setCurrentVideoLink] = useState("");
  const [selectedVideoData, setSelectedVideoData] = useState({});
  const [data, setData] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [qaAData, setQaAData] = useState([]);
  const [allQA, setAllQA] = useState([]);
const { id } = useParams();
  const getUserData = () => {
    getRequest({
      endpoint: endpoints.getSkillsById(id),
      setIsLoading,
      params: {
        courseId: params.course,
        courseCategoryId: params.CourseType,
        subjectId: params.subject,
        chapterId: params.chapterId,
      },
    }).then((res) => {
      const allSubjectsId = res?.data?.subjects
        ?.[0]?.subSubjects?.flatMap((subSubject) => subSubject?.chapters || []);
     

    


      const allChaptersVideos =
        allSubjectsId?.flatMap((chapter) =>
          chapter?.topics?.flatMap((topic) => topic?.courseVideos || [])
        ) || [];
     
        
      setCourseVideo(allChaptersVideos);
      setCurrentVideoId(
        allChaptersVideos?.[0]?._id
      );
      setSelectedVideo(params.videoId);
      setCurrentVideoLink(
        allChaptersVideos?.[0]
          ?.videoLink
      );

      setSubjectTitle(res?.data?.title);
      setData(res?.data?.subjects);
    });
  };

  useEffect(() => {
    getUserData();
  }, [params.course]);

  const getQaAData = (topicId) => {
    getRequest({
      endpoint: endpoints.getQaData(topicId),
      setIsLoading,
    }).then((res) => {
      setQaAData(res?.data);
    });
  };
  useEffect(() => {
    if (selectedVideo && courseVideo) {
      const selectedVideoObj = courseVideo.find(
        (video) => video._id === selectedVideo
      );
      setSelectedTopic(selectedVideoObj?.topic?._id || "");
      if (selectedVideoObj?.topic?._id) {
        getQaAData(selectedVideoObj?.topic?._id);
      }
      setSelectedVideoData(selectedVideoObj);
      setCurrentVideoLink(selectedVideoObj?.videoLink || "");
    }
  }, [selectedVideo, courseVideo, selectedTopic]);

  const [comment, setComment] = useState("");
  const [dataQandA, setDataQandA] = useState([]);
  const getQandAbyId= () => {
    getRequest({
      endpoint: endpoints.getQandAbyIdMainId(selectedVideoData?.topic?._id),
      setIsLoading,
    }).then((res) => {
      setDataQandA(res?.data);
      
    });
    
  }

  useEffect(() => {
    if (selectedTopic) {
      getQaAData(selectedTopic);
    }
  },[selectedVideoData?.topic?._id]);
  const postQandA = (id) => {
    postRequest({
      endpoint: endpoints.postQandA(selectedVideoData?.topic?._id),
      data: { message: comment },
      setIsLoading,
    }).then((res) => {
      setComment("");
      getQandAbyId();
      return;
    });
  };
  return (
    <div>
      <div>
        <UserNavbar title={courseVideo?.chapter?.name} />
      </div>
      <div className="CoursePage5MainContainer">
        <div className="CoursePage5Container">
          <div className="CoursePage5Content1">
            <p>Course Content</p>
            <div>
              <Accordion>
                {courseVideo?.map((item, index) => (
                  <Accordion.Item
                    className="mb-3"
                    eventKey={index.toString()}
                    key={index}
                    style={{
                      border:
                        selectedVideo === item?._id ? "1px solid red" : "none",
                    }}
                  >
                    <Accordion.Header className="CoursePage5AccordionHeader1">
                      <p className="CoursePage5AccordionHeader">
                        <span>{item?.topic?.name || "No Topic"}</span>
                        <span>1/1 | {item?.videoDuration}</span>
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => setSelectedVideo(item?._id)}
                        className="CoursePage5AccordionBody123"
                      >
                        <span></span>
                        <span className="CoursePage5AccordionBody">
                          {/* Assuming 'item' has a property 'topicName' */}
                          <span>
                            <Icon
                              icon="icon-park-outline:dot"
                              style={{
                                color: "gray",
                                width: "20px",
                                height: "20px",
                                marginRight: "10px",
                              }}
                            />{" "}
                            {item?.videoName || "No Topic"}
                          </span>
                          <span>
                            <Icon
                              icon="icomoon-free:file-play"
                              style={{
                                color: "gray",
                                width: "20px",
                                height: "20px",
                                marginRight: "10px",
                                marginLeft: "2rem",
                              }}
                            />
                            <span>{item?.videoDuration}</span>
                          </span>
                        </span>
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
          <div>
            <div>
              {currentVideoLink && <VideoPlayer videoLink={currentVideoLink} />}
            </div>{" "}
            <div className="CoursePage5Content2">
              <div className="CoursePage5Content2Items">
                {Items.map((item, index) => {
                  return (
                    <div
                      className={
                        selectedItem === index
                          ? "CoursePage5Content2ItemActive"
                          : "CoursePage5Content2Item"
                      }
                      onClick={() => setSelectedItem(index)}
                      key={index}
                    >
                      <span>{item}</span>
                    </div>
                  );
                })}
              </div>
              <div>
                {selectedItem === 0 && (
                  <div className="CoursePage5Content2Video">
                    <p className="CoursePage5Content2VideoText">
                      {selectedVideoData?.videoName}
                    </p>
                    <p className="CoursePage5Content2VideoText2">
                      <span>
                        <span>
                          {Array.from({ length: 5 }, (_, i) => (
                            <Icon icon="noto:star" />
                          ))}
                        </span>
                        <span>5 Stars</span>
                      </span>
                      <span>
                        <span>300245</span>
                        <span>Students</span>
                      </span>
                      <span>
                        <span>{selectedVideoData?.videoDuration}</span>
                        <span>Total</span>
                      </span>
                    </p>
                    <p className="CoursePage5Content2VideoText3">
                      <span>
                        <Icon
                          icon="humbleicons:refresh"
                          style={{ color: "#000" }}
                        />
                      </span>
                      <span>
                        last update{" "}
                        {formatToMonthYear(selectedVideoData?.updatedAt)}{" "}
                      </span>
                    </p>
                    <p className="CoursePage5Content2VideoText3">
                      <span>
                        <Icon
                          icon="icon-park-outline:translate"
                          style={{ color: "#000" }}
                        />
                      </span>
                      <span>{selectedVideoData?.topic?.language}</span>
                    </p>
                    <hr />
                    <p className="CoursePage5Content2VideoText4">
                      <span>Feactures</span>
                      <span>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Quo incidunt temporibus, libero voluptas at facere
                        magnam vel quos assumenda qui officiis error ex aperiam
                        aliquid iusto! A molestiae necessitatibus quaerat!
                      </span>
                    </p>
                    <hr />
                    <p className="CoursePage5Content2VideoText4">
                      <span>Description</span>
                      <span>{selectedVideoData?.videoDescription} </span>
                    </p>
                    <hr />
                    <p className="CoursePage5Content2VideoText4">
                      <span>Instructor</span>
                      <span className="CoursePage5Content2VideoText4Span">
                        <span>
                          {selectedVideoData?.educatorName?.image && (
                            <img
                              src={selectedVideoData?.educatorName?.image}
                              className="CoursePage5Content2VideoText4SpanImage"
                              alt="userImage"
                            />
                          )}
                        </span>
                        <span className="CoursePage5Content2VideoText4Span1">
                          <span>{selectedVideoData?.educatorName?.name}</span>
                          <span>
                            {selectedVideoData?.educatorName?.qualifications}
                          </span>
                        </span>
                      </span>
                      <span>{selectedVideoData?.educatorName?.desc} </span>
                    </p>
                  </div>
                )}
                {selectedItem === 1 && (
                  <div>
                    <div className="CoursePage5Content2Videork">
                      {selectedVideoData &&
                        selectedVideoData?.educatorNotes?.map((item, index) => (
                          <div
                            onClick={() =>
                              navigate(`/user/notes/${index + 1}`, {
                                state: {
                                  noteData: item,
                                  title: courseVideo?.chapter?.name,
                                },
                              })
                            }
                            className="CoursePage5Content2VideoTextrk"
                          >
                            <span className="CoursePage5Content2VideoTextrk1">
                              <span>
                                <img
                                  src={images.userNavbarHome}
                                  alt="imageuserNavbarHome"
                                />
                              </span>
                              <span>
                                <span>{selectedVideoData?.videoName}</span>
                                <span>
                                  {selectedVideoData?.educatorName?.name}
                                </span>
                                <span>23 Pages</span>
                              </span>
                            </span>
                            <span>
                              <Icon
                                icon="mingcute:arrow-right-line"
                                style={{ color: "#000" }}
                              />
                            </span>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
                {selectedItem === 2 && (
                  <div className="CoursePage5Content2VideorkQA">
                    <div className="CoursePage5Content2VideorkQA1">
                      <p style={{ display: "flex", flexDirection: "column" }}>
                        {}
                        {selectedVideoData?.topic?.QandA?.map((item) => (
                          <p className="CoursePage5Content2VideorkQA1">
                            <p>
                               <img
                                src={images.userNavbarHome}
                                alt="userimage"
                              />
                            </p>
                            <p>
                              <p className="d-flex justify-content-between">
                                <span>{item?.user?.name || ""}</span>
                                <span>3 days ago</span>
                              </p>
                              <p>
                                <span>Question: {" "}</span>
                                <span>{item?.message}</span>
                              </p>
                              <p>
                                <span>
                                  <img
                                    src={images.userNavbarHome}
                                    alt="userImage"
                                  />
                                </span>
                                <span>
                                  <span>Answer : {" "}</span>
                                  <span>
                                    {item?.reply}
                                    {/* Awesome course. Both this and the beginners Figma
                                  Essentials courses are amazing. Highly recommend.
                                  I've learned a ton and if anything, they're great
                                  to get your foot through the door. */}
                                  </span>
                                </span>
                              </p>
                            </p>
                          </p>
                        ))}
                      </p>
                    </div>
                    <p>
                      <p className="CoursePage5Content2VideorkQA1P33">
                        <span>
                          <Form.Control
                            type="text"
                            placeholder="Write your Comment"
                            className="CoursePage5Content2VideorkQA1P33Input"
                            value={comment}
                            onChange={(e) => {
                              setComment(e.target.value);
                            }}
                          />
                        </span>
                        <span onClick={() => postQandA("post")}>Send</span>
                      </p>
                    </p>
                  </div>
                )}
                {selectedItem === 3 && (
                  <div>
                    <div className="CoursePage5Content2Videork">
                      {selectedVideoData?.handwrittenNotes?.map(
                        (item, index) => (
                          <div
                            onClick={() =>
                              navigate(`/user/notes/${index}`, {
                                state: {
                                  noteData: item?.handWrittenNotesPdf?.[index],
                                  title: "Notes",
                                },
                              })
                            }
                            className="CoursePage5Content2VideoTextrk"
                          >
                            <span className="CoursePage5Content2VideoTextrk1">
                              <span>
                                {}
                                <img
                                  src={images.userNavbarHome}
                                  alt="imageuserNavbarHome"
                                />
                              </span>
                              <span>
                                <span>{item?.bundleName}</span>
                                <span>Rock Jhonson</span>
                                <span>{item?.pagesCount || 0} Pages</span>
                              </span>
                            </span>
                            <span>
                              <Icon
                                icon="mingcute:arrow-right-line"
                                style={{ color: "#000" }}
                              />
                            </span>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillsPage3;
