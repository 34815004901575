import { useContext, useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import { UserBannerTopComponent } from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { Form, Spinner } from "react-bootstrap";
import "./CurrentAffairs.css";
import { Button } from "react-bootstrap";
import { Icon } from "@iconify/react/dist/iconify.js";
import { CommunityModal } from "../../../components/common/Modal";
import { useNavigate } from "react-router-dom";
import { getRequest } from "../../../services/apiService";
import { endpoints } from "../../../services/endPoints";
import { UserContext } from "../../../Context/Context";
import {
  formatDateCurrentAffairs,
  formatDateCurrentAffairsTarget,
  timeAgo,
} from "../../../utils/dateFormatter";
import ContentWithShowMore from "../../../components/common/ContentWithShowMore";

const CurrentAffairs = () => {
  const navigate = useNavigate();
  const communityNotesUserCard = [
    {
      name: "User Name",
      iconImage: images.userHandWrittenNotesImage2,
      time: "4 Min Ago",
      description:
        "Lorem ipsum odor amet, consectetuer adipiscing elit. Commodo fringilla blandit fermentum cras augue...",
    },
    {
      name: "User Name",
      iconImage: images.userHandWrittenNotesImage2,
      time: "4 Min Ago",
      description:
        "Lorem ipsum elit. Commodo fringilla blandit fermentum cras augue...",
    },
  ];

  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState("Daily News");
  const userData = useContext(UserContext);
  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const dailyNewsData = [
    {
      title: "User Name",
      iconImage: images.userHandWrittenNotesImage2,
      subTitle: "4 Min Ago",
      description:
        "Lorem ipsum odor amet, consectetuer adipiscing elit. Commodo fringilla blandit fermentum cras augue...",
    },
    {
      title: "User Name",
      iconImage: images.userHandWrittenNotesImage2,
      subTitle: "4 Min Ago",
      description:
        "Lorem ipsum elit. Commodo fringilla blandit fermentum cras augue...",
    },
    {
      title: "User Name",
      iconImage: images.userHandWrittenNotesImage2,
      subTitle: "4 Min Ago",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Commodo fringilla blandit fermentum cras augue...",
    },
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonth = new Date().getMonth();
  const initialIndex =
    currentMonth >= 2 ? currentMonth - 2 : (12 + currentMonth - 2) % 12;

  const [currentMonthIndex, setCurrentMonthIndex] = useState(initialIndex || 0);

  const getCurrentThreeMonths = () => {
    return [
      months[currentMonthIndex],
      months[(currentMonthIndex + 1) % months.length],
      months[(currentMonthIndex + 2) % months.length],
    ];
  };

  const switchToNextMonth = () => {
    setCurrentMonthIndex((prevIndex) => (prevIndex + 1) % months.length);
  };

  const switchToPreviousMonth = () => {
    setCurrentMonthIndex((prevIndex) =>
      prevIndex === 0 ? months.length - 1 : prevIndex - 1
    );
  };

  const currentMonths = getCurrentThreeMonths();
  const [dailyNews, setDailyNews] = useState([]);
  const [editorialData, setEditorialData] = useState([]);
  const [DailyQuiz, setDailyQuiz] = useState([]);
  const [targetCurrent, setTargetCurrent] = useState([]);
  const [youtubeData, setYoutubeData] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date()?.toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toISOString().split("T")[0];
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [currentTopicPage, setCurrentTopicPage] = useState(1);
  const [hasNextTopicPage, setHasNextTopicPage] = useState(true);
  const [topicData, setTopicData] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dailyQuiz, setDailyQuizData] = useState([]);
  const [dailyQuizPage, setDailyQuizPage] = useState(1);
  const [dailyQuizStartDate, setDailyQuizStartDate] = useState(new Date()?.toISOString().split("T")[0]);
  const [hasNextDailyQuizPage, setHasNextDailyQuizPage] = useState(true);
  const [dailyQuizisLoading, setDailyQuizIsLoading] = useState(false);
  const [targetCurrentAffairs, setTargetCurrentAffairs] = useState([]);
  const [targetCurrentPage, setTargetCurrentPage] = useState(1);
  const [hasNextTargetCurrentPage, setHasNextTargetCurrentPage] =
    useState(true);
  const [targetCurrentIsLoading, setTargetCurrentIsLoading] = useState(false);
  const [youTubeVideos, setYouTubeVideos] = useState([]);
  const [youTubeVideosPage, setYouTubeVideosPage] = useState(1);
  const [hasNextYouTubeVideosPage, setHasNextYouTubeVideosPage] =
    useState(true);
  const [youTubeVideosIsLoading, setYouTubeVideosIsLoading] = useState(false);

  const getAllData = async () => {
    const params = {
      // goal: userData?.user?.goal,
      // goalCategory: userData?.user?.goalCategory,
      page: currentPage,
      status: "Publish",
      type: selectedItem,
      limit: 10,
    };
    if (startDate) params.startDate = startDate;
    if (endDate) params.endDate = endDate;
    try {
      const res = await getRequest({
        endpoint: endpoints.getCurrentAffairs,
        setIsLoading,
        params,
      });

      setData((prevData) => [
        ...(prevData || []),
        ...(Array.isArray(res?.data) ? res.data : []),
      ]);

      setHasNextPage(res?.pagination?.hasNextPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    setData([]);
    setCurrentPage(1);
    if (selectedItem === "Daily News" || selectedItem === "Editorial Analysis")
      getAllData();
  }, [selectedItem, currentPage, startDate, endDate]);

  const getAllYoutubeVideos = async () => {
    try {
      const res = await getRequest({
        endpoint: endpoints.getYoutubeVideos,
        setIsLoading,
        params: {
          limit: 10,
          page: currentPage,
        },
      });
      setYouTubeVideos((prevData) => [
        ...(prevData || []),
        ...(Array.isArray(res?.data) ? res.data : []),
      ]);
      setHasNextYouTubeVideosPage(res?.pagination?.hasNextPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getAllTargetCurrent = async () => {
    if (selectedItem === "Daily Quiz") {
      const tomorrow = new Date(dailyQuizStartDate);
      tomorrow.setDate(tomorrow.getDate() + 1);

      const dailyQuizParams = {
        limit: 10,
        startDate: dailyQuizStartDate,
        endDate: tomorrow.toISOString().split("T")[0],
        status: "Publish",
        type: "Daily Quiz",
      };
      const res = await getRequest({
        endpoint: endpoints.getTargetCurrent,
        setIsLoading,
        params: dailyQuizParams,
      });
      setData((res?.data || []));
      // setData((prevData) => [
      //   ...(prevData || []),
      //   ...(Array.isArray(res?.data) ? res.data : []),
      // ]);
      setHasNextPage(res?.pagination?.hasNextPage);
      const res2 = await getRequest({
        endpoint: endpoints.getTargetCurrent,
        setIsLoading,
        params: dailyQuizParams,
      });
      setDailyQuizData((prevData) => [
        ...(prevData || []),
        ...(Array.isArray(res2?.data) ? res2.data : []),
      ]);
      setHasNextDailyQuizPage(res2?.pagination?.hasNextPage);
    }
  };

  useEffect(() => {
    setData([]);
    setCurrentPage(1);
    if (selectedItem === "Daily Quiz") getAllTargetCurrent();
  }, [selectedItem, dailyQuizPage,dailyQuizStartDate]);

  useEffect(() => {
    setData([]);
    setCurrentPage(1);
    if (selectedItem === "Youtube Videos") getAllYoutubeVideos();
  }, [selectedItem, currentPage]);

  const getAllTargetCurrentAffairs = async () => {
    try {
      if (!currentMonth) {
        throw new Error("Current month is not defined");
      }

      const firstDateOfMonth = new Date(
        currentMonth.getFullYear(),
        currentMonth.getMonth(),
        1
      )
        .toISOString()
        .split("T")[0];
      const lastDateOfMonth = new Date(
        currentMonth.getFullYear(),
        currentMonth.getMonth() + 1,
        0
      )
        .toISOString()
        .split("T")[0];

      // Fetch data
      const res = await getRequest({
        endpoint: endpoints.getTargetCurrentAffairs,
        setIsLoading,
        params: {
          limit: 10,
          page: targetCurrentPage,
          status: "Publish",
          type: "Monthly MCQs",
          startDate: firstDateOfMonth,
          endDate: lastDateOfMonth,
        },
      });

      // Update state with new data
      setTargetCurrentAffairs((prevData) => [
        ...(prevData || []),
        ...(Array.isArray(res?.data) ? res.data : []),
      ]);

      // Check if there are more pages
      setHasNextTargetCurrentPage(res?.pagination?.hasNextPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (selectedItem === "Target Current Affairs") getAllTargetCurrentAffairs();
  }, [selectedItem, targetCurrentPage, currentMonth]);
  const [mytargetData, setMyTargetAffairs] = useState([]);
  const [myTargetPage, setMyTargetPage] = useState(1);
  const [hasNextMyTargetPage, setHasNextMyTargetPage] = useState(true);
  const [myTopicWiseData, setMyTopicWiseData] = useState([]);
  const [myTopicPage, setMyTopicPage] = useState(1);
  const [hasNextMyTopicPage, setHasNextMyTopicPage] = useState(true);

  const getMyTargetTopicAffairs = async () => {
    try {
      const currentYear = new Date().getFullYear();
      const startMonth = currentMonths[0];
      const endMonth = currentMonths[currentMonths.length - 1];

      const startDate = new Date(`${startMonth} 1, ${currentYear}`);

      const endDate = new Date(`${endMonth} 1, ${currentYear}`);
      endDate.setMonth(endDate.getMonth() + 1);
      endDate.setDate(0);
      const res = await getRequest({
        endpoint: endpoints.getTargetCurrent,
        setIsLoading,
        params: {
          limit: 10,
          page: myTargetPage,
          status: "Publish",
          type: "Topic Wise MCQs",
          startDate: startDate.toISOString().split("T")[0],
          endDate: endDate.toISOString().split("T")[0],
        },
      });

      setMyTopicWiseData((prevData) => [
        ...(prevData || []),
        ...(Array.isArray(res?.data) ? res.data : []),
      ]);
      setHasNextMyTopicPage(res?.pagination?.hasNextPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getMyTargetAffairs = async () => {
    try {
      const currentYear = new Date().getFullYear();

      // Convert months to start and end dates
      const startMonth = currentMonths[0];
      const endMonth = currentMonths[currentMonths.length - 1];

      // Start date: 1st day of the first month
      const startDate = new Date(`${startMonth} 1, ${currentYear}`);

      // End date: Last day of the last month
      const endDate = new Date(`${endMonth} 1, ${currentYear}`);
      endDate.setMonth(endDate.getMonth() + 1); // Move to next month
      endDate.setDate(0);
      const res = await getRequest({
        endpoint: endpoints.getTargetCurrent,
        setIsLoading,
        params: {
          limit: 10,
          page: myTargetPage,
          status: "Publish",
          type: "Monthly MCQs",
          startDate: startDate.toISOString().split("T")[0],
          endDate: endDate.toISOString().split("T")[0],
        },
      });
      setMyTargetAffairs((prevData) => [
        ...(prevData || []),
        ...(Array.isArray(res?.data) ? res.data : []),
      ]);
      setHasNextMyTargetPage(res?.pagination?.hasNextPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setMyTargetAffairs([]);
    setMyTargetPage(1);
    if (selectedItem === "Target Current Affairs") {
      getMyTargetAffairs();
      getMyTargetTopicAffairs();
    }
  }, [selectedItem, myTargetPage, currentMonthIndex]);

  const renderSelectedContent = () => {
    switch (selectedItem) {
      case "Daily News":
        return (
          <>
            <div className="user_current_affairs_card_container_div">
              <div className="user_current_affairs_card_container_div_top">
                <p className="user_current_affairs_card_container_div_top_p">
                  <span>
                    <Form.Control
                      type="date"
                      value={startDate?.slice(0, 10)}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </span>
                  <span>Download PDF For Today</span>
                </p>
              </div>
              <div className="user_current_affairs_card_container_div_bottom">
                {data?.length > 0 ? (
                  data.map((item, index) => (
                    <div
                      className="user_current_affairs_card_container_div_bottom_card"
                      key={item.id || index}
                    >
                      <div className="user_current_affairs_card_container_div_bottom_card_icon">
                        <img src={item.image} alt="icon" />
                      </div>
                      <div className="user_current_affairs_card_container_div_bottom_card_content">
                        <p>{item.heading}</p>
                        <p>{item.subheading}</p>
                        <ContentWithShowMore content={item.content} />
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No data available</p>
                )}
              </div>
            </div>
            <div className="userDashboardPage_continer4_1">
              {!isLoading && hasNextPage ? (
                <Button
                  className="userDashboardPage_continer4_1_1"
                  onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
                >
                  Load More
                </Button>
              ) : isLoading ? (
                <div className="loader">
                  <Spinner animation="border" />
                </div>
              ) : null}
            </div>
          </>
        );
      case "Editorial Analysis":
        return (
          <>
            <div className="user_current_affairs_card_container_div">
              <div className="user_current_affairs_card_container_div_top">
                <p className="user_current_affairs_card_container_div_top_p">
                  <span>
                    <Form.Control
                      onChange={(e) => setStartDate(e.target.value)}
                      value={startDate?.slice(0, 10)}
                      type="date"
                    />
                  </span>
                </p>
              </div>
              <div className="user_current_affairs_card_container_div_bottom">
                {data?.length > 0 &&
                  data.map((item, index) => (
                    <div className="user_current_affairs_editorial" key={index}>
                      <p className="user_current_affairs_editorial_title">
                        {item.heading}
                      </p>
                      <p className="user_current_affairs_editorial_description">
                        <ContentWithShowMore content={item.content} />
                      </p>
                    </div>
                  ))}
              </div>
            </div>
            <div className="userDashboardPage_continer4_1">
              {!isLoading && hasNextPage ? (
                <Button
                  className="userDashboardPage_continer4_1_1"
                  onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
                >
                  Load More
                </Button>
              ) : isLoading ? (
                <div className="loader">
                  <Spinner animation="border" />
                </div>
              ) : null}
            </div>
          </>
        );
      case "Daily Quiz":
        return (
          <>
            <div className="user_current_affairs_card_container_div">
              <p>
                
                <Form.Control 
                  type="date"
                  value={dailyQuizStartDate}
                  onChange={(e) => setDailyQuizStartDate(e.target.value)}
                  style={{ width: "200px" }}
                />
              </p>
              <div className="user_current_affairs_card_container_div_bottom">
                {dailyQuiz?.length > 0 &&
                  dailyQuiz?.map((item, index) => (
                    <div
                      className="user_current_affairs_daily_quiz"
                      key={index}
                    >
                      <p className="user_current_affairs_daily_quiz_title">
                        {formatDateCurrentAffairs(item.createdAt)}
                      </p>
                      <p className="user_current_affairs_daily_quiz_description">
                        <span style={{ backgroundColor: "white" }}>
                          <span
                            style={{
                              marginRight: "1rem",
                              backgroundColor: "#D9D9D9",
                              padding: "1rem",
                              borderRadius: "10px",
                              fontWeight: "normal",
                            }}
                          >
                            {item?.questions} Questions
                          </span>
                          <span
                            style={{
                              marginRight: "1rem",
                              backgroundColor: "#D9D9D9",
                              padding: "1rem",
                              borderRadius: "10px",
                              fontWeight: "normal",
                            }}
                          >
                            {item?.marks} Marks
                          </span>
                          <span
                            style={{
                              marginRight: "1rem",
                              backgroundColor: "#D9D9D9",
                              padding: "1rem",
                              borderRadius: "10px",
                              fontWeight: "normal",
                            }}
                          >
                            {item?.duration} Min
                          </span>
                        </span>
                        <span
                          onClick={() =>
                            navigate(
                              `/user/current-affairs/targetCurrentAffairs/${item?._id}/${item?.testSeriesFiles?.[0]?._id}`,
                              {
                                state: { todo: item },
                              }
                            )
                          }
                        >
                          Attempt Now
                        </span>
                      </p>
                    </div>
                  ))}
              </div>
            </div>
            <div className="userDashboardPage_continer4_1">
              {!isLoading && hasNextPage ? (
                <Button
                  className="userDashboardPage_continer4_1_1"
                  onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
                >
                  Load More
                </Button>
              ) : isLoading ? (
                <div className="loader">
                  <Spinner animation="border" />
                </div>
              ) : null}
            </div>
          </>
        );
      case "Target Current Affairs":
        return (
          <div className="user_current_affairs_card_container_div">
            <div>
              <div className="user_current_target_container_div_top">
                <span>Monthly MCQs</span>
                <span>
                  <span onClick={switchToPreviousMonth}>
                    <Icon
                      className="user_current_target_container_div_top_icon"
                      icon={"ep:arrow-left-bold"}
                    />
                  </span>
                  <span>{currentMonths.join(" | ")}</span>
                  <span onClick={switchToNextMonth}>
                    <Icon
                      className="user_current_target_container_div_top_icon"
                      icon="ep:arrow-right-bold"
                    />
                  </span>
                </span>
              </div>
            </div>
            <div className="user_current_affairs_card_container_div_bottom">
              {mytargetData?.length > 0 &&
                mytargetData.map((item, index) => (
                  <div className="user_current_affairs_daily_quiz" key={index}>
                    <p className="user_current_affairs_daily_quiz_title">
                      {formatDateCurrentAffairsTarget(item.createdAt)}
                    </p>
                    <p className="user_current_affairs_daily_quiz_description">
                      <span style={{ backgroundColor: "white" }}>
                        <span
                          style={{
                            marginRight: "1rem",
                            backgroundColor: "#D9D9D9",
                            padding: "1rem",
                            borderRadius: "10px",
                            fontWeight: "normal",
                          }}
                        >
                          {item?.questions} Questions
                        </span>
                        <span
                          style={{
                            marginRight: "1rem",
                            backgroundColor: "#D9D9D9",
                            padding: "1rem",
                            borderRadius: "10px",
                            fontWeight: "normal",
                          }}
                        >
                          {item?.marks} Marks
                        </span>
                        <span
                          style={{
                            marginRight: "1rem",
                            backgroundColor: "#D9D9D9",
                            padding: "1rem",
                            borderRadius: "10px",
                            fontWeight: "normal",
                          }}
                        >
                          {item?.duration} Min
                        </span>
                      </span>
                      <span
                        onClick={() =>
                          navigate(
                            `/user/current-affairs/targetCurrentAffairs/${item?._id}/${item?.testSeriesFiles?.[0]?._id}`,
                            {
                              state: { todo: item },
                            }
                          )
                        }
                      >
                        Attempt Now
                      </span>
                    </p>
                  </div>
                ))}
            </div>
            <div>
              <div>
                <div className="user_current_target_container_div_top mt-4">
                  <span>Topic wise MCQs</span>
                </div>
              </div>
              <div className="user_current_affairs_card_container_div_bottom">
                {myTopicWiseData &&
                  myTopicWiseData.map((item, index) => (
                    <div
                      className="user_current_affairs_daily_quiz"
                      key={item.title}
                    >
                      <p className="user_current_affairs_daily_quiz_title">
                        Topic {index + 1}
                      </p>
                      <p className="user_current_affairs_daily_quiz_description">
                        <span style={{ backgroundColor: "white" }}>
                          <span
                            style={{
                              marginRight: "1rem",
                              backgroundColor: "#D9D9D9",
                              padding: "1rem",
                              borderRadius: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            {item?.questions} Questions
                          </span>
                          <span
                            style={{
                              marginRight: "1rem",
                              backgroundColor: "#D9D9D9",
                              padding: "1rem",
                              borderRadius: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            {item?.marks} Marks
                          </span>
                          <span
                            style={{
                              marginRight: "1rem",
                              backgroundColor: "#D9D9D9",
                              padding: "1rem",
                              borderRadius: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            {item?.duration} Min
                          </span>
                        </span>
                        <span
                          onClick={() =>
                            navigate(
                              `/user/current-affairs/targetCurrentAffairs/${item?._id}/${item?.testSeriesFiles?.[0]?._id}`,
                              {
                                state: { todo: item },
                              }
                            )
                          }
                        >
                          Attempt Now
                        </span>
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        );
      case "Target Current Affairs1":
        return (
          <div>
            <div>
              <div>
                <div className="user_current_target_container_div_top">
                  <span>Monthly MCQs</span>
                  <span>
                    <span onClick={switchToPreviousMonth}>
                      <Icon
                        className="user_current_target_container_div_top_icon"
                        icon={"ep:arrow-left-bold"}
                      />
                    </span>
                    <span>{currentMonths.join(" | ")}</span>
                    <span onClick={switchToNextMonth}>
                      <Icon
                        className="user_current_target_container_div_top_icon"
                        icon="ep:arrow-right-bold"
                      />
                    </span>
                  </span>
                </div>
              </div>
              <div className="user_current_affairs_card_container_div_bottom">
                {dailyNewsData &&
                  dailyNewsData.map((item) => (
                    <div
                      className="user_current_affairs_daily_quiz"
                      key={item.title}
                    >
                      <p className="user_current_affairs_daily_quiz_title">
                        11th November 2024
                      </p>
                      <p className="user_current_affairs_daily_quiz_description">
                        <span style={{ backgroundColor: "white" }}>
                          <span
                            style={{
                              marginRight: "1rem",
                              backgroundColor: "#D9D9D9",
                              padding: "1rem",
                              borderRadius: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            10 Questions
                          </span>
                          <span
                            style={{
                              marginRight: "1rem",
                              backgroundColor: "#D9D9D9",
                              padding: "1rem",
                              borderRadius: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            20 Marks
                          </span>
                          <span
                            style={{
                              marginRight: "1rem",
                              backgroundColor: "#D9D9D9",
                              padding: "1rem",
                              borderRadius: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            10 Min
                          </span>
                        </span>
                        <span
                          onClick={() =>
                            navigate("/user/test-video-solution/1/1")
                          }
                        >
                          Attempt Now
                        </span>
                      </p>
                    </div>
                  ))}
              </div>
            </div>
            <div>
              <div>
                <div className="user_current_target_container_div_top mt-4">
                  <span>Topic wise MCQs</span>
                </div>
              </div>
              <div className="user_current_affairs_card_container_div_bottom">
                {dailyNewsData &&
                  dailyNewsData.map((item, index) => (
                    <div
                      className="user_current_affairs_daily_quiz"
                      key={item.title}
                    >
                      <p className="user_current_affairs_daily_quiz_title">
                        Topic {index + 1}
                      </p>
                      <p className="user_current_affairs_daily_quiz_description">
                        <span style={{ backgroundColor: "white" }}>
                          <span
                            style={{
                              marginRight: "1rem",
                              backgroundColor: "#D9D9D9",
                              padding: "1rem",
                              borderRadius: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            10 Questions
                          </span>
                          <span
                            style={{
                              marginRight: "1rem",
                              backgroundColor: "#D9D9D9",
                              padding: "1rem",
                              borderRadius: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            20 Marks
                          </span>
                          <span
                            style={{
                              marginRight: "1rem",
                              backgroundColor: "#D9D9D9",
                              padding: "1rem",
                              borderRadius: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            10 Min
                          </span>
                        </span>
                        <span
                          onClick={() =>
                            navigate("/user/test-video-solution/1/1")
                          }
                        >
                          Attempt Now
                        </span>
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        );
      case "Youtube Videos":
        return (
          <div className="user_current_affairs_card_container_div">
            <div className="user_current_affairs_card_container_div_bottom">
              {youTubeVideos &&
                youTubeVideos.map((item, index) => (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      item.videoFileLink &&
                      window.open(item.videoFileLink, "_blank")
                    }
                    className="user_current_affairs_youtube_video"
                    key={index}
                  >
                    <p className="user_current_affairs_youtube_video_title">
                      <img src={item.thumbnail} alt="imageIcon" />
                    </p>
                    <p className="user_current_affairs_youtube_video_description">
                      <span>{item.description}</span>
                      <span>
                        {item.creatorName} | {item.views} Views |{" "}
                        {timeAgo(item.createdAt)}
                      </span>
                    </p>
                  </div>
                ))}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <CommunityModal show={showModal} handleClose={handleClose} />
      <div className="user_container">
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
          <div className="user_container_width">
            <UserMenuBar link={"/user/dashboard"} />
          </div>
          <div className="user_container_width">
            <div className="user_current_affairs_container">
              <div className="user_current_affairs_card">
                {[
                  "Daily News",
                  "Editorial Analysis",
                  "Daily Quiz",
                  "Target Current Affairs",
                  "Youtube Videos",
                ].map((item) => (
                  <span
                    key={item}
                    onClick={() => setSelectedItem(item)}
                    className={
                      selectedItem === item
                        ? "user_current_affairs_card_active"
                        : ""
                    }
                    role="button"
                  >
                    {item}
                  </span>
                ))}
              </div>
              <div className="user_current_affairs_card_container">
                {renderSelectedContent()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HOC(CurrentAffairs);
