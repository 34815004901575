import React from "react";
import images from "../../../utils/images";
import "./UserNavbar.css"
import { useNavigate } from "react-router-dom";
const UserNavbar = ({title}) => {
  const navigate = useNavigate();
  return (
    <div className="userNavbarContainer">
      <div className="userNavbar">
        <div className="userNavbarLogo">
          <span>
            <img  onClick={()=>navigate("user/home")} src={images.userNavbarLogo} alt="imageLogo" />
          </span>
          <span>{ title || ""}</span>
        </div>
        <div className="userNavbarHome">
          <span>
            <img src={images.userNavbarHome} className="userNavbarHome" alt="" />
          </span>
          <span>Home</span>
        </div>
      </div>
    </div>
  );
};

export default UserNavbar;
