import { useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import { UserBannerTopComponent } from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { useNavigate, useParams } from "react-router-dom";
// import "./Course.css";
import {
  DashboardUserCoursesTypeCardCoursePage3,
  TestWithVideoCard1,
} from "../../../components/common/Cards";
import AddToCart from "../../../components/common/AddToCart";
import { getRequest, postRequest } from "../../../services/apiService";
import { endpoints } from "../../../services/endPoints";
import { Spinner } from "react-bootstrap";
import { showNotification } from "../../../components/common/APIComponents";
const HandwrittenNotesP22 = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const { id } = useParams();
  const [handWrittenNotesData, setHandWrittenNotesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [courseCategoryData, setCourseCategoryData] = useState({});
  const [courseData, setCourseData] = useState({});
  const [courseTestSeries, setCourseTestSeries] = useState([]);
  const getUserData = () => {
    getRequest({
      endpoint: endpoints.getHandWrittenNotesById(id),
      setIsLoading,
    }).then((res) => {
      setData(res?.data?.subjects);
      setCourseCategoryData(res?.data?.[0]);
      setCourseData(res?.data);
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  const addToCart = (id) => {
    postRequest({
      endpoint: endpoints.addToCart,
      setIsLoading,
      data: {
        courseId: id,
        quantity: 1,
      },
    }).then((res) => {
      navigate("/user/cart");
    });
  };

  const addToWishlist = (id) => {
    postRequest({
      endpoint: endpoints.addToWishlist,
      setIsLoading,
      params: {
        courseId: id,
      },
    }).then((res) => {
      showNotification({
        type: "success",
        message: "Added to wishlist successfully",
      });
    });
  };

  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar name="" link={"/user/dashboard"} />
        </div>
        <div className="user_container_width">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
              className="loader"
            >
              <Spinner animation="border" />
            </div>
          ) : (
            <div className="userCourseContainerPage3">
              <div>
                <div className="userCourseContainerPage3Div1">
                  {data &&
                    data.map((i, index) => (
                      <DashboardUserCoursesTypeCardCoursePage3
                        name={i.subject?.name}
                        image={images.UserSubjectsImage}
                        subTopics={i?.subSubjects?.length}
                        path={i.path}
                        onClick={() => {
                          navigate(`/user/handwritten-notes/subjects/${i.subject?.name}/${id}`, {
                            state: {
                              dataItem: i,
                            },
                          });
                        }}
                      />
                    ))}
                </div>
                <div className="user_testwithvideo_container2 "></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HOC(HandwrittenNotesP22);
