import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import images from "../../../utils/images";
import {
  SkillsUserCardComponent,
  TestWithVideoCard,
} from "../../../components/common/Cards";
import UserWishlistBar from "./UserWishlistBar";
import { useNavigate } from "react-router-dom";
import { getRequest } from "../../../services/apiService";
import { endpoints } from "../../../services/endPoints";
import "../Skills/Skills.css";

const UserWishlistBarDiv = () => {
  const Items = [
    "OverView",
    "Teacher Notes",
    "Q & A",
    "Hand written notes by topper",
  ];
  const testwithVideoUserCard = [
    {
      name: "RRB NTPC (CBT 1 + CBT 2) 2024 Mock Test Series",
      iconImage: images.userTestWithVideoImage,
      description: "🌐 English, Hindi + 6 More",
      descriptionArray: ["1100 Total Tests", "10 Free tests"],
      courseText: "Start Foundation Course",
      pointsArray: [
        "96 Current General knowledge CT",
        "269 Chapter test",
        "+756 more test",
      ],
      users: 80,
    },
    {
      name: "RRB NTPC (CBT 1 + CBT 2) 2024 Mock Test Series",
      iconImage: images.userTestWithVideoImage,
      description: "🌐 English, Hindi + 6 More",
      descriptionArray: ["1100 Total Tests", "10 Free tests"],
      courseText: "Start Foundation Course",
      pointsArray: [
        "96 Current General knowledge CT",
        "269 Chapter test",
        "+756 more test",
      ],
      users: 80,
    },
  ];
  const [selectedItem, setSelectedItem] = useState(0);
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const getUserData = () => {
    getRequest({
      endpoint: endpoints.getWishlistItems,
      setIsLoading,
    }).then((res) => {
      
      setData(res?.data);
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div>
      <div>
        <UserWishlistBar />
      </div>
      <div className="myWishlistContainer">
        <div className="CoursePage5MainContainer">
          <div>
            <div className="UserSearchBarDivContainer1">
              <p className="d-flex justify-content-between">
                <p
                  onClick={() => navigate("/user/home")}
                  className="UserSearchBarDivContainerP1"
                >
                  <span>
                    <img src={images.userNavbarHome} alt="homeIcon" />
                  </span>
                  <span>Home</span>
                </p>
                <p className="UserSearchBarDivContainerP1">
                  <span>
                    <img
                      style={{ padding: "0.5rem" }}
                      src={images.menubarIcon4}
                      alt="homeIcon"
                    />
                  </span>
                  <span>Wishlist</span>
                </p>
              </p>

              <div>
                <div className="UserWishlistBarDivContainer2">
                  <p className="UserSearchBarDivContainerP23x mt-1">
                    <p className="UserSearchBarDivContainerP231">Your Skills</p>
                    <div className="userHandWrittenNotesCards gridLayout4">
                      {data?.skill &&
                        data?.skill?.map((i, index) => (
                          <SkillsUserCardComponent
                            key={index}
                            name={i.name}
                            image={i.image}
                            title={i.title}
                            pages={i.duration || 0}
                            subtopics={i.subTopics}
                            rating={i.rating || 0}
                            id={i._id}
                          />
                        ))}
                    </div>
                  </p>
                  <p className="d-flex justify-content-end">
                    <Button
                      className="UserWishlistBarDivContainer2Button"
                      variant="primary"
                    >
                      Add to Cart
                    </Button>
                  </p>
                  <p>
                    <hr />
                  </p>
                  <p></p>
                </div>
                <div className="UserWishlistBarDivContainer2">
                  <p className="UserSearchBarDivContainerP23x mt-1">
                    <p className="UserSearchBarDivContainerP231">
                      Your Courses
                    </p>
                    <div className="gridLayout3 ">
                      {data &&
                        data?.courses?.map((i, index) => {
                          return (
                            <TestWithVideoCard
                              key={index}
                              name={i.name}
                              image={images.userStudyPlannerAiImage1 || i.image}
                              description={i.description}
                              descriptionArray={i.descriptionArray}
                              courseText={i.courseText}
                              pointsArray={i.pointsArray}
                              users={i.users}
                            />
                          );
                        })}
                    </div>
                  </p>
                  <p className="d-flex justify-content-end">
                    <Button
                      className="UserWishlistBarDivContainer2Button"
                      variant="primary"
                    >
                      Add to Cart
                    </Button>
                  </p>
                  <p>
                    <hr />
                  </p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserWishlistBarDiv;
