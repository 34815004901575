import { useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import {
  BannerComponent,
  UserBannerTopComponent,
} from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { useNavigate } from "react-router-dom";
import "./Community.css";
import { CommunityNotesUserCard } from "../../../components/common/Cards";
import { Button, Spinner } from "react-bootstrap";
import { CommunityModal } from "../../../components/common/Modal";
import {
  getRequest,
  postRequest,
  putRequest,
} from "../../../services/apiService";
import { endpoints } from "../../../services/endPoints";

const Community = () => {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [bannerData, setBannerData] = useState({});

  const getBanner = () => {
    const params = {
      position: "TOP",
      type: "Community",
    };
    getRequest({
      endpoint: endpoints.getHomePageBanners,
      setIsLoading,
      params,
    }).then((res) => {
      setBannerData(res?.data);
    });
  };

  const getUserData = (currentPage) => {
    setIsLoading(true);
    const params = {
      page: currentPage,
      limit: 10,
      type: "Course",
    };
    getRequest({
      endpoint: endpoints.getCommunityPosts,
      setIsLoading: setIsLoading,
      params,
    }).then((res) => {
      setData(res?.data);
      setHasNextPage(res?.pagination?.hasNext);
    });
  };
  useEffect(() => {
    getBanner();
  }, []);
  useEffect(() => {
    getUserData(currentPage);
  }, [currentPage]);

  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleLike = (id) => {
    
    putRequest({
      endpoint: endpoints.likePost(id),

      setIsLoading,
    }).then((res) => {
      getUserData(currentPage);
    });
  };

  const handlePin = (id) => {
    putRequest({
      endpoint: endpoints.pinPost(id),
      setIsLoading,
    }).then((res) => {
      getUserData(currentPage);
    });
  };

  const handleCommentData = (id, data) => {
    postRequest({
      endpoint: endpoints.commentPost(id),
      data: {
        comment: data,
      },
      setIsLoading,
    }).then((res) => {
      getUserData(currentPage);
    });
  };  

  return (
    <>
      <CommunityModal
        show={showModal}
        handleClose={handleClose}
        getUserData={getUserData}
      />
      <div className="user_container">
        <UserBannerTopComponent
          divCSS="UserMainTopBannerDiv"
          imagecss="UserMainTopBannerDivImage"
          image={images.userDashboardTopBanner}
        />
        <div className="user_container_width">
          <UserMenuBar link="/user/dashboard" />
        </div>
        <div className="user_container_width">
          {bannerData && (
            <BannerComponent
              image={
                bannerData?.image?.[bannerData?.image?.length - 1] ||
                images.userHandWrittenNotesImage
              }
              imagecss="userHandWrittenNotesBannerImage"
              alt="alttext"
            />
          )}
          <div className="userCommunityContainer">
            <div className="userCommunityContainer1">
              {/* <Button onClick={handleOpen}>
                <Icon
                  icon="flowbite:edit-outline"
                  className="userCommunityIcon"
                />
                <span>Create Your Post</span>
              </Button> */}
            </div>
            <div className="userCommunityContainer2">
              {data ? (
                data.map((item) => (
                  <CommunityNotesUserCard
                    key={item.id}
                    name={item.title || "Untitled"}
                    iconImage={item.image}
                    time={item.time || "Just now"}
                    description={item.description || "No description"}
                    likes={item.likes}
                    comments={item.comments}
                    item={item}
                    id={item._id}
                    handleLike={handleLike}
                    handleCommentData={handleCommentData}
                    handlePin={handlePin}
                    views={item.views}
                  />
                ))
              ) : (
                <p>No posts available</p>
              )}
              <div className="userDashboardPage_continer4_1">
                {isLoading ? (
                  <div className="loader">
                    <Spinner animation="border" />
                  </div>
                ) : hasNextPage ? (
                  <Button
                    className="userDashboardPage_continer4_1_1"
                    onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
                  >
                    Load More
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HOC(Community);
