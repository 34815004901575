import { useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import {
  BannerComponent,
  UserBannerTopComponent,
} from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { useNavigate, useParams } from "react-router-dom";
import "./StudyPlanner.css";
import { StudyPlannerPage2UserCardComponent } from "../../../components/common/Cards";
import { Button } from "react-bootstrap";
import { getRequest, putRequest } from "../../../services/apiService";
import { endpoints } from "../../../services/endPoints";
import { showNotification } from "../../../components/common/APIComponents";
const StudyPlannerPage2 = () => {
  const navigate = useNavigate();
  const StudyPlannerPage2Cards = [
    {
      name: "School going student",
      image: images.userStudyPlannerAiImage1,
      id: 1,
    },
    {
      name: "College going student",
      image: images.userStudyPlannerAiImage2,
      id: 2,
    },
    {
      name: "Working Professional ",
      image: images.userStudyPlannerAiImage3,
      id: 3,
    },
    {
      name: "Preparing Full time",
      image: images.userStudyPlannerAiImage4,
      id: 4,
    },
  ];
  const [selectedId, setSelectedId] = useState(null);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const getUserData = () => {
    getRequest({
      endpoint: endpoints.getGoalsDataById,
      setIsLoading,
      limit: 10,
    }).then((res) => {
      
      setData(res?.data?.docs);
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleClick = (id1) => {
    if (!id1)
      return showNotification({
        type: "error",
        message: "Please select Profile",
      });
    navigate(`/user/study-planner-ai/${id}/${id1}`);
    return 
    putRequest({
      endpoint: endpoints.updateUserProfile,
      data: {},
    }).then((res) => {});
  };
  const [bannerData, setBannerData] = useState(null);
  const getBanner = () => {
    const params = {
      position: "TOP",
      type: "StudyPlanner",
    };
    getRequest({
      endpoint: endpoints.getHomePageBanners,
      setIsLoading,
      params,
    }).then((res) => {
      setBannerData(res?.data);
    });
  };
  useEffect(() => {
    getBanner();
  }, []);
  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar DashboardName={"Dashboard"} link={"/user/dashboard"} />
        </div>
        <div className="user_container_width">
          <div>
            <div>
              {setBannerData && (
                <BannerComponent
                  image={
                    bannerData?.image?.[bannerData?.image?.length - 1] ||
                    images.userHandWrittenNotesImage
                  }
                  imagecss={"userHandWrittenNotesBannerImage"}
                  alt="alttext"
                />
              )}
            </div>
            <div className="userStudyPlannerContainer">
              <div className="userStudyPlannerCardsContainer">
                <p className="userStudyPlanner2CardsHeading">
                  Before we start creating your study plan, could you please
                  provide a few details about yourself?
                </p>
                <p className="userStudyPlanner2CardsSubHeading">
                  What is your current profile?
                </p>
                <div className="gridLayout4">
                  {data &&
                    data?.map((item, index) => {
                      return (
                        <StudyPlannerPage2UserCardComponent
                          name={item.title}
                          image={item.image}
                          id={item._id}
                          setSelectedId={setSelectedId}
                          selectedId={selectedId}
                        />
                      );
                    })}
                </div>
                <div className="userStudyPlanner2ButtonContainer">
                  {selectedId && (
                    <Button onClick={() => handleClick(selectedId)}>
                      Save & Next
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(StudyPlannerPage2);
