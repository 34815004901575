import { postRequest } from "../services/apiService";
import { endpoints } from "../services/endPoints";

export const startTest = ({ payload }) => {
  return postRequest({
    endpoint: endpoints.startTest,
    data: payload,
  });
};
export const directSubmitTest = ({ payload }) => {
  return postRequest({
    endpoint: endpoints.directSubmitTest,
    data: payload,
  });
}
export const startTestTWV = ({ payload }) => {
  return postRequest({
    endpoint: endpoints.startTestTWV,
    data: payload,
  });
};
export const startMainTest = ({ payload }) => {
  return postRequest({
    endpoint: endpoints.startMainTest,
    data: payload,
  });
};

export const mainTest = ({ payload }) => {
  return postRequest({
    endpoint: endpoints.mainTest,
    data: payload,
  });
};


export const endTest = ({ payload }) => {
  return postRequest({
    endpoint: endpoints.endTest,
    data: payload,
  });
}
export const endTestTWV = ({ payload }) => {
  return postRequest({
    endpoint: endpoints.endTestTWV,
    data: payload,
  });
}