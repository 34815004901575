import { useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import styles from "./CSS/MySuccessRoadMap.module.css";
import images from "../../../utils/images";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useLocation, useNavigate } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import { UserBannerTopComponent } from "../../../components/common/Banners";
import { Popover, ProgressBar } from "react-bootstrap";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController,
} from "chart.js";
import { getRequest } from "../../../services/apiService";
import { endpoints } from "../../../services/endPoints";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController
);

const TestAnalysis = () => {
  const navigate = useNavigate();

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <div className={styles.MySuccessRoadMapDiv2Popover}>
          <span>Filter</span>
          <span>Overall Progress</span>
          <span>Weekly Progress</span>
        </div>
      </Popover.Body>
    </Popover>
  );

  const [data1, setData1] = useState([]);
  console.log(data1);
  const comp1 = [
    {
      icon: "bx:calendar",
      text: "Test Completed",
      number: data1?.overallStats?.completedTestCount || "",
    },
    {
      icon: "mdi:tick-circle-outline",
      text: "to Complete",
      number: `${data1?.overallStats?.completionPercentage} %` || "",
    },
    {
      icon: "mdi:alarm-clock-tick",
      text: "Overall time spent",
      number: `${data1?.overallStats?.overallTimeSpent}` || "",
    },
  ];
  const comp2 = [
    {
      videos: 80,
      practice: 30,
      notes: 20,
      test: 10,
      timeLeft: 10,
      isLocked: false,
      week: 1,
    },
    {
      videos: 80,
      practice: 10,
      notes: 0,
      test: 10,
      timeLeft: 10,
      isLocked: true,
      week: 2,
    },
    {
      videos: 80,
      practice: 30,
      notes: 20,
      test: 10,
      timeLeft: 10,
      isLocked: true,
      week: 3,
    },
  ];
  const data = {
    labels:
      data1?.testDetails?.tests?.[0]?.testPerformances?.map(
        (item, index) => `Test ${index + 1}`
      ) || [],
    datasets: [
      {
        type: "bar",
        label: "Scores",
        data:
          data1?.testDetails?.tests?.[0]?.testPerformances?.map(
            (item) => item.marksObtained
          ) || [],
        backgroundColor: [
          "#76e8ac",
          "#b163ec",
          "#fb8533",
          "#f4c947",
          "#3b98f5",
        ],
        borderRadius: 5,
        borderWidth: 1,
      },
      {
        type: "line",
        label: "Improvement",
        data:
          data1?.testDetails?.tests?.[0]?.testPerformances?.map(
            (item) => item.marksObtained
          ) || [],
        borderColor: "#f00",
        borderWidth: 3,
        fill: false,
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Score Improvement Over Time",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Test",
          color: "#6865f3",
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Score",
          color: "#6865f3",
        },
      },
    },
  };
  const chartData = [
    { name: "Correct", value: 40, color: "#66cc66" },
    { name: "Incorrect", value: 40, color: "#ff6600" },
    { name: "Unattempt", value: 20, color: "#cccccc" },
  ];
  const [selectedWeek, setSelectedWeek] = useState(1);

  const [hasNextPage, setHasNextPage] = useState(true);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const getUserData = () => {
    getRequest({
      endpoint: endpoints.getTestDashboard,
      setIsLoading,
    }).then((res) => {
      setData1(res?.data);
    });
  };

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <div className="MySuccessRoadMapContainer">
      <div>
        <UserBannerTopComponent
          divCSS="UserMainTopBannerDiv"
          imagecss={"UserMainTopBannerDivImage"}
          image={images.userDashboardTopBanner}
        />
      </div>
      <div className={styles.MySuccessRoadMapDiv1}>
        <span className={styles.MySuccessRoadMapDiv1Span1}>Test Analysis </span>
      </div>
      <div className={styles.MySuccessRoadMapDiv2Main}>
        <div className={styles.MySuccessRoadMapDiv2}>
          {comp1.map((item, index) => {
            return (
              <div className={styles.MySuccessRoadMapDiv2Div1} key={index}>
                <span className={styles.MySuccessRoadMapDiv2Div1Span1}>
                  <Icon
                    icon={item.icon}
                    style={{ width: "25px", height: "25px" }}
                  />
                </span>
                <span className={styles.MySuccessRoadMapDiv2Div1Span4}>
                  <span className={styles.MySuccessRoadMapDiv2Div1Span3}>
                    {item.number}
                  </span>
                  <span className={styles.MySuccessRoadMapDiv2Div1Span2}>
                    {item.text}
                  </span>
                </span>
              </div>
            );
          })}
        </div>
        <div className={styles.MySuccessRoadMapDiv5}>
          <p className={styles.MySuccessRoadMapDiv5P1}>Overall Progress</p>
          <p className={styles.MySuccessRoadMapDiv5P2}>
            <span
              style={{ padding: "2rem" }}
              className={styles.MySuccessRoadMapDiv5P2Span3}
            >
              {" "}
              <span>
                <CircularProgressbar
                  value={
                    data1?.overallStats?.overallProgress
                      ? parseInt(data1.overallStats.overallProgress, 10)
                      : undefined
                  }
                  text={`${
                    data1?.overallStats?.overallProgress
                      ? parseInt(data1.overallStats.overallProgress, 10)
                      : undefined
                  }%`}
                  styles={buildStyles({
                    pathColor: "#FFC851",
                    trailColor: "#D9D9D9",
                    textColor: "blue",
                  })}
                />
                <span
                  style={{
                    display: "block",
                    marginTop: "10px",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {data1?.overallStats?.overallProgress
                    ? parseInt(data1.overallStats.overallProgress, 10)
                    : ""}
                </span>
              </span>
            </span>
            <span className={styles.MySuccessRoadMapDiv5P2Span4}>
              {data1?.testDetails?.testWiseScores?.map((item, index) => (
                <span
                  onClick={() =>
                    navigate(
                      `/test/analysis/${item?.testId}/${
                        item?.testSeriesFileId || item?.mainTestSeriesFileId
                      }`,
                      {
                        state: {
                          isMainTest: item?.mainTestSeriesFileId
                            ? true
                            : false,
                        },
                      }
                    )
                  }
                  className={styles.MySuccessRoadMapDiv5P2Span4Span1}
                >
                  <span>
                    <img
                      src={images.userAllDashboardTestImage}
                      alt="DasImage"
                    />
                  </span>
                  <span>
                    <Icon
                      className={styles.MySuccessRoadMapDiv5P2Span2Icon2}
                      icon="mdi:tick-circle-outline"
                    />
                  </span>
                  <span className={styles.MySuccessRoadMapDiv5P2Span4Span2}>
                    Test {index + 1}
                  </span>
                  <span className={styles.MySuccessRoadMapDiv5P2Span4Span3}>
                    {item?.testType || ""}
                  </span>
                </span>
              ))}
            </span>
          </p>
        </div>
        <div className={styles.TestAnalysisDiv2Text}>
          <p>
            <span style={{ color: "#7DE079" }}>
              {data1?.overallStats?.highestScore || 0}{" "}
            </span>
            <span>Highest Score</span>
          </p>
          <p>
            <span style={{ color: "#FF0000" }}>
              {data1?.overallStats?.lowestScore || 0}{" "}
            </span>
            <span>Lowest Score</span>
          </p>
          <p>
            <span style={{ color: "#6090F7" }}>
              {data1?.overallStats?.latestScore || 0}
            </span>
            <span>Latest Score</span>
          </p>
        </div>
        <div className={styles.TestAnalysisDiv2Chart}>
          <div className={styles.TestAnalysisDiv2ChartDiv1}>
            <p>Score Improvement over Time</p>
            <p>
              <div
                style={{ maxWidth: "100%", margin: "auto", padding: "20px" }}
              >
                <Bar data={data} options={options} />
              </div>
            </p>
          </div>
          <div className={styles.TestAnalysisDiv2ChartDiv2}>
            <p>Attempt accuracy Over Time</p>
            <p>Correct VS Incorrect</p>
            <p className={styles.TestAnalysisDiv2ChartDiv2P2}>
              {data1?.testDetails?.attemptAccuracy?.map((item, index) => (
                <p>
                  <span>Test {index + 1}</span>
                  <span>
                    <ProgressBar
                      now={item?.correctPercentage}
                      label={`${item?.correctPercentage}%`}
                      variant="warning"
                      style={{
                        height: "40px",
                        backgroundColor: "red",
                        width: "100%",
                        borderRadius: "25px",
                      }}
                    />
                  </span>
                </p>
              ))}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(TestAnalysis);
