import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import "../Courses/Course.css";

import { getRequest, postRequest } from "../../../../../services/apiService";
import { endpoints } from "../../../../../services/endPoints";
import {
  DashboardUserCoursesTypeCardCoursePage3,
  TestWithVideoCard1,
} from "../../../../../components/common/Cards";
import AddToCart from "../../../../../components/common/AddToCart";
import { UserBannerTopComponent } from "../../../../../components/common/Banners";
import { UserMenuBar } from "../../../../../components/common/MenuBar";
import HOC from "../../../../../components/layout/HOC";
import images from "../../../../../utils/images";
import { showNotification } from "../../../../../components/common/APIComponents";
const CapsuleCoursePage3 = () => {
  const navigate = useNavigate();
  const courseUserCard3 = [
    {
      name: "Mathmatics",
      iconImage: images.homePageCourseIcon1,
      subTopics: 14,
      path: "/user/course/1/subjects/1",
    },
    {
      name: "English",
      iconImage: images.homePageCourseIcon2,
      subTopics: 14,
      path: "/user/courses/crash-course/1",
    },
    {
      name: "Science",
      iconImage: images.homePageCourseIcon3,
      subTopics: 14,
      path: "/user/courses/express-course/1",
    },
    {
      name: "Social Science",
      iconImage: images.homePageCourseIcon2,
      subTopics: 14,
      path: "/user/courses/express-course/1",
    },
    {
      name: "Computer Science",
      iconImage: images.homePageCourseIcon1,
      subTopics: 14,
      path: "/user/courses/express-course/1",
    },
  ];
  const testwithVideoUserCard = [
    {
      name: "RRB NTPC (CBT 1 + CBT 2) 2024 Mock Test Series",
      iconImage: images.userTestWithVideoImage,
      description: "🌐 English, Hindi + 6 More",
      descriptionArray: ["1100 Total Tests", "10 Free tests"],
      courseText: "Start Foundation Course",
      pointsArray: [
        "96 Current General knowledge CT",
        "269 Chapter test",
        "+756 more test",
      ],
      users: 80,
    },
  ];

  const addToCartItems = {
    name: "Foundation Course For JEE",
    image: images.homePageCourseIcon2,
    courseHeighlights: [
      "Includes 95 Courses",
      "1000+ notes & videos",
      "Unlimited practice test",
      "Personalize analysis",
    ],
    Price: "₹ 500",
    PromoCodes: [
      {
        code: "JEE50",
        discount: "50%",
      },
      {
        code: "JEE20",
        discount: "20%",
      },
      {
        code: "JEE10",
        discount: "10%",
      },
    ],
  };
  const [data, setData] = useState([]);
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [courseCategoryData, setCourseCategoryData] = useState({});
  const [courseData, setCourseData] = useState({});
  const [courseTestSeries, setCourseTestSeries] = useState([]);
  const getUserData = () => {
    const payload = {};
    params.course && (payload.course = params.capsuleId);

    getRequest({
      endpoint: endpoints.getCapsuleCourseById,
      setIsLoading,
      params: {
        courseId: params.capsuleId,
      },
    }).then((res) => {
      setData(res?.data?.[0]?.subjects);
      setCourseTestSeries(res?.data?.[0]?.testSeries);
      setCourseCategoryData(res?.data?.[0]);
      setCourseData(res?.data);
      console.log(res?.data);
    });
  };

  useEffect(() => {
    getUserData();
  }, [params.course, params.CourseType]);
  const addToCart = (id) => {
    postRequest({
      endpoint: endpoints.addToCart,
      setIsLoading,
      data: {
        capsuleCourseId: params.capsuleId,
        quantity: 1,
      },
    }).then((res) => {
      navigate("/user/cart");
    });
  };

  const addToWishlist = (id) => {
    postRequest({
      endpoint: endpoints.addToWishlist,
      setIsLoading,
      params: {
        capsuleCourseId: params.capsuleId,
      },
    }).then((res) => {
      showNotification({
        type: "success",
        message: "Added to wishlist successfully",
      });
    });
  };

  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar name="" link={"/user/dashboard"} />
        </div>
        <div className="user_container_width">
          <div className="userCourseContainerPage3">
            <div>
              <div className="userCourseContainerPage3Div1">
                {data &&
                  data.map((i, index) => (
                    <DashboardUserCoursesTypeCardCoursePage3
                      name={i.subject?.name}
                      image={images.UserSubjectsImage}
                      subTopics={i?.subSubjects?.length}
                      path={i.path}
                      onClick={() => {
                        const isPurchased = courseData?.[0]?.isPurchased;
                        const subjectId = i.subject?._id;
                        const hasSubSubjects =
                          i.subject?.subSubject?.length > 0;

                        if (!isPurchased) {
                          showNotification({
                            type: "error",
                            message: "Please buy the course first",
                          });
                          return;
                        }

                        if (subjectId) {
                          const route = hasSubSubjects
                            ? `/user/capsule-course/${params.CourseType}/${params.course}/${subjectId}/subSubject`
                            : `/user/capsule-course/${params.CourseType}/${params.course}/${subjectId}`;

                          navigate(route, {
                            state: {
                              todo: hasSubSubjects
                                ? i?.subSubjects || []
                                : undefined,
                              itemName: "Course",
                              itemId: params.course,
                              isPurchased: true,
                              isSubSubject: hasSubSubjects,
                              item: i,
                            },
                          });
                        } else {
                          console.error("Subject ID is missing.");
                        }
                      }}
                    />
                  ))}
                <div className="gridLayout1">
                  {courseTestSeries?.map((i, index) => {
                    return (
                      <TestWithVideoCard1
                        key={index}
                        name={i.bundleName}
                        image={i.tileImage}
                        description={i.bundleDescription}
                        descriptionArray={i.descriptionArray}
                        courseText={i.courseText}
                        pointsArray={i.pointsArray}
                        users={i.users}
                        id={i._id}
                        isPurchased={courseData?.[0]?.isPurchased}
                        courseId1={params.course || ""}
                        courseType={params.CourseType || ""}
                        isMainTest={true}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="user_testwithvideo_container2 "></div>
            </div>
            <div className="userCourseContainerPage3Div2">
              <AddToCart
                name={courseCategoryData?.courseCategoryId?.name}
                image={courseCategoryData?.courseCategoryId?.image?.[0]}
                courseHighlights={
                  courseCategoryData?.courseCategoryId?.desc || ""
                }
                price={courseData?.[0]?.Price}
                PromoCodes={courseCategoryData?.PromoCodes}
                purchased={courseData?.[0]?.isPurchased}
                purchasedDate={courseData?.[0]?.purchaseDate}
                addToWishlist={addToWishlist}
                addToCart={addToCart}
                id={courseData?.[0]?._id}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(CapsuleCoursePage3);
